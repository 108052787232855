<template>
  <div class="ifc-treeview-area">
    <!-- color="information-200" -->
    <ifc-select-treeview
      v-if="!loading"
      :multiple="false"
      v-model="selected"
      :options="listCategories"
      :label="label"
      :keyLabel="forId ? 'categoryId' : 'nameCategoryId'"
      keyValue="categoryId"
      keyChildren="children"
      selectionType="independent"
      open-all
      :disabled="!!disabled"
      :loading="loading"
    />
    <ifc-loader
      v-else-if="loading"
      class="ifc-loader-size"
      indeterminate
      color="information-200"
    />
    <small class="ifc-search-for ifc-color--information-200">
      <span v-if="forId" @click="forId = false">
        {{ `${$t('label.searchFor')} ${$t('label.name')}` }}
      </span>
      <span v-else @click="forId = true">
        {{ `${$t('label.searchFor')} ${$t('label.id')}` }}
      </span>
    </small>
  </div>
</template>
<script>
import { getCategoriesTreeView } from '@/services/categories'

export default {
  name: 'AutoCompleteListCategories',
  props: {
    value: {
      default: 0
    },
    label: {
      require: false
    },
    disabled: {
      require: false
    },
    channelId: {
      require: false
    }
  },
  data() {
    return {
      listCategories: [],
      forId: false,
      department: null,
      loading: false
    }
  },
  watch: {
    channelId: {
      handler() {
        this.getListCategories()
      },
      immediate: true
    }
  },
  computed: {
    selected: {
      get() {
        return this.value ? [this.value] : []
      },
      set(s) {
        const [val] = s || null
        const item = !val ? 0 : val
        this.$emit('input', item)
        this.getDepartment(item)
      }
    }
  },
  methods: {
    async getListCategories() {
      if (!this.channelId) return

      this.loading = true
      this.listCategories = []
      try {
        const data = await getCategoriesTreeView(this.channelId)
        this.listCategories = this.transformCategories(data)
      } catch {
        this.listCategories = []
      } finally {
        this.loading = false
      }
    },
    transformCategories(categories) {
      if (!categories?.length) return []

      return categories.map((category) => {
        const transformedCategory = {
          ...category,
          nameCategoryId: `${category.name} (${category.categoryId})`
        }

        if (category.children && category.children.length > 0) {
          transformedCategory.children = this.transformCategories(
            category.children
          )
        }

        return transformedCategory
      })
    },
    getDepartment(selId) {
      this.department = null
      selId = parseInt(selId)
      this.listCategories.forEach((obj) => {
        if (obj.categoryId === selId) {
          this.department = this.setDepartment(obj)
        } else if (obj?.children?.length > 0) {
          this.searchChildrens(obj.children, obj, selId)
        }
      })
      return this.department
    },
    searchChildrens(list, firstCategory, selId) {
      list.forEach((obj) => {
        if (obj.categoryId === selId) {
          this.department = this.setDepartment(firstCategory)
        } else if (obj?.children?.length > 0) {
          this.searchChildrens(obj.children, firstCategory, selId)
        }
      })
    },
    setDepartment(data) {
      const depart = {
        id: data.categoryId,
        name: data.name
      }
      this.$emit('department', depart)
    }
  },
  mounted() {
    this.getListCategories()
  }
}
</script>
<style scoped>
.ifc-treeview-area {
  position: relative;
  top: -5px;
}
small.ifc-search-for {
  position: absolute;
  display: block;
  bottom: 5px;
  cursor: pointer;
}
.ifc-loader-size {
  width: 45px !important;
  height: 45px !important;
  margin-bottom: 30px;
}
</style>
