<template>
  <div>
    <label class="editor-label">{{ label }}</label>
    <div v-if="editor" class="editor-wrapper">
      <editor-toolbar :editor="editor" />
      <editor-content :editor="editor" class="editor-content" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Heading from '@tiptap/extension-heading'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import Blockquote from '@tiptap/extension-blockquote'
import Code from '@tiptap/extension-code'
import Link from '@tiptap/extension-link'
import EditorToolbar from './EditorToolbar.vue'

export default {
  components: {
    EditorContent,
    EditorToolbar
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editor: null
    }
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }
      this.editor.commands.setContent(value, false)
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        Heading.configure({ levels: [1, 2, 3] }),
        OrderedList,
        BulletList,
        Blockquote,
        Code,
        Link
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      }
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>
<style>
.ProseMirror {
  min-height: 140px;
}
</style>
<style scoped lang="scss">
.editor-label {
  color: #414f62;
  display: flex;
  align-items: center;
  font-family: Basic, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.editor-wrapper {
  border: 1px solid #c6d3de;
  border-radius: 4px;
  padding: 5px 25px;
}

.editor-content {
  min-height: 146px;
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tiptap {
  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  blockquote {
    border-left: 3px solid #c6d3de;
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid #c6d3de;
    margin: 2rem 0;
  }

  code {
    background-color: #f4f4f4;
    border-radius: 0.4rem;
    color: #333;
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: rgba(0, 0, 0, 0.87);
    border-radius: 0.5rem;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }
}
</style>
