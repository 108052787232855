<template>
  <ifc-container
    class="product-list"
    :breadcrumb="breadcrumbs"
    :title="$t('titles.products')"
  >
    <filter-table
      @filters="filterTable"
      @download="downloadList()"
      :exportIsLoading="loading || exportLoading"
    />
    <v-row>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-table
          :headers="headers"
          :items="content.data"
          :options.sync="options"
          :loading="loading"
        >
          <tbody v-if="content.data.length > 0">
            <tr v-for="(item, i) in content.data" :key="i">
              <td>{{ item.productId }}</td>
              <td>
                <ifc-tooltip
                  v-bind="$props"
                  bottom
                  max-width="450"
                  background-color="information-50"
                  text-color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                      {{ item.productName }}
                    </div>
                  </template>
                  <span> {{ item.productName }} </span>
                </ifc-tooltip>
              </td>
              <td>
                <ifc-tooltip
                  v-bind="$props"
                  bottom
                  max-width="450"
                  background-color="information-50"
                  text-color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                      {{ item.categoryName }}
                    </div>
                  </template>
                  <span> {{ item.categoryName }} </span>
                </ifc-tooltip>
              </td>
              <td>
                {{ item.externalProductId || $t('table.notInformation') }}
              </td>
              <td>
                <ifc-switch
                  class="ifc-switch-adjust"
                  v-model="item.isVisible"
                  label=""
                  inset
                  color="success"
                  :colorThumb="item.isVisible ? 'success-200' : 'neutral-400'"
                  @input="setStatusProduct(item.productId)"
                />
              </td>
              <td>
                <ifc-button
                  icon="ph-pencil-simple"
                  :to="`/products/${item.productId}?channelId=${filters.channelId}`"
                  size="extra-small"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <span>
                  {{ $t('table.noDataText') }}
                </span>
              </td>
            </tr>
          </tbody>
        </ifc-table>
        <ifc-pagination
          class="ifc-mt-28"
          :totalPages="paginations.pages"
          :perPageOptions="listPerPage"
          :perPageDefault="paginations.perPage"
          @onChangePage="setOnChangePage"
          @onChangePerPage="setOnChangePerPage"
        />
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import FilterTable from '@/components/Catalog/Filters/FilterProductList.vue'
import { showNotifyInfo, showNotifySuccess } from '@/plugins/notify'
import { postListExport } from '@/services/imports'
import {
  getProductData,
  getProductList,
  setDeleteProduct,
  setUpdateProduct
} from '@/services/products'
import getStoragedChannel from '@/utils/get-storaged-channel'

export default {
  name: 'ViewProduct',
  components: {
    'filter-table': FilterTable
  },
  props: ['chkStore'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('titles.homePage'),
          href: '/'
        },
        {
          text: this.$t('titles.catalog'),
          disabled: true
        },
        {
          text: this.$t('titles.products'),
          to: '/products',
          exact: true
        }
      ],
      headers: [
        {
          text: this.$t('label.id'),
          align: 'start',
          sortable: true,
          value: 'productId'
        },
        {
          text: this.$t('label.name'),
          align: 'start',
          sortable: true,
          value: 'productName'
        },
        {
          text: this.$t('label.category'),
          align: 'start',
          sortable: true,
          value: 'categoryName'
        },
        {
          text: `${this.$t('label.externalIdOf')} ${this.$t('label.product')}`,
          align: 'start',
          sortable: true,
          value: 'externalProductId'
        },
        {
          text: this.$t('label.visible'),
          align: 'start',
          sortable: true,
          value: 'isVisible'
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'edit'
        }
      ],
      modalUpload: false,
      options: {},
      listPerPage: [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 }
      ],
      content: {
        data: [],
        page: 1
      },
      config: [],
      paginations: [],
      filters: {},
      loading: false,
      exportLoading: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getListProducts()
      },
      deep: true
    },
    chkStore(store) {
      if (store) {
        this.getListProducts()
      }
    }
  },
  mounted() {},
  methods: {
    async chkChannelId() {
      if (!this.filters?.channelId) {
        this.filters.channelId = getStoragedChannel()
      }
    },
    async setStatusProduct(id) {
      const item = this.content.data.filter((obj) => {
        return obj.productId === id
      })
      const data = await getProductData(id)
      data.isVisible = item[0].isVisible
      await setUpdateProduct(id, data)

      if (item[0].isVisible) {
        this.$router.push(`/products/${id}?skuTab=true`)
      }
    },
    async getListProducts() {
      this.chkChannelId()

      if (this.loading) {
        return
      }

      this.filters.channelId = getStoragedChannel()

      if (!this.filters.channelId) {
        return
      }

      this.loading = true
      this.content.data = []
      this.content.page = 1
      try {
        const params = Object.assign(
          {
            filters: this.filters
          },
          this.options
        )
        const { data, ...paginations } = await getProductList(params)
        this.paginations = paginations
        this.content.data = data
      } finally {
        this.loading = false
      }
    },
    async downloadList() {
      this.$notify({
        title: this.$t('message.exportReportSkuTitle'),
        text: this.$t('message.exportReporSkuText'),
        confirmLabel: this.$t('message.exportReporSkuConfirm'),
        maxWidth: '320px'
      }).then(async () => {
        try {
          const params = Object.assign({ filters: this.filters }, this.options)
          this.exportLoading = true
          await postListExport(params)
          this.$toastify(this.$t('message.exportReportSuccess'), 'success')
        } catch {
          this.$toastify(this.$t('message.errorRequest'), 'error')
        } finally {
          this.exportLoading = false
        }
      })
    },
    async delProductContent(id, name) {
      const success = {
        msg: `${this.$t('message.product')} ${name} ${this.$t(
          'message.remove'
        )}`,
        ok: this.$t('button.ok')
      }
      const error = {
        msg: `${this.$t('message.removeCategoryValidation')}`,
        ok: this.$t('button.ok')
      }
      const resp = await setDeleteProduct(id)
      const notify = resp ? showNotifySuccess(success) : showNotifyInfo(error)
      this.showNotify(notify)
      this.getListProducts()
    },
    chkDate(date) {
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    setOnChangePage(val) {
      this.options.page = val
    },
    setOnChangePerPage(val) {
      this.options.itemsPerPage = val
    },
    filterTable(filter) {
      this.paginations.page = 1
      this.filters = filter
      this.getListProducts()
    },
    modalClose(dialogName) {
      this[dialogName] = false
      this.getListProducts()
    },
    modalOpen(dialogName) {
      this[dialogName] = true
    },
    showNotify(options) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'question') {
            this.delProductContent(options.id, options.name)
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.ifc-btn-right {
  float: right;
}
.ifc-switch-adjust {
  height: 30px !important;
  margin: 0 !important;
  width: 75px;
}
</style>
