<template>
  <ifc-container
    class="import-details"
    :breadcrumb="breadcrumbs"
    :title="config.title"
    returnButton
    :returnLabel="$t('button.back')"
    @onGoBack="getBack"
  >
    <v-row>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-table
          :headers="headers"
          :items="content.data"
          :options.sync="options"
          :loading="loading"
        >
          <tbody v-if="content.data.length > 0">
            <tr v-for="(item, i) in getContentData" :key="i">
              <td>
                {{ item.line || ' - ' }}
              </td>
              <td>
                <ifc-tooltip
                  v-bind="$props"
                  bottom
                  max-width="600"
                  background-color="information-50"
                  text-color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                      {{ item.name }}
                    </div>
                  </template>
                  <span> {{ item.name }} </span>
                </ifc-tooltip>
              </td>
              <td class="v-data-table__col-description">
                <ifc-tooltip
                  v-bind="$props"
                  bottom
                  max-width="600"
                  background-color="information-50"
                  text-color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                      {{ item.description }}
                    </div>
                  </template>
                  <span>
                    {{ item.description }}
                  </span>
                </ifc-tooltip>
              </td>
              <td>
                {{ chkDate(item.createdAt) }}
              </td>
              <td>
                {{ chkDate(item.updatedAt) }}
              </td>
              <td>
                <ifc-chip
                  v-if="!setTootipView(item)"
                  :text-color="setColorStatusTxt(item)"
                  :background-color="setColorStatusBg(item)"
                  :label="getStatusName(item)"
                >
                </ifc-chip>
                <ifc-tooltip
                  v-if="setTootipView(item)"
                  v-bind="$props"
                  bottom
                  max-width="600"
                  :background-color="setColorStatusBg(item)"
                  :text-color="setColorStatusTxt(item)"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ifc-chip
                      v-bind="attrs"
                      v-on="on"
                      :text-color="setColorStatusTxt(item)"
                      :background-color="setColorStatusBg(item)"
                      :label="getStatusName(item)"
                    >
                    </ifc-chip>
                  </template>
                  <span> {{ setTootipView(item) }} </span>
                </ifc-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <span>
                  {{ $t('table.noDataText') }}
                </span>
              </td>
            </tr>
          </tbody>
        </ifc-table>
        <ifc-pagination
          class="ifc-mt-28"
          :totalPages="paginations.pages"
          :perPageOptions="listPerPage"
          :perPageDefault="paginations.perPage"
          @onChangePage="setOnChangePage"
          @onChangePerPage="setOnChangePerPage"
        />
      </v-col>
    </v-row>
    <ifc-dialog
      v-model="modal.view"
      @onClose="modalImageHide()"
      maxWidth="50vw"
    >
      <img width="100%" :src="modal.url" />
    </ifc-dialog>
  </ifc-container>
</template>

<script>
import { getDetailsList } from '@/services/imports'
export default {
  name: 'ViewImportDetail',
  components: {},
  props: ['chkStore', 'id', 'file', 'page'],
  data() {
    return {
      channelId: null,
      breadcrumbs: [],
      headers: [
        {
          text: this.$t('label.line'),
          align: 'start',
          sortable: false,
          value: 'statusId'
        },
        {
          text: this.$t('label.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('label.description'),
          align: 'start',
          sortable: false,
          value: 'description'
        },
        {
          text: this.$t('label.dateImport'),
          align: 'start',
          sortable: true,
          value: 'createdAt'
        },
        {
          text: this.$t('label.updatedAt'),
          align: 'start',
          sortable: true,
          value: 'updatedAt'
        },
        {
          text: this.$t('label.status'),
          align: 'start',
          sortable: true,
          value: 'status'
        }
      ],
      options: {},
      listPerPage: [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 }
      ],
      content: {
        data: [],
        page: 1
      },
      config: {
        title: '...',
        params: null,
        modelFile: ''
      },
      paginations: [],
      filters: {},
      loading: false,
      modal: {
        url: null,
        view: false
      },
      selectedButtons: [],
      copied: false
    }
  },
  computed: {
    getContentData() {
      const { data } = this.content
      return data.map((line) => {
        return {
          ...line,
          ...this.getNameAndDescriptionPerPage(line),
          action: {
            name: '-',
            description: '-'
          }
        }
      })
    }
  },
  watch: {
    options: {
      handler() {
        this.getListProductsImport()
        this.statusLoadInterval(10000)
      },
      deep: true
    },
    chkStore(store) {
      if (store) {
        this.getListFilesImport()
      }
    }
  },
  methods: {
    getNameAndDescriptionPerPage(item) {
      const pageMappings = {
        'products-full': {
          name: item.description?.skuName,
          description: item.description?.skuDescription
        },
        brands: {
          name: item.description?.name,
          description: item.description?.description
        },
        categories: {
          name: item.description?.name,
          description: item.description?.description
        },
        images: {
          name: item.description?.title,
          description: item.description?.url
        },
        fiscal: {
          name: item.description?.cest,
          description: item.description?.ncm
        }
      }

      const result = pageMappings[this.page] || {
        name: '-',
        description: '-'
      }

      if (!result.name) {
        result.name = '-'
      }

      if (!result.description) {
        result.description = '-'
      }

      return result
    },
    modalImageShow(url) {
      if (!url) {
        this.modal = {}
      } else {
        this.modal = {
          url,
          view: true
        }
      }
    },
    modalImageHide() {
      this.modal = {
        url: null,
        view: false
      }
    },
    copyUrl(url, index) {
      if (navigator?.clipboard?.writeText) {
        navigator.clipboard.writeText(url)
      } else {
        const TempText = document.createElement('input')
        TempText.value = url
        document.body.appendChild(TempText)
        TempText.select()
        document.execCommand('copy')
        document.body.removeChild(TempText)
      }

      this.feedbackCopiedText(index)
    },
    feedbackCopiedText(index) {
      const feedbackTime = 2000
      this.copied = true
      this.selectedButtons.push(index)

      const uniqueItems = [...new Set(this.selectedButtons)]
      this.selectedButtons = uniqueItems
      setTimeout(() => {
        this.selectedButtons = this.removeItemFromSelectedButtonsList(index)
      }, feedbackTime)
    },
    hasOnSelectedButtonsList(index) {
      return this.selectedButtons.includes(index)
    },
    removeItemFromSelectedButtonsList(index) {
      return this.selectedButtons.filter((item) => ![index].includes(item))
    },
    getConfigPage() {
      switch (this.page) {
        case 'attributes':
          return {
            title: `${this.$t('titles.importOn')} ${this.$t(
              'titles.attributes'
            )}`,
            params: 'attributes',
            modelFile: 'files/ProductExportModel.xlsx'
          }
        case 'brands':
          return {
            title: `${this.$t('titles.importOn')} ${this.$t('titles.brands')}`,
            params: 'brands',
            modelFile: 'files/ProductExportModel.xlsx'
          }
        case 'categories':
          return {
            title: `${this.$t('titles.importOn')} ${this.$t(
              'titles.categories'
            )}`,
            params: 'categories',
            modelFile: 'files/ProductExportModel.xlsx'
          }
        case 'fiscal':
          return {
            title: `${this.$t('titles.importOn')} ${this.$t('titles.fiscal')}`,
            params: 'fiscal',
            modelFile: 'files/ProductExportModel.xlsx'
          }
        case 'images':
          return {
            title: `${this.$t('titles.importOn')} ${this.$t('titles.images')}`,
            params: 'images',
            modelFile: 'files/ProductExportModel.xlsx'
          }
        case 'products':
          return {
            title: `${this.$t('titles.importOn')} ${this.$t(
              'titles.products'
            )}`,
            params: 'products',
            modelFile: 'files/ProductExportModel.xlsx'
          }
        case 'products-full':
          return {
            title: `${this.$t('titles.importOn')} ${this.$t(
              'titles.products'
            )} ${this.$t('titles.and')} ${this.$t('titles.skus')}`,
            params: 'products',
            modelFile: 'files/ProductFullExportModel.xlsx'
          }
        case 'sku':
          return {
            title: `${this.$t('titles.importOn')} ${this.$t('titles.sku')}`,
            params: 'sku',
            modelFile: 'files/ProductExportModel.xlsx'
          }
        case 'wms':
          return {
            title: `${this.$t('titles.importOn')} ${this.$t('titles.wms')}`,
            params: 'wms',
            modelFile: 'files/WMSExportModel.xlsx'
          }
        default:
          this.$router.push('/')
          return {
            title: `${this.$t('titles.import')}`,
            params: '',
            modelFile: 'files/ProductExportModel.xlsx'
          }
      }
    },
    async getListProductsImport() {
      this.chkChannelId()
      this.loading = true
      try {
        const params = Object.assign(
          {
            filters: this.filters
          },
          this.options
        )
        params.channelId = this.channelId
        const { data, ...paginations } = await getDetailsList(this.id, params)
        this.paginations = paginations
        this.content.data = data
      } finally {
        this.loading = false
      }
    },
    statusLoadInterval(interval) {
      let initialInterval = null
      initialInterval = setInterval(() => {
        const processing = this.content.data.some((el) => el.status === 0)
        if (processing) {
          this.getListProductsImport()
        } else {
          clearInterval(initialInterval)
        }
      }, interval)
    },
    chkDate(date) {
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    setTypeLanguage(type) {
      switch (type) {
        case 'attibute':
          return this.$t(`type.${type}`)
        case 'attibutesSkus':
          return this.$t(`type.${type}`)
        case 'category':
          return this.$t(`type.${type}`)
        case 'imagem':
          return this.$t(`type.${type}`)
        case 'product':
          return this.$t(`type.${type}`)
        case 'sku':
          return this.$t(`type.${type}`)
        default:
          return type
      }
    },
    setOnChangePage(val) {
      this.options.page = val
    },
    setOnChangePerPage(val) {
      this.options.itemsPerPage = val
    },
    filterTable(filter) {
      this.paginations.page = 1
      this.filters = filter
      this.getListProductsImport()
    },
    setTootipView(item) {
      return item.error
    },
    getStatusCode(status) {
      return status === 'SUCCESS' ? 1 : 2
    },
    setColorStatusTxt(item) {
      switch (this.getStatusCode(item.status)) {
        case 1:
          return 'success'
        case 4:
          return 'success'
        case 0:
          return 'information-100'
        case 3:
          return 'neutral-600'
        default:
          return 'error-100'
      }
    },
    setColorStatusBg(item) {
      switch (this.getStatusCode(item.status)) {
        case 1:
          return 'success-50'
        case 4:
          return 'success-50'
        case 0:
          return 'information-50'
        case 3:
          return 'warning-50'
        default:
          return 'error-50'
      }
    },
    getStatusName(item) {
      switch (this.getStatusCode(item.status)) {
        case 2:
          return this.$t('status.error.short')
        case 1:
          return this.$t('status.success.short')
        case 0:
          return this.$t('status.processing.short')
        case 3:
          return this.$t('status.analisingRetry.short')
        case 4:
          return this.$t('status.successRetry.short')
        case 5:
          return this.$t('status.errorRetry.short')
        default:
          return this.$t('status.error.short')
      }
    },
    getBack() {
      this.$router.push(`/import/${this.page}`)
    },
    chkChannelId() {
      this.channelId = this.$route.query.channelId
    }
  },
  mounted() {
    this.chkChannelId()
    this.config = this.getConfigPage()
    this.breadcrumbs = [
      {
        text: this.$t('titles.homePage'),
        href: '/'
      },
      {
        text: this.$t('titles.catalog'),
        to: '/',
        exact: true
      },
      {
        text: this.config.title || '...',
        to: `/import/${this.page}`,
        exact: true
      },
      {
        text: this.$t('titles.details'),
        to: `/import-details/${this.page}/details/${this.id}/${this.file}`,
        exact: true
      }
    ]
  }
}
</script>
<style scoped lang="scss">
.v-data-table {
  &__buttons-wrap {
    &--link {
      width: 95% !important;
    }
    &--actions {
      width: 50px;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
}
::v-deep {
  .v-data-table__button-wrap {
    display: flex !important;
  }
}
</style>
