<template>
  <div class="ifc-gallery">
    <draggable
      class="ifc-gallery-list"
      tag="ul"
      :list="gallery"
      v-if="gallery.length > 0"
    >
      <li
        class="ifc-gallery-item"
        v-for="(item, i) in gallery"
        :key="i"
        :title="item.name"
      >
        <ifc-icon
          class="ifc-play"
          name="ph-play"
          iconColor="shade-0"
          iconSize="36"
          v-if="item.mediaType.indexOf('video') > -1"
        />
        <video v-if="item.mediaType.indexOf('video') > -1">
          <source :src="item.url" :type="item.metadata.contentType" />
        </video>
        <figure
          v-else
          :style="{ 'background-image': `url(${item.url})` }"
        ></figure>
        <div class="ifc-gallery-actions">
          <ifc-button
            class="ifc-pa-8px"
            icon="ph-arrows-out"
            outlined
            color="primary"
            size="extra-small"
            @click="modalGalleryShow(item, 'view')"
          />
          <ifc-button
            v-if="!item.isNew"
            icon="ph-pencil"
            outlined
            color="information-200"
            size="extra-small"
            @click="modalGalleryShow(item, 'edit')"
          />
          <ifc-button
            icon="ph-trash"
            outlined
            color="error-200"
            size="extra-small"
            @click="removeImg(i, item)"
          />
        </div>
      </li>
    </draggable>
    <ifc-typography
      v-else
      typography-style="body-small"
      text-weight="500"
      class="ifc-my-16"
    >
      {{ $t('message.notFoundMedias') }}
    </ifc-typography>
    <ifc-dialog
      v-model="modal.edit"
      persistent
      @onClose="modalGalleryHide()"
      maxWidth="360px"
    >
      <ifc-typography
        typography-style="h4-small"
        text-weight="500"
        class="ifc-pb-16"
        >{{ `${$t('titles.mediaEdit')}:` }}</ifc-typography
      >
      <ifc-input :label="`${$t('label.titleMedia')}`" v-model="modal.name" />
      <ifc-input
        :label="`${$t('label.alternativeText')}`"
        v-model="modal.label"
      />
      <ifc-input
        :label="`${$t('label.mediaPosition')}`"
        v-model="modal.sortOrder"
        disabled
        readonly
      />
      <ifc-button
        class="ifc-ma-4"
        block
        :label="$t('button.ok')"
        color="information-200"
        size="small"
        @click="editGalleryImg(modal)"
      />
    </ifc-dialog>
    <ifc-dialog
      v-if="width"
      v-model="modal.view"
      persistent
      @onClose="modalGalleryHide()"
      maxWidth="90vw"
    >
      <video
        v-if="modal.type === 'video'"
        class="ifc-gallery-modal-video"
        controls
      >
        <source :src="modal.url" :type="modal.contentType" />
      </video>
      <figure
        v-else
        class="ifc-gallery-modal-medias"
        :style="{ 'background-image': `url(${modal.url})` }"
      >
        <figcaption class="ifc-gallery-modal-caption">
          {{ modal.text }}
        </figcaption>
      </figure>
    </ifc-dialog>
  </div>
</template>

<script>
import { showNotifyDelete } from '@/plugins/notify'
import draggable from 'vuedraggable'
export default {
  name: 'ifcGallery',
  components: {
    draggable
  },
  props: ['value'],
  data() {
    return {
      width: 0,
      modal: {
        label: null,
        url: null,
        text: null,
        sortOrder: null,
        view: false,
        edit: false
      }
    }
  },
  computed: {
    gallery: {
      get() {
        return this.value || []
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    modalGalleryShow(item, type) {
      console.log(item)
      this.modal = {
        fileId: item.fileId,
        name: item.name,
        label: item.label,
        url: item.url,
        sortOrder: item.sortOrder,
        type: item.mediaType,
        contentType: item.metadata.contentType,
        view: type === 'view',
        edit: type === 'edit'
      }
    },
    modalGalleryHide() {
      this.modal = {
        fileId: null,
        name: null,
        label: null,
        url: null,
        sortOrder: null,
        type: null,
        contentType: null,
        view: false,
        edit: false
      }
    },
    editGalleryImg(val) {
      const media = val
      this.$emit('editedMedia', media)
      this.modalGalleryHide()
    },
    removeImg(i, item) {
      const remove = {
        msg: this.$t('message.removeSkuMedias'),
        item: item,
        idx: i
      }
      const notify = showNotifyDelete(remove)
      this.showNotify(notify, item)
    },
    removeGalleryImg(item) {
      const filteredGallery = this.gallery.filter((obj) => {
        return obj.fileId !== item.fileId
      })

      this.gallery = filteredGallery

      if (!item.isNew) {
        this.$emit('removedMedias', [item.fileId])
      }
    },
    showNotify(options, info) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'question') {
            this.removeGalleryImg(info)
          }
        })
        .catch(() => {})
    }
  },
  mounted() {
    this.width = 500
  }
}
</script>

<style>
.ifc-gallery {
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 15px;
  position: relative;
}
.ifc-gallery-modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 90vw;
  height: 90vh;
}
.ifc-gallery-modal-close {
  position: fixed;
  display: block;
  top: 20px;
  right: 20px;
  z-index: 1005;
  cursor: pointer;
}
.ifc-gallery-modal.active {
  display: flex;
  opacity: 1;
  transition: 1s;
}
.ifc-gallery-modal-video {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: auto;
  max-width: 80vw;
  max-height: 70vh;
  margin: 0 auto;
  background-color: #000;
}
.ifc-gallery-modal-medias {
  position: relative;
  display: block;
  overflow: hidden;
  max-width: 90vw;
  height: 80vh;
  margin: -20px;
  box-sizing: border-box;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.ifc-gallery-modal-medias .ifc-gallery-modal-caption {
  position: absolute;
  display: block;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  bottom: -100%;
  left: 0;
  background-color: rgba(33, 33, 33, 0.8);
  color: #fff;
  transition: 0.5s;
}
.ifc-gallery-modal-medias:hover .ifc-gallery-modal-caption {
  bottom: 0;
  transition: 0.5s;
}

.ifc-gallery-list {
  position: relative;
  display: inline-block;
  vertical-align: top;
  /* display: grid;
  grid-template-columns: auto;
  gap: 2px; */
  width: 100%;
  padding-left: 0 !important;
  box-sizing: border-box;
  padding: 0;
  text-align: left;
  margin: 0 auto;
}
.ifc-gallery-item {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  margin: 1px;
  padding: 0px;
  overflow: hidden;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #000;
  opacity: 0.65;
  transition: 0.7s;
}
.ifc-gallery-item:hover {
  opacity: 1;
  transition: 0.7s;
  cursor: move;
}
.ifc-gallery-item > figure {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ifc-gallery-item > video {
  width: auto;
  height: 100%;
}
.ifc-gallery-item > .ifc-play {
  position: absolute;
  display: block;
  z-index: 3;
}
.ifc-gallery-item .ifc-gallery-actions {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  left: 0;
  top: 100%;
  bottom: initial;
  padding: 2px 5px;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
  transition: 0.7s;
  z-index: 5;
}
.ifc-gallery-item:hover .ifc-gallery-actions {
  top: initial;
  bottom: 0;
  opacity: 1;
  transition: 0.7s;
}
.ifc-gallery-item .ifc-gallery-actions .ifc-icon i {
  position: relative;
  display: block;
  top: -2.5px;
  left: -1.25px;
}

@media screen and (min-width: 1441px) {
  .ifc-gallery-list {
    grid-template-columns: auto auto auto auto auto auto;
  }
  .ifc-gallery-item {
    width: calc((100% / 6) - 4px);
    height: 120px;
  }
}
@media screen and (max-width: 1440px) {
  .ifc-gallery-list {
    grid-template-columns: auto auto auto auto auto;
  }
  .ifc-gallery-item {
    width: calc((100% / 4) - 4px);
    height: 80px;
  }
}
@media screen and (max-width: 1367px) {
  .ifc-gallery-list {
    grid-template-columns: auto auto auto auto;
  }
  .ifc-gallery-item {
    width: calc((100% / 5) - 4px);
    height: 100px;
  }
}
@media screen and (max-width: 1263px) {
  .ifc-gallery-item {
    height: 75px;
  }
}
@media screen and (max-width: 1025px) {
  .ifc-gallery-list {
    grid-template-columns: auto auto auto;
  }
  .ifc-gallery-item {
    height: 80px;
  }
}
@media screen and (max-width: 959px) {
  .ifc-gallery-list {
    grid-template-columns: auto auto auto auto auto auto;
  }
  .ifc-gallery-item {
    height: 80px;
  }
}
@media screen and (max-width: 854px) {
  .ifc-gallery-list {
    grid-template-columns: auto auto auto auto;
  }
  .ifc-gallery-item {
    height: 80px;
  }
}
@media screen and (max-width: 769px) {
  .ifc-gallery-list {
    grid-template-columns: auto auto auto;
  }
  .ifc-gallery-item {
    height: 90px;
  }
}
@media screen and (max-width: 650px) {
  .ifc-gallery-list {
    grid-template-columns: auto auto auto;
  }
  .ifc-gallery-item {
    height: 70px;
  }
}
@media screen and (max-width: 550px) {
  .ifc-gallery-list {
    grid-template-columns: auto auto auto;
  }
  .ifc-gallery-item {
    height: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ifc-gallery-list {
    grid-template-columns: auto auto;
  }
  .ifc-gallery-item {
    height: 80px;
  }
}
@media screen and (max-width: 400px) {
  .ifc-gallery-list {
    grid-template-columns: auto;
  }
  .ifc-gallery-item {
    height: 80px;
  }
}
</style>
