import { NODE_ENV } from '@/constants/environments'
import getStoragedChannel from '@/utils/get-storaged-channel'
import axios from 'axios'

const increaseTimeoutForUploads = (config) => {
  if (config.url.includes('import') && config.method === 'post') {
    config.timeout = 60 * 10000
  }
}

const getChannelId = (config) => {
  if (config?.params?.channelId) {
    return config.params.channelId
  }
  if (config?.data) {
    const configData =
      typeof config.data === 'string' ? JSON.parse(config.data) : config.data
    if (configData?.channelId) return configData.channelId
    if (configData?.filter?.channelId) return configData.filter.channelId
  }
  return getStoragedChannel() || null
}

const defaultHeaders = (config) => {
  const Authorization = {}

  const xTenantId = {
    'x-tenant-id': window.parent.IFC_VARIABLES.headers['x-tenant-id']
  }

  const xChannelId = {
    'x-channel-id': getChannelId(config)
  }

  return {
    'Content-Type': 'application/json',
    ...Authorization,
    ...xTenantId,
    ...xChannelId
  }
}

const downloadHeaders = (config) => {
  const Authorization = NODE_ENV === 'development' ? {} : {}
  const xTenantId = {
    'X-Tenant-Id': window.parent.IFC_VARIABLES.headers['x-tenant-id']
  }

  const xChannelId = {
    'x-channel-id': getChannelId(config)
  }

  return {
    // 'Content-Type':
    //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'Content-disposition': 'attachment; filename=download_file.xlsx',
    ...Authorization,
    ...xTenantId,
    ...xChannelId
  }
}

const timeout = 60000
const timeoutUpload = 600000

const ApiCtg = axios.create({
  baseURL: `${window.parent.IFC_VARIABLES.baseURL}/catalog/main/`
})

const ApiCtgDown = axios.create({
  baseURL: `${window.parent.IFC_VARIABLES.baseURL}/catalog/fileprocessor/`,
  responseType: 'blob',
  timeout: timeoutUpload
})

const ApiFile = axios.create({
  baseURL: `${window.parent.IFC_VARIABLES.baseURL}/catalog/fileprocessor/`
})

const ApiMedia = axios.create({
  baseURL: `${window.parent.IFC_VARIABLES.baseURL}/content/media/`
})

const ApiAcc = axios.create({
  baseURL: `${window.parent.IFC_VARIABLES.baseURL}/account/main/`
})

const ApiCtgImp = axios.create({
  baseURL: `${window.parent.IFC_VARIABLES.baseURL}/catalog/import/`
})

ApiCtg.defaults.timeout = timeout
ApiCtgDown.defaults.timeout = timeoutUpload
ApiAcc.defaults.timeout = timeout
ApiCtgImp.defaults.timeout = timeout
ApiFile.defaults.timeout = timeout

ApiCtg.interceptors.request.use(
  (config) => {
    increaseTimeoutForUploads(config)
    Object.assign(config.headers, {
      ...defaultHeaders(config)
    })
    return config
  },
  (error) => Promise.reject(error)
)

ApiCtgImp.interceptors.request.use(
  (config) => {
    increaseTimeoutForUploads(config)
    Object.assign(config.headers, {
      ...defaultHeaders(config)
    })
    return config
  },
  (error) => Promise.reject(error)
)

ApiCtgDown.interceptors.request.use(
  async (config) => {
    Object.assign(config.headers, {
      ...downloadHeaders(config)
    })
    return config
  },
  (error) => Promise.reject(error)
)

ApiFile.interceptors.request.use(
  (config) => {
    increaseTimeoutForUploads(config)
    Object.assign(config.headers, {
      ...defaultHeaders(config)
    })
    return config
  },
  (error) => Promise.reject(error)
)

ApiAcc.interceptors.request.use(
  (config) => {
    increaseTimeoutForUploads(config)
    Object.assign(config.headers, {
      ...defaultHeaders(config)
    })
    return config
  },
  (error) => Promise.reject(error)
)

export { ApiAcc, ApiCtg, ApiCtgDown, ApiCtgImp, ApiFile, ApiMedia, axios }
