<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="2"
      lg="2"
      class="align-self-start ifc-px-2 ifc-pb-none ifc-pt-6"
    >
      <acl-chan :label="`${$t('label.channel')}`" v-model="filters.channelId" />
    </v-col>
    <v-col cols="12" sm="12" md="3" class="ifc-px-4 ifc-pt-top ifc-pb-none">
      <ifc-input
        v-model="filters.categoryId"
        :placeholder="`${$t('label.searchFor')} ${$t('label.id')}`"
        mask="###################"
        type="tel"
        prependIcon="search"
      />
    </v-col>
    <v-col cols="12" sm="12" md="3" class="ifc-px-4 ifc-pt-top ifc-pb-none">
      <ifc-input
        v-model="filters.name"
        :placeholder="`${$t('label.searchFor')} ${$t('label.name')}`"
        prependIcon="search"
      />
    </v-col>
    <v-col cols="12" sm="12" md="2" class="ifc-px-4 ifc-pb-none">
      <ifc-select
        appendIcon="expand_more"
        v-model="filters.active"
        :options="statusList"
        :label="`${$t('label.filterFor')} ${$t('label.status')}`"
        :placeholder="$t('label.select')"
        clearable
      />
    </v-col>
    <v-col cols="12" sm="12" md="2" class="ifc-px-4 ifc-pt-top ifc-pb-none col">
      <!-- removed by backend -->
      <ifc-button
        v-if="1 === 2"
        appendIcon="ph-download"
        :label="`${$t('button.downloadList')}`"
        outlined
        color="information-200"
        @click="getDownload"
      />
      <ifc-button
        appendIcon="add"
        :label="$t('button.newCategory')"
        :to="`/categories/new`"
        color="information-200"
      />
    </v-col>
  </v-row>
</template>

<script>
import AutoCompleteListChannels from '@/components/Catalog/Autocomplete/AutoCompleteListChannels.vue'
import _ from 'lodash'
import getStoragedChannel from '@/utils/get-storaged-channel'

export default {
  name: 'FilterCategoriesList',
  components: {
    'acl-chan': AutoCompleteListChannels
  },
  data() {
    return {
      panel: [0],
      status: [],
      filters: {
        channelId: '',
        categoryId: '',
        name: '',
        active: ''
      },
      categoryIdAux: '',
      statusList: [
        {
          label: this.$t('status.active.short'),
          value: 'true'
        },
        {
          label: this.$t('status.inactive.short'),
          value: 'false'
        }
      ],
      /**
       * Debounced update for filters.
       * @param {string} [field] - The name field.
       */
      debouncedUpdateFilters: (field) =>
        _.debounce(() => this.updateFilters(field), 500)()
    }
  },
  computed: {
    mdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  watch: {
    'filters.categoryId': {
      handler() {
        this.debouncedUpdateFilters('categoryId')
      },
      immediate: true
    },
    'filters.channelId': 'debouncedUpdateFilters',
    'filters.name': 'debouncedUpdateFilters',
    'filters.active': 'debouncedUpdateFilters'
  },
  mounted() {
    this.filters.channelId = getStoragedChannel()
  },
  methods: {
    /**
     * Update filters.
     * @param {string} [field] - The name field.
     */
    updateFilters(field) {
      if (
        field === 'categoryId' &&
        this.categoryIdAux === this.filters.categoryId
      ) {
        return
      }
      this.categoryIdAux = this.filters.categoryId
      this.$emit('filters', this.filters)
    },
    getDownload() {
      this.$emit('download')
    }
  }
}
</script>

<style scoped lang="scss">
.v-text-field__details {
  display: none !important;
}
.ifc-btn-right {
  text-align: right;
  white-space: nowrap;
}
.ifc-min-select {
  min-width: 155px;
}

.ifc-pt-top {
  padding-top: 32px !important;
}

@media (max-width: 960px) {
  .ifc-padd-bottom {
    position: relative;
    padding-bottom: 20px !important;
  }

  .ifc-pt-top {
    padding-top: 0px !important;
  }

  .ifc-btn-right {
    white-space: normal;
  }
}
@media (max-width: 474px) {
  .ifc-btn-right {
    .v-btn {
      width: 100%;
    }
  }
}
</style>
