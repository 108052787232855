<template>
  <ifc-container
    class="product-list"
    :breadcrumb="breadcrumbs"
    :title="$t('titles.brands')"
  >
    <filter-table
      @filters="filterTable"
      @addBrands="modalOpen('modalBrands')"
      @download="downloadList()"
    />
    <v-row class="ifc-mt-none">
      <v-col cols="12" class="ifc-pa-none">
        <ifc-table
          :headers="headers"
          :items="content.data"
          :options.sync="options"
          :loading="loading"
        >
          <tbody v-if="content.data.length > 0">
            <tr v-for="(item, i) in content.data" :key="i">
              <td>{{ item.brandId }}</td>
              <td>
                <ifc-tooltip
                  v-bind="$props"
                  bottom
                  max-width="600"
                  background-color="information-50"
                  text-color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                      {{ item.name }}
                    </div>
                  </template>
                  <span> {{ item.name }} </span>
                </ifc-tooltip>
              </td>
              <td>{{ item.externalCode || $t('table.notInformation') }}</td>
              <td>{{ chkDate(item.updatedAt) }}</td>
              <td>
                <ifc-chip
                  :label="$t(`status.active.short`)"
                  background-color="success-50"
                  text-color="success-200"
                  v-if="item.active"
                />
                <ifc-chip
                  :label="$t(`status.inactive.short`)"
                  background-color="neutral-300"
                  text-color="neutral-400"
                  v-else
                />
              </td>
              <td class="ifc-col-center">
                <ifc-button
                  icon="ph-pencil-simple"
                  :to="`/brands/${item.brandId}?channelId=${filters.channelId}`"
                  size="extra-small"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <span>
                  {{ $t('table.noDataText') }}
                </span>
              </td>
            </tr>
          </tbody>
        </ifc-table>
        <ifc-pagination
          class="ifc-mt-28"
          :totalPages="paginations.pages"
          :perPageOptions="listPerPage"
          :perPageDefault="paginations.perPage"
          @onChangePage="setOnChangePage"
          @onChangePerPage="setOnChangePerPage"
        />
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import { getBrandsList, setDeleteBrands } from '@/services/brands'
import FilterTable from '@/components/Catalog/Filters/FilterBrandsList.vue'
import { postListDownload } from '@/services/imports'
import {
  showNotifyInfo,
  showNotifyDelete,
  showNotifySuccess
} from '@/plugins/notify'
export default {
  name: 'Brands',
  components: {
    'filter-table': FilterTable
  },
  props: ['chkStore'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('titles.homePage'),
          href: '/'
        },
        {
          text: this.$t('titles.catalog'),
          to: `/`,
          disabled: true
        },
        {
          text: this.$t('titles.brandsList'),
          to: '/brands',
          exact: true
        }
      ],
      headers: [
        {
          text: this.$t('label.id'),
          align: 'start',
          sortable: true,
          value: 'brandId'
        },
        {
          text: this.$t('label.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: `${this.$t('label.externalIdOf')} ${this.$t('label.brand')}`,
          align: 'start',
          sortable: true,
          value: 'externalBrandId'
        },
        {
          text: this.$t('label.updatedAt'),
          align: 'start',
          sortable: true,
          value: 'updatedAt'
        },
        {
          text: this.$t('label.status'),
          align: 'start',
          sortable: false
        },
        {
          text: '',
          sortable: false,
          value: 'edit'
        }
      ],
      modalUpload: false,
      options: {},
      listPerPage: [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 }
      ],
      content: {
        data: [],
        page: 1
      },
      config: [],
      paginations: [],
      filters: {},
      loading: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getListBrands()
      },
      deep: true
    },
    chkStore(store) {
      this.chkChannelId()
      if (store) {
        this.getListBrands()
      }
    }
  },
  methods: {
    chkChannelId() {
      if (this.$route.query.channelId && !this.filters.channelId) {
        this.filters.channelId = this.$route.query.channelId
      }
    },
    async getListBrands() {
      this.chkChannelId()
      this.loading = true
      try {
        const params = Object.assign(
          {
            filters: this.filters
          },
          this.options
        )
        const resp = await getBrandsList(params)
        if (resp) {
          const { data, ...paginations } = resp
          this.paginations = paginations
          this.content.data = data
        }
      } catch (err) {
        const error = {
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyInfo(error)
        this.showNotify(notify)
      } finally {
        this.loading = false
      }
    },
    async downloadList() {
      try {
        const params = Object.assign({ filters: this.filters }, this.options)
        const resp = await postListDownload('brands', params)
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.$t('titles.brandsList')}.xlsx`)
        document.body.appendChild(link)
        link.click()
      } catch {
        const error = {
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyInfo(error)
        this.showNotify(notify)
      }
    },
    delBrands(id, name) {
      const remove = {
        msg: `${this.$t('message.removeQuestion')} ${name}`,
        ok: this.$t('button.ok'),
        cancel: this.$t('button.cancel'),
        id: id
      }
      const notify = showNotifyDelete(remove)
      this.showNotify(notify)
    },
    async delBrandsContent(id, name) {
      const success = {
        msg: `${this.$t('message.brands')} ${name} ${this.$t(
          'message.remove'
        )}`,
        ok: this.$t('button.ok')
      }
      const error = {
        msg: `${this.$t('message.errorRequest')}`,
        ok: this.$t('button.ok')
      }
      const resp = await setDeleteBrands(id)
      const notify = resp ? showNotifySuccess(success) : showNotifyInfo(error)
      this.showNotify(notify)
      this.getListBrands()
    },
    chkDate(date) {
      if (!date) return ' - '
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    setOnChangePage(val) {
      this.options.page = val
    },
    setOnChangePerPage(val) {
      this.options.itemsPerPage = val
    },
    filterTable(filter) {
      this.paginations.page = 1
      this.filters = filter
      this.getListBrands()
    },
    modalOpen(dialogName) {
      this[dialogName] = true
    },
    modalClose(dialogName) {
      this[dialogName] = false
    },
    showNotify(options) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'question') {
            this.delBrandsContent(options.id, options.name)
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.ifc-btn-right {
  float: right;
}
.ifc-col-center {
  text-align: center;
}
.cell-ellipsis {
  width: 215px !important;
}
</style>
