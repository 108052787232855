<template>
  <ifc-main data-app class="main-content">
    <router-view :chkStore="chkStoreCurrent" :key="$route.fullPath" />
  </ifc-main>
</template>

<script>
import router from './router'

export default {
  name: 'App',
  props: ['tokenIdSubject', 'basepath', 'location'],
  data: () => ({
    subscription: {},
    chkStoreCurrent: ''
  }),
  watch: {
    tokenIdSubject: {
      immediate: true,
      handler(value) {
        if (!value) return
        this.subscription = value.subscribe(this.handleSubscribe)
      }
    },
    $route: function ({ path, fullPath, query }) {
      window.parent.postMessage({ path, fullPath, query }, '*')
    }
  },
  destroyed() {
    this.subscription && this.subscription.unsubscribe()
  },
  methods: {
    handleSubscribe({ store }) {
      localStorage.setItem('ctgCurrentStore', JSON.stringify(store))
      this.chkStoreCurrent = store
    },
    handleRouteChange(event) {
      router.push(`${event.detail.newRoute}`)
    }
  },
  created() {
    this.newRoute = this.location.includes('old-app')
      ? this.location.replace(
          `/old-app/${window.parent.IFC_VARIABLES.headers['x-tenant-id']}/catalog`,
          ''
        )
      : this.$route.fullPath
  },
  mounted() {
    this.location.includes('old-app') && router.push(this.newRoute)
    window.addEventListener('changeRoute', this.handleRouteChange)

    // Make sure to remove the event listener when the component is destroyed
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('changeRoute', this.handleRouteChange)
    })
  }
}
</script>
