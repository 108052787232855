<template>
  <ifc-dialog
    v-bind="attributes"
    v-on="listeners"
    maxWidth="400"
    @onClose="closeModal()"
  >
    <div class="upload-send">
      <ifc-upload
        title=" "
        :accept="uploadMime"
        class="ifc-top-negative"
        :showTitle="true"
        :rulesLabel="`${$t('label.medias')} `"
        @onError="errorHasEmitted"
        @onUpload="getListUpload"
      >
        <template slot="drag">
          <div class="drag-msg">
            <p class="ifc-pa-4">
              <ifc-upload-icon icon="ph-upload" color="information-100" />
            </p>
            <p class="ifc-pa-4">
              <ifc-typography
                :text="$t('message.uploadMedias')"
                typography-style="caption-small"
                text-weight="400"
                style="text-align: center"
              />
            </p>
          </div>
        </template>
      </ifc-upload>
      <ifc-typography
        typography-style="caption-small"
        text-weight="400"
        style="text-align: left"
      >
        <span class="ifc-color--neutral-400">{{
          `${$t('label.uploadMedias')} ${type}.`
        }}</span>
      </ifc-typography>

      <ifc-typography
        v-if="fileUp.size > 10485760"
        typography-style="caption-small"
        text-weight="400"
        style="text-align: center"
      >
        <span class="ifc-color--error-200">{{
          `${$t('message.uploadMediasErrSize')} 10Mb`
        }}</span>
      </ifc-typography>
      <div
        class="ifc-list-file ifc-py-12"
        v-if="fileUp.status && fileUp.size <= 10485760"
      >
        <div class="catalog-spradsheets-upload-v2">
          <div class="catalog-spradsheets-upload-v2-content">
            <ifc-upload-icon
              size="md"
              :label="fileUp.label"
              :extension="fileUp.ext"
              :color="fileUp.color"
            />

            <ifc-typography
              :text="fileUp.title"
              typography-style="caption-small"
              text-weight="400"
              style="text-align: left"
            />
          </div>
          <button type="button" @click="closeUpload()">
            <ifc-icon
              name="ph-trash"
              type="filled"
              class="ifc-pl-4"
              iconColor="error-400"
              iconSize="24"
            />
          </button>
        </div>
        <!-- <ifc-upload-progress
          class="catalog-spradsheets-upload"
          :class="fileUp.ext === 'zip' ? 'text-black' : 'text-white'"
          :color="fileUp.color"
          :title="fileUp.title"
          :label="fileUp.ext"
          @onClose="closeUpload()"
        /> -->
      </div>
      <div class="ifc-mt-20">
        <ifc-button
          class="col-12"
          size="large"
          :label="`${$t('button.conclude')}`"
          @click="attachMedias()"
          color="information-200"
          :disabled="!fileUp.status || fileUp.size > 10485760"
        />

        <!-- <ifc-button
          v-if="fileUp.process < 100"
          class="col-12 mt-6"
          size="large"
          :label="`${$t('button.conclude')}`"
          @click="onUpload()"
          color="information-200"
          :disabled="!fileUp.status || fileUp.size > 10485760"
        /> -->
        <!-- <ifc-button
          v-if="fileUp.process >= 100"
          class="col-12 mt-6"
          size="large"
          :label="`${$t('button.close')}`"
          @click="closeModal()"
          color="information-200"
          outlined
        /> -->
      </div>
    </div>
  </ifc-dialog>
</template>

<script>
// import { uploadMediaSku } from '@/services/sku'

export default {
  name: 'UploadImportFiles',
  components: {},
  props: ['type', 'label', 'sku', 'channelId'],
  data() {
    return {
      getFiles: [],
      fileUp: {
        title: '',
        color: '',
        process: 0,
        status: false,
        ext: '',
        item: null
      },
      selectedFile: undefined,
      uploadMime:
        'image/apng, image/avif, image/gif, image/jpg, image/jpeg, image/png, image/svg+xml, image/webp, video/mpeg, video/mp4, video/webm'
    }
  },
  watch: {
    modalUpload: {
      handler(m) {
        if (!m) {
          this.closeUpload()
        }
      },
      deep: true
    }
  },
  computed: {
    attributes() {
      const attributes = {
        ...this.$props,
        ...this.$attrs
      }

      return attributes
    },
    listeners() {
      return this.$listeners
    },
    uploadType: {
      get() {
        let types = this.type
        types = `${types}, ${types.toUpperCase()}`
        return types
      }
    }
  },
  methods: {
    errorHasEmitted(err) {
      console.error(err)
    },
    getListUpload(file) {
      let ext = file[0].name
      ext = ext.split('.').reverse()
      ext = ext[0].toLowerCase()
      this.fileUp = {
        title: file[0].name,
        color: 'information-200',
        process: 0,
        status: this.type.indexOf(ext) > -1,
        size: file[0].size,
        ext: ext,
        item: file[0]
      }
    },
    attachMedias() {
      this.$emit('mediaSelected', {
        data: {
          sku: this.sku,
          label: this.label
        },
        file: this.fileUp.item
      })
      this.closeModal()
    },
    // async onUpload() {
    //   const data = {
    //     sku: this.sku,
    //     label: this.label
    //   }
    //   try {
    //     const msg = await uploadMediaSku(this.fileUp.item, data, this.channelId)
    //     this.fileUp.process = parseInt(msg.process)
    //     this.fileUp.color = msg.color
    //   } catch {
    //     this.$emit('error')
    //     this.closeModal()
    //   }
    // },
    closeUpload() {
      this.fileUp = {
        title: '',
        color: '',
        process: 0,
        status: false,
        ext: '',
        item: null
      }
    },
    closeModal() {
      this.closeUpload()
      this.$emit('onClose')
    }
  }
}
</script>

<style scoped>
.drag-msg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ifc-top-negative {
  margin: -50px -8px 0;
}
.ifc-upload.ifc-top-negative .v-card__actions {
  padding: 0 !important;
}
.ifc-list-file {
  position: relative;
}
.ifc-icon-position {
  position: absolute;
  display: block;
  top: 10px;
  right: 5px;
  cursor: pointer;
}
</style>
<style>
.catalog-spradsheets-upload-v2 {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.catalog-spradsheets-upload-v2-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.catalog-spradsheets-upload .ifc-upload-progress__title {
  margin-bottom: 6px;
  width: 200px !important;
  word-break: break-all !important;
}

.catalog-spradsheets-upload .ifc-upload-progress__title {
  margin-bottom: 6px;
  width: 200px !important;
  word-break: break-all !important;
}
.catalog-spradsheets-upload .ifc-icon-position {
  position: absolute;
  display: block;
  top: 15px;
  right: 5px;
  cursor: pointer;
}
.catalog-spradsheets-upload .text-black > .ifc-upload-icon {
  color: #fff !important;
}
.catalog-spradsheets-upload .text-black > .ifc-upload-icon {
  color: #000 !important;
}
</style>
