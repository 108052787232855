<template>
  <ifc-dialog v-bind="attributes" v-on="listeners" maxWidth="465">
    <div>
      <p style="text-align: center">
        <ifc-icon
          name="ph-info"
          type="filled"
          iconClass="color-black"
          iconColor="information-200"
          :iconSize="40"
        />
      </p>
      <ifc-typography
        :text="$t('titles.importInformation')"
        typography-style="h3-small"
        text-weight="700"
        class="ifc-color--neutral-600 ifc-mb-8"
        style="text-align: center"
      />
      <ul
        class="ifc-instruction-modal ifc-mb-10 ifc-color--neutral-600"
        v-html="$t('uploadInfo.text')"
      ></ul>
      <ifc-button
        size="small"
        :label="$t('button.understand')"
        @click="closeModal(true)"
        color="information-200"
        block
      />
    </div>
  </ifc-dialog>
</template>

<script>
export default {
  name: 'UploadImportFilesInfo',
  components: {},
  data() {
    return {}
  },
  computed: {
    attributes() {
      const attributes = {
        ...this.$props,
        ...this.$attrs
      }

      return attributes
    },
    listeners() {
      return this.$listeners
    }
  },
  methods: {
    closeModal(info) {
      this.$emit('onClose', info)
    }
  }
}
</script>

<style>
ul.ifc-instruction-modal > li {
  border-bottom: 1px solid #e7e6f2;
  font-size: 14px;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
ul.ifc-instruction-modal > li:last-child {
  border-bottom: none;
}
</style>
