<template>
  <div class="editor-toolbar">
    <button @click="toggleBold" :class="{ active: editor.isActive('bold') }">
      Bold
    </button>
    <button
      @click="toggleItalic"
      :class="{ active: editor.isActive('italic') }"
    >
      Italic
    </button>
    <button
      @click="toggleUnderline"
      :class="{ active: editor.isActive('underline') }"
    >
      Underline
    </button>
    <button
      @click="toggleHeading(1)"
      :class="{ active: editor.isActive('heading', { level: 1 }) }"
    >
      H1
    </button>
    <button
      @click="toggleHeading(2)"
      :class="{ active: editor.isActive('heading', { level: 2 }) }"
    >
      H2
    </button>
    <button
      @click="toggleHeading(3)"
      :class="{ active: editor.isActive('heading', { level: 3 }) }"
    >
      H3
    </button>
    <button
      @click="toggleOrderedList"
      :class="{ active: editor.isActive('orderedList') }"
    >
      OL
    </button>
    <button
      @click="toggleBulletList"
      :class="{ active: editor.isActive('bulletList') }"
    >
      UL
    </button>
    <button
      @click="toggleBlockquote"
      :class="{ active: editor.isActive('blockquote') }"
    >
      Quote
    </button>
    <button @click="toggleCode" :class="{ active: editor.isActive('code') }">
      Code
    </button>
    <button @click="setLink" :class="{ active: editor.isActive('link') }">
      Link
    </button>
  </div>
</template>

<script>
export default {
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleBold() {
      this.editor.chain().focus().toggleBold().run()
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run()
    },
    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run()
    },
    toggleHeading(level) {
      this.editor.chain().focus().toggleHeading({ level }).run()
    },
    toggleOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run()
    },
    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run()
    },
    toggleBlockquote() {
      this.editor.chain().focus().toggleBlockquote().run()
    },
    toggleCode() {
      this.editor.chain().focus().toggleCode().run()
    },
    setLink() {
      const url = prompt('Enter the URL')
      if (url) {
        this.editor.chain().focus().setLink({ href: url }).run()
      }
    }
  }
}
</script>

<style scoped>
.editor-toolbar {
  border-bottom: 1px solid #c6d3de;
  margin-bottom: 10px;
}

.editor-toolbar button:not(:first-child) {
  padding-inline: 5px;
}

.editor-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  padding-block: 10px;
  margin-right: 5px;
}

.editor-toolbar button.active {
  font-weight: bold;
}
</style>
