<template>
  <div class="ifc-tab-content">
    <v-row>
      <v-col cols="12" class="ifc-align-right align-item-center ifc-py-none">
        <ifc-switch
          class="ifc-switch-inline ifc-mt-none ifc-mx-10"
          v-model="content.isActive"
          :label="`${$t('status.active.short')}?`"
          inset
          color="success"
        />
        <ifc-button
          class="ifc-ml-12 ifc-mr-none ifc-nt-4"
          prependIcon="ph-arrow-left"
          :label="`${$t('button.back')} ${$t('label.sku')}`"
          color="information-200"
          size="small"
          outlined
          @click="goBack()"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="content.skuId" class="ifc-py-none">
        <ifc-input
          :label="$t('label.id')"
          v-model.number="content.skuId"
          disabled
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-if="content.createdAtView"
        class="ifc-py-none"
      >
        <ifc-input
          :label="$t('label.createdAt')"
          v-model="content.createdAtView"
          disabled
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-if="content.updatedAtView"
        class="ifc-py-none"
      >
        <ifc-input
          :label="$t('label.updatedAt')"
          v-model="content.updatedAtView"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.sku')}*`"
          v-model="content.externalCode"
          :rules="rules.required"
        />
      </v-col>
      <v-col cols="12" sm="12" md="8" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.name')}*`"
          v-model="content.name"
          :rules="rules.required"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <date-modal
          :label="$t('label.activationDate')"
          v-model="content.activationDate"
          :dateEnd="content.inactivationDate"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <date-modal
          :label="$t('label.inactivationDate')"
          v-model="content.inactivationDate"
          :dateStart="content.activationDate"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.ean')}*`"
          v-model="
            ((content.codes || []).find((obj) => obj.type === 'EAN') || {})
              .value
          "
          :rules="rules.required"
          mask="XXXXXXXXXXXXXXXXXXX"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ifc-separetor">
        <ifc-typography
          :text="$t('titles.cashier')"
          typography-style="h4"
          text-weight="700"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.dun')}`"
          v-model="
            ((content.codes || []).find((obj) => obj.type === 'DUN') || {})
              .value
          "
          mask="###################"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-select
          appendIcon="expand_more"
          keyLabel="label"
          keyValue="value"
          v-model="content.batchControl"
          :options="listBatchControl"
          :label="`${$t('titles.batchControl')}`"
          :placeholder="$t('label.select')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.quantity')}`"
          v-model.number="content.boxQuantity"
          type="number"
          :rules="rules.optionalNumber"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ifc-separetor">
        <ifc-typography
          :text="$t('titles.properties')"
          typography-style="h4"
          text-weight="700"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.height')} (Cm)*`"
          v-model.number="content.height"
          type="number"
          :rules="rules.number"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.width')} (Cm)*`"
          v-model.number="content.width"
          type="number"
          :rules="rules.number"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.length')} (Cm)*`"
          v-model.number="content.length"
          type="number"
          :rules="rules.number"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.weight')} (Gr)*`"
          v-model.number="content.weight"
          type="number"
          :rules="rules.number"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-select
          appendIcon="expand_more"
          keyLabel="label"
          keyValue="value"
          v-model="content.measurementUnit"
          :options="listMensure"
          :label="`${$t('label.measurementUnit')}*`"
          :placeholder="$t('label.select')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <ifc-typography
          class="ifc-header-title"
          typography-style="h4"
          text-weight="700"
        >
          <div>{{ $t('titles.attributes') }}</div>
          <div>
            <ifc-tooltip
              v-bind="$props"
              bottom
              max-width="600"
              background-color="information-100"
              text-color="shade-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <ifc-button
                  v-bind="attrs"
                  v-on="on"
                  icon="ph-plus-circle"
                  :label="$t('button.newAttributeValue')"
                  color="information-200"
                  @click="modalOpen('modalAttributes')"
                />
              </template>
              <span> {{ `${$t('button.newAttributeValue')}` }} </span>
            </ifc-tooltip>
          </div>
        </ifc-typography>
        <ifc-table :headers="headers" :items="attrValueList">
          <tbody default="{ items }" v-if="attrValueList.length > 0">
            <tr v-for="(item, i) in attrValueList" :key="i">
              <td v-if="editAttrValues === i">
                <ifc-combobox
                  class="ifc-input-no-hint"
                  appendIcon="expand_more"
                  keyValue="attributeId"
                  keyLabel="name"
                  v-model="nameSelect"
                  :options="attributeOptionsName"
                  size="small"
                  color="primary"
                  :hideMoreOptions="false"
                  :deletableChips="false"
                  :multiple="false"
                  :placeholder="$t('label.name')"
                  returnObject
                  :clearable="false"
                />
              </td>
              <td v-else>
                {{ item.name }}
              </td>
              <td v-if="editAttrValues === i">
                <ifc-combobox
                  class="ifc-input-no-hint"
                  appendIcon="expand_more"
                  keyValue="attributeValue"
                  keyLabel="attributeValue"
                  v-model="valueSelect"
                  :options="attributeOptionsValue"
                  size="small"
                  color="primary"
                  :hideMoreOptions="false"
                  :deletableChips="false"
                  :multiple="false"
                  :placeholder="$t('label.value')"
                  returnObject
                  :clearable="false"
                  ref="attributeValueComboBox"
                  @click="getScrollPosition"
                  @input.native="debouncedSetFilterString"
                />
              </td>
              <td v-else>
                {{ item.attributeValue ? item.attributeValue : '-' }}
              </td>
              <td
                width="60"
                class="ifc-align-center"
                v-if="editAttrValues === i"
              >
                <ifc-button
                  icon="ph-check"
                  size="extra-small"
                  @click="setEditAttrValue(i, item)"
                />
              </td>
              <td width="60" class="ifc-align-center" v-else>
                <ifc-button
                  icon="ph-pencil-simple"
                  size="extra-small"
                  @click="editAttrValuesOpen(i, item)"
                />
              </td>
              <td
                width="60"
                class="ifc-align-center"
                v-if="editAttrValues === i"
              >
                <ifc-button
                  icon="ph-x"
                  @click="editAttrValuesClose()"
                  size="extra-small"
                />
              </td>
              <td width="60" class="ifc-align-center" v-else>
                <ifc-button
                  icon="ph-trash"
                  @click="delAttribute(item)"
                  size="extra-small"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">
                <span>
                  {{ $t('table.noDataText') }}
                </span>
              </td>
            </tr>
          </tbody>
        </ifc-table>
        <add-attr-values
          :options="attributesOptions"
          local="sku"
          @save="setAttributeValue"
          :channelId="channelId"
        />
        <ifc-typography
          typography-style="body-small"
          text-weight="500"
          class="ifc-mt-16"
          >{{
            `(*) ${$t('message.attributeRequired')}: ${getAttributesRequired()}`
          }}
        </ifc-typography>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <ifc-typography
          class="ifc-header-title"
          typography-style="h4"
          text-weight="700"
        >
          <div>{{ `${$t('titles.medias')}: ${content.medias.length}` }}</div>
          <div>
            <ifc-tooltip
              v-bind="$props"
              bottom
              max-width="600"
              background-color="information-100"
              text-color="shade-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <ifc-button
                  v-bind="attrs"
                  v-on="on"
                  icon="ph-plus-circle"
                  :color="
                    !!content.externalCode ? 'information-200' : 'neutral-200'
                  "
                  @click="modalOpen('modalMedias')"
                  :disabled="!content.externalCode"
                />
              </template>
              <span> {{ `${$t('button.add')} ${$t('label.media')}` }} </span>
            </ifc-tooltip>
            <ifc-tooltip
              v-bind="$props"
              bottom
              max-width="600"
              :background-color="
                content.medias.length === 0 ? 'neutral-100' : 'error-100'
              "
              text-color="shade-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <ifc-button
                  v-bind="attrs"
                  v-on="on"
                  icon="ph-trash"
                  :color="
                    content.medias.length === 0 ? 'neutral-200' : 'error-200'
                  "
                  @click="removeAllMedias()"
                  :disabled="content.medias.length === 0"
                />
              </template>
              <span> {{ $t('label.removeAll') }} </span>
            </ifc-tooltip>
          </div>
        </ifc-typography>
        <img-sku-gallery
          v-model="content.medias"
          @removedMedias="removedMedias"
          @editedMedia="editedMedia"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ifc-typography
          typography-style="body-small"
          text-weight="500"
          class="ifc-my-16"
        >
          {{ `(*) ${$t('message.inputRequired')}` }}
        </ifc-typography>
      </v-col>
      <v-col cols="12" class="ifc-align-center">
        <ifc-button
          class="ifc-ma-4"
          prependIcon="ph-arrow-left"
          :label="`${$t('button.back')} ${$t('label.sku')}`"
          color="information-200"
          size="small"
          outlined
          @click="goBack()"
        />
        <ifc-button
          class="ifc-ma-4"
          prependIcon="ph-check"
          :label="`${
            !this.content.skuId ? $t('button.save') : $t('button.update')
          } ${$t('label.sku')}`"
          color="information-200"
          size="small"
          @click="setSkuContent()"
          :disabled="buttonValidate"
        />
      </v-col>
    </v-row>
    <create-attr-value
      persistent
      v-model="modalAttributes"
      :title="$t('titles.attributeValueNew')"
      :options="attributesOptions"
      :data="attributesSelected"
      :channelId="channelId"
      local="sku"
      @blur="modalClose('modalAttributes')"
      @onClose="modalClose('modalAttributes')"
      @save="saveAttrValue"
    />
    <modal-medias
      persistent
      :value="modalMedias"
      :title="`${$t('button.add')} ${$t('label.media')}`"
      :type="uploadType"
      :channelId="channelId"
      :subtitle="$t('message.skuMidias')"
      :label="(content.medias.length + 1).toString()"
      :sku="content.externalCode"
      @mediaSelected="updateMedia"
      @blur="modalClose('modalMedias')"
      @onClose="modalClose('modalMedias')"
      @error="uploadError()"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import imgSkuGallery from '@/components/Catalog/Medias/mediaSkuGallery.vue'
import DateModal from '@/components/Catalog/Modals/DateModal.vue'
import AddAttrValues from '@/components/Catalog/Tabs/Products/Sku/AddAttributesValue.vue'
import CreateAttributes from '@/components/Catalog/Tabs/Products/Sku/CreateAttrValue.vue'
import ModalMedias from '@/components/Catalog/Uploads/UploadSkuMedias.vue'
import {
  showNotifyDelete,
  showNotifyInfo,
  showNotifySuccess,
  showNotifyWarning
} from '@/plugins/notify'
import { getAttributeValue, getAttributeValues } from '@/services/attributes'
import {
  deleteMediaSku,
  getSkuItem,
  setCreateSku,
  setUpdateSku,
  uploadMediaSku
} from '@/services/sku'
import Vue from 'vue'

export default {
  name: 'tabSkuDetails',
  components: {
    'img-sku-gallery': imgSkuGallery,
    'date-modal': DateModal,
    'create-attr-value': CreateAttributes,
    'modal-medias': ModalMedias,
    'add-attr-values': AddAttrValues
  },
  props: ['id', 'data', 'skuId', 'options', 'channelId'],
  data() {
    return {
      debouncedSetFilterString: _.debounce(this.setFilterString, 1500),
      listMensure: [
        { label: this.$t('label.unit'), value: 'UN' },
        { label: this.$t('label.box'), value: 'CX' }
      ],
      listBatchControl: [
        { label: this.$t('status.no.short'), value: false },
        { label: this.$t('status.yes.short'), value: true }
      ],
      headers: [
        {
          text: this.$t('label.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('label.value'),
          align: 'start',
          sortable: true,
          value: 'attributeValue'
        },
        {
          align: 'start',
          sortable: false,
          value: 'edit'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'delete'
        }
      ],
      content: {
        isActive: true,
        kit: false,
        canonical: '',
        productId: this.id,
        batchControl: false,
        boxQuantity: null,
        name: '',
        sku: null,
        dun: null,
        codes: [
          {
            type: 'EAN',
            value: null
          },
          {
            type: 'DUN',
            value: null
          }
        ],
        height: 0,
        length: 0,
        width: 0,
        weight: 0,
        measurementUnit: 'UN',
        medias: [],
        activationDate: null,
        inactivationDate: null,
        modal: null,
        keyWords: null,
        metaTagDescription: null,
        attributes: [],
        skuId: null,
        createdAt: null,
        updatedAt: null
      },
      rules: {
        required: [(v) => v?.trim() !== '' || this.$t('message.required')],
        optionalNumber: [
          (v) => {
            if (v === null || v === '') {
              return true
            }
            return v > 0 || this.$t('validation.number')
          }
        ],
        number: [
          (v) => {
            return v > 0 || this.$t('validation.number')
          },
          (v) => !!v || this.$t('message.required')
        ],
        email: [
          (v) => {
            const val = v ? /\S+@\S+\.\S+/.test(v) : true
            return val || this.$t('validation.email')
          },
          (v) => !!v || this.$t('message.required')
        ]
      },
      attrValueList: [],
      attrValueStatus: null,
      attrValueSuccess: null,
      editAttrValues: null,
      attributesOptionsValue: [],
      attributesSelected: [],
      listAttrRequired: [],
      getValidation: false,
      modalAttributes: false,
      modalMedias: false,
      uploadType:
        '.apng, .avif, .gif, .jpg, .jpeg, .png, .svg, .webp, .mp4, .webm .mpeg',
      buttonValidate: true,
      nameSelect: {},
      valueSelect: {},
      attributeSelection: {},
      unsavedMedia: false,
      newMedias: []
    }
  },
  watch: {
    data: {
      handler() {
        this.getSkuData()
      },
      deep: true
    },
    attrValueList: {
      handler(a) {
        this.content.attributes = a
        this.chkSkuContent()
      },
      deep: true
    },
    attributesOptions: {
      handler() {
        this.listAttrRequired = this.getAttributesRequired()
        this.chkSkuContent()
      },
      deep: true
    },
    content: {
      handler(v) {
        this.chkSkuContent()
      },
      deep: true
    },
    attributeSelection: {
      handler(s) {
        this.getOptionsValue(s)
      },
      deep: true
    },
    nameSelect: {
      handler(n) {
        this.errCreateMsg = null
        this.attributeSelection = {
          attributeId: n.attributeId,
          name: n.name
        }
      },
      deep: true
    },
    valueSelect: {
      handler(v) {
        this.errCreateMsg = null
        this.attributeSelection.attributeValueId = v.attributeValueId
        this.attributeSelection.attributeValue = v.attributeValue
      },
      deep: true
    },
    channelId: {
      required: false
    }
  },
  computed: {
    attributeOptionsName() {
      const opt = []
      this.attributesOptions.forEach((obj) => {
        const arr = {
          name: obj.attribute.name,
          attributeId: obj.attribute.attributeId
        }
        opt.push(arr)
      })
      return opt
    },
    attributesOptions: {
      get() {
        return this.options
      }
    }
  },
  methods: {
    generateImageUrl(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          const imageUrl = reader.result
          resolve(imageUrl)
        }
        reader.onerror = (error) => {
          reject(error)
        }
        reader.readAsDataURL(file)
      })
    },
    async updateMedia(selectedMedia) {
      const mediaUrl = await this.generateImageUrl(selectedMedia.file)

      const media = {
        isNew: true,
        fileId: selectedMedia.file.name + '_' + Date.now(),
        url: mediaUrl,
        mediaType: selectedMedia.file.type.includes('image')
          ? 'photo'
          : 'video',
        metadata: {
          contentType: selectedMedia.file.type
        }
      }

      this.content.medias.push(media)
      this.newMedias.push(selectedMedia)
    },
    setFilterString(e) {
      const { value } = e.target
      this.getValue(value)
    },
    getScrollPosition() {
      const dropdown =
        this.$refs.attributeValueComboBox[0]?.$refs['v-combobox'].$refs.menu
          ?.$children[0]?.$el
      if (dropdown) {
        dropdown.addEventListener('scroll', this.handleScroll)
      }
    },
    handleScroll(event) {
      const scrollHeight = event.target.scrollHeight - event.target.clientHeight
      const scrollPosition = event.target.scrollTop

      if (scrollPosition === scrollHeight) {
        this.getValues()
      }
    },
    merge(arr1, arr2) {
      const newArr = arr1.concat(arr2)
      return newArr.filter((item, index) => newArr.indexOf(item) === index)
    },
    async getValue(value) {
      const elementPos = this.options
        .map((item) => item.attribute.attributeId)
        .indexOf(this.attributeSelection.attributeId)
      const res = await getAttributeValue(
        this.attributeSelection.attributeId,
        this.channelId,
        value
      )
      if (res.data.length > 0) {
        const newData = this.merge(
          res.data,
          this.options[elementPos].attribute.attributeValues.data
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'data',
          newData
        )
        this.getOptionsValue({
          attributeId: this.options[elementPos].attribute.attributeId,
          attributeValue:
            this.options[elementPos].attribute.attributeValues.value,
          attributeValueId:
            this.options[elementPos].attribute.attributeValues.attributeValueId,
          name: this.options[elementPos].attribute.name
        })
        this.$forceUpdate()
      }
    },
    async getValues() {
      const elementPos = this.options
        .map((item) => item.attribute.attributeId)
        .indexOf(this.attributeSelection.attributeId)
      const currentPage =
        this.options[elementPos].attribute.attributeValues.currentPage
      const pages = this.options[elementPos].attribute.attributeValues.pages
      if (currentPage < pages) {
        const res = await getAttributeValues(
          this.attributeSelection.attributeId,
          this.channelId,
          this.options[elementPos].attribute.attributeValues.nextPage
        )
        const newData = this.merge(
          this.options[elementPos].attribute.attributeValues.data,
          res.data
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'currentPage',
          res.currentPage
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'nextPage',
          res.nextPage
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'prevPage',
          res.prevPage
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'data',
          newData
        )
        this.getOptionsValue({
          attributeId: this.options[elementPos].attribute.attributeId,
          attributeValue:
            this.options[elementPos].attribute.attributeValues.value,
          attributeValueId:
            this.options[elementPos].attribute.attributeValues.attributeValueId,
          name: this.options[elementPos].attribute.name
        })

        this.$forceUpdate()
      }
    },
    async getSkuData() {
      const data =
        this.skuId !== 'new'
          ? await getSkuItem(this.skuId, this.channelId)
          : this.content

      const isValidArray = (arr) => Array.isArray(arr) && arr.length > 0

      if (data) {
        const { codes = [], ...rest } = data

        this.content = {
          ...rest,
          codes: isValidArray(codes) ? codes : this.content.codes
        }

        if (isValidArray(codes)) {
          this.content.codes.forEach((code) => {
            const codeIndex = codes.findIndex(
              (c) => c.type?.toLowerCase() === code.type?.toLowerCase()
            )
            if (codeIndex > -1) {
              codes[codeIndex].value = code.value
            }
          })
        }

        if (Array.isArray(this.content?.attributes)) {
          this.content.attributes = this.content.attributes.map(
            (obj, index) => ({
              ...obj,
              _id: index + 1
            })
          )
        }

        const medias =
          data.medias
            .map((media) => ({
              ...media,
              mediaType: media.mediaType || 'photo',
              metadata: media.metadata || { contentTpe: 'image/jpg' }
            }))
            .sort((a, b) => a.sortOrder - b.sortOrder) || []

        if (this.content.codes.length > 1) {
          this.content.dun = this.content.codes.filter(
            (val) => val.type === 'DUN'
          )[0].value
        }
        this.content.medias = [...medias]
        this.content.createdAtView = this.content.createdAt
          ? this.chkDate(this.content.createdAt)
          : ''

        this.content.activationDate = this.content.activationDate
          ? this.chkDate(this.content.activationDate, true)
          : ''
        this.content.inactivationDate = this.content.inactivationDate
          ? this.chkDate(this.content.inactivationDate, true)
          : ''

        setTimeout(() => {
          this.attrValueList = this.content.attributes
        }, 100)
      } else {
        const error = {
          msg: `${this.$t('message.notFound')} ${this.$t(
            'message.skuIdNotFound'
          )} <b>${this.skuId}</b>`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyInfo(error)
        this.showNotify(notify)
        this.goBack()
      }
    },
    async setSkuContent(goback = true) {
      const listAttr = this.content.attributes.map(
        (obj) => obj.attributeValueId
      )

      const listImg = this.content.medias
        ?.filter((media) => !media.isNew)
        .map(({ sortOrder, ...media }, index) => {
          const image = media

          image.metadataContentType = media.metadata.contentType
          image.width = media.metadata.width
          image.height = media.metadata.height
          image.sortOrder = index + 1

          delete image.metadata

          return image
        })

      const codes = []

      const dunCode = this.content.codes?.find((obj) => obj.type === 'DUN')
      if (dunCode && dunCode.value) {
        codes.push({
          type: 'DUN',
          value: dunCode.value
        })
      }

      const eanCode = this.content.codes?.find((obj) => obj.type === 'EAN')
      if (eanCode && eanCode.value) {
        codes.push({
          type: 'EAN',
          value: eanCode.value
        })
      }

      const boxQuantityIsEmpty =
        this.content.boxQuantity === '' ||
        this.content.boxQuantity === null ||
        this.content.boxQuantity === undefined

      const boxQuantity = boxQuantityIsEmpty ? null : this.content.boxQuantity

      const params = {
        skuId: this.content.skuId ? this.content.skuId : undefined,
        channelId: this.channelId,
        brandId: 1, // TODO remove
        isActive: this.content.medias.length ? this.content.isActive : false,
        isKit: this.content.kit,
        canonical: this.content.canonical,
        productId: parseInt(this.id),
        name: this.content.name,
        externalCode: this.content.externalCode,
        codes,
        height: this.content.height,
        length: this.content.length,
        width: this.content.width,
        weight: this.content.weight,
        measurementUnit: this.content.measurementUnit,
        medias: listImg,
        activationDate: this.content.activationDate
          ? this.dateToUs(this.content.activationDate)
          : undefined,
        inactivationDate: this.content.inactivationDate
          ? this.dateToUs(this.content.inactivationDate)
          : undefined,
        modal: this.content.modal,
        keyWords: this.content.keyWords,
        metaTagDescription: this.content.metaTagDescription,
        attributeValueIds: listAttr,
        batchControl: this.content.batchControl,
        boxQuantity,
        createdAt: this.content.createdAt ? this.content.createdAt : undefined,
        updatedAt: this.content.updatedAt ? this.content.updatedAt : undefined
      }

      let success
      let error

      try {
        if (!this.content.skuId) {
          await setCreateSku(params)
        } else {
          await setUpdateSku(this.content.skuId, params)
        }

        success = {
          msg: `${this.$t('message.sku')} ${this.content.name} ${
            this.content.skuId
              ? this.$t('message.update')
              : this.$t('message.create')
          }`,
          ok: this.$t('button.ok')
        }
      } catch (e) {
        console.error(e)
        error = {
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        }

        this.showNotify(
          showNotifyInfo({
            msg: `${this.$t('message.errorRequest')}`,
            ok: this.$t('button.ok')
          })
        )
        return
      }

      try {
        const uploadPromises = this.newMedias.map((media) =>
          uploadMediaSku(media.file, media.data, this.channelId)
        )

        await Promise.all(uploadPromises)
      } catch (e) {
        console.error(e)
      }

      if (success) {
        this.showNotify(showNotifySuccess(success), 'sku', goback)
      } else {
        this.showNotify(showNotifyInfo(error), 'sku')
      }
    },
    chkAttrContent() {
      let getRequired = false
      const isRequired = this.listAttrRequired.length > 0
      const isData = this.attrValueList.length > 0
      if (isRequired) {
        if (isData) {
          let chkList = []
          this.listAttrRequired.forEach((req) => {
            let count = 0
            this.attrValueList.forEach((obj) => {
              if (req === obj.name) {
                count++
              }
            })
            chkList.push(count)
          })
          getRequired = !(chkList.indexOf(0) > -1)
          chkList = []
        } else {
          getRequired = false
        }
      } else {
        getRequired = true
      }
      this.getValidation = getRequired
    },
    chkSkuContent() {
      this.chkAttrContent()

      const eanCode = this.content.codes?.find((obj) => obj.type === 'EAN')
      const eanValue = eanCode?.value.trim() !== ''
      const externalCodeValue = this.content.externalCode.trim() !== ''

      const boxQuantityIsValid =
        this.content.boxQuantity === '' ||
        this.content.boxQuantity === null ||
        this.content.boxQuantity === undefined ||
        this.content.boxQuantity > 0

      this.buttonValidate = !(
        !!this.content.productId &&
        !!this.content.name &&
        !!this.content.height &&
        !!this.content.width &&
        !!this.content.length &&
        !!this.content.weight &&
        !!this.content.measurementUnit &&
        !!externalCodeValue &&
        !!eanValue &&
        !!boxQuantityIsValid &&
        this.getValidation
      )
    },
    getAttributesRequired(type) {
      const list = this.attributesOptions.filter((obj) => {
        return obj.isRequired === true
      })
      const attrs = []
      list.forEach((obj) => {
        attrs.push(obj?.attribute?.name || obj.attributeId)
      })
      return attrs
    },
    chkDate(date, short = false) {
      if (!date) return date

      return short
        ? this.$moment(date).zone(0).format('DD/MM/YYYY')
        : this.$moment(date).zone(0).format('DD/MM/YYYY HH:mm:ss')
    },
    dateToUs(date) {
      if (!date) return date
      return date.split('/').reverse().join('-')
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    setCanonical(name) {
      this.content.canonical = name
    },
    setAttributeValue(val) {
      this.attrValueStatus = null
      const item = val
      const data = this.attrValueList
      const ckhAttrValue = this.attrValueList.find((obj) => {
        return (
          obj.attributeValue.toLowerCase() ===
            item.attributeValue.toLowerCase() &&
          obj.name.toLowerCase() === item.name.toLowerCase()
        )
      })
      if (ckhAttrValue) {
        const error = {
          msg: `${this.$t('message.attributeValue')} ${this.$t(
            'message.exists'
          )}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyInfo(error)
        this.showNotify(notify)
      } else {
        this.attrValueStatus = null
        if (item._id) {
          const idx = item._id - 1
          data[idx] = item
        } else {
          const cont = this.attrValueList.length + 1
          item._id = cont
          data.push(item)
        }
        this.$emit('input', data)
      }
    },
    setEditAttrValue(i, val) {
      const item = val
      const idx = i
      item._id = i
      const ckhAttrValue = this.attrValueList.find((obj) => {
        return (
          obj.attributeValue.toLowerCase() ===
            this.valueSelect.attributeValue.toLowerCase() &&
          obj.name.toLowerCase() === this.nameSelect.name.toLowerCase()
        )
      })
      if (ckhAttrValue) {
        this.editAttrValues = i
        const error = {
          msg: `${this.$t('message.attributeValue')} ${this.$t(
            'message.exists'
          )}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyInfo(error)
        this.showNotify(notify)
      } else {
        this.attrValueStatus = null
        if (idx >= 0) {
          this.attrValueList[idx] = {
            _id: item._id,
            attributeId: this.nameSelect.attributeId,
            name: this.nameSelect.name,
            attributeValueId: this.valueSelect.attributeValueId,
            attributeValue: this.valueSelect.attributeValue
          }
        }
        this.editAttrValues = null
      }
    },
    editAttrValuesOpen(i, val) {
      const item = val
      this.editAttrValues = i
      this.nameSelect = {
        attributeId: item.attributeId,
        name: item.name
      }
      this.valueSelect = {
        attributeValue: item.attributeValue,
        attributeValueId: item.attributeValueId
      }
    },
    editAttrValuesClose() {
      this.editAttrValues = null
      this.nameSelect = {}
      this.valueSelect = {}
    },
    getOptionsValue(s) {
      this.attributeOptionsValue = []
      let list = []
      if (this.options.length > 0) {
        list = this.options.filter((obj) => {
          return obj.attribute.attributeId === s.attributeId
        })
      }
      if (list.length) {
        list[0].attribute.attributeValues.data.forEach((obj) => {
          const arr = {
            attributeValue: obj.value,
            attributeValueId: obj.attributeValueId
          }
          this.attributeOptionsValue.push(arr)
        })
      }
    },
    saveAttrValue() {
      this.editAttrValues = null
      this.$emit('refresh')
    },
    delAttribute(item) {
      const remove = {
        msg: `${this.$t('message.removeAttributeValue')} ${item.name}`,
        ok: this.$t('button.ok'),
        cancel: this.$t('button.cancel'),
        item: item
      }
      const notify = showNotifyDelete(remove)
      this.showNotify(notify, 'modalAttributes')
    },
    setDelAttribute(item) {
      this.attrValueList = this.attrValueList.filter((obj) => {
        return obj._id !== item._id
      })
    },
    removeAllMedias() {
      const remove = {
        msg: this.$t('message.removeSkuAllMedias'),
        info: 'removeMedias'
      }
      const notify = showNotifyDelete(remove)
      this.showNotify(notify, 'removeMedias')
    },
    removeAllMediasOk() {
      const delMedias = []

      this.content.medias.forEach((media) => {
        if (!media.isNew) {
          delMedias.push(media.fileId)
        }
      })

      if (delMedias.length > 0) {
        this.removedMedias(delMedias)
      }

      this.content.medias = []
    },
    uploadError() {
      const error = {
        msg: `${this.$t('message.errorRequest')}`,
        ok: this.$t('button.ok')
      }
      const notify = showNotifyInfo(error)
      this.showNotify(notify)
    },
    modalOpen(modal, item) {
      const unsavedMediaMessage = () => {
        const error = {
          msg: `${this.$t('message.unsavedMedias')}
          ${
            !this.content.skuId
              ? this.$t('button.save')
              : this.$t('button.update')
          } ${this.$t('label.sku')}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyInfo(error)
        this.showNotify(notify)
      }
      if (modal === 'modalAttributes') {
        const newAttr = {
          _id: null,
          name: null,
          attributeId: null,
          attributeValue: null,
          attributeValueId: null
        }
        this.attributesSelected = item || newAttr
        this[modal] = true
      }
      if (modal === 'modalMedias') {
        this.unsavedMedia ? unsavedMediaMessage() : (this[modal] = true)
      }
    },
    modalClose(modal) {
      if (modal === 'modalAttributes') {
        this.attributesSelected = []
      }
      this[modal] = false
    },
    goBack() {
      this.$emit('goBack')
    },
    showNotify(options, info, goback) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'success' && info === 'sku') {
            goback ? this.goBack() : this.getSkuData()
          }
          if (options.type === 'question' && info === 'modalAttributes') {
            this.setDelAttribute(options.item)
          }
          if (options.type === 'question' && info === 'removeMedias') {
            this.removeAllMediasOk()
          }
        })
        .catch(() => {})
    },
    async editedMedia(media) {
      const mediaIndex = this.content.medias.findIndex(
        (m) => m.fileId === media.fileId
      )

      if (mediaIndex > -1) {
        this.content.medias[mediaIndex] = {
          ...this.content.medias[mediaIndex],
          ...media
        }
      }
    },
    async removedMedias(delMedias) {
      if (!delMedias.length) {
        return !this.buttonValidate
          ? this.setSkuContent(false)
          : (this.unsavedMedia = true)
      }

      try {
        const remove = {
          skuId: this.content.skuId,
          channelId: this.channelId,
          data: delMedias
        }
        await deleteMediaSku(remove)
        await this.setSkuContent(false)
        this.getSkuData()
        const success = {
          msg: `${this.$t('message.removeSkuAllMediasOk')}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifySuccess(success)
        this.showNotify(notify)
      } catch {
        const warning = {
          msg: `${this.$t('message.removeSkuAllMediasError')}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyWarning(warning)
        this.showNotify(notify)
      }
    }
  },
  beforeDestroy() {
    const dropdown =
      this.$refs.attributeValueComboBox[0].$refs[
        'v-combobox'
      ].$refs.menu.$children[0].$el?.querySelector('.v-select-list')

    if (dropdown) {
      dropdown.removeEventListener('scroll', this.handleScroll)
    }
  },
  mounted() {
    if (this.id !== 'new') this.getSkuData()
  }
}
</script>
<style scoped>
.ifc-tab-content {
  position: relative;
  display: block;
  width: 100%;
}
.ifc-switch-inline {
  display: inline-block;
}
.ifc-align-right {
  text-align: right;
}
.ifc-align-center {
  text-align: center;
}
.ifc-nt-4 {
  top: -4px;
}
.ifc-header-title > div {
  position: relative;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
  box-sizing: border-box;
}
.ifc-header-title > div:last-child {
  text-align: right;
}
.ifc-input-no-hint {
  position: relative;
  display: block;
  margin-bottom: -35px;
}
</style>
import { log10 } from 'core-js/core/number'import { push } from
'core-js/core/array'import { log10 } from 'core-js/core/number'
