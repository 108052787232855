<template>
  <v-row class="ifc-px-none">
    <v-col
      cols="12"
      sm="12"
      md="2"
      lg="2"
      class="align-self-start ifc-px-2 ifc-pb-none ifc-pt-6"
    >
      <acl-chan :label="`${$t('label.channel')}`" v-model="filters.channelId" />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="4"
      lg="4"
      class="ifc-pt-32 ifc-px-4 ifc-pb-none"
    >
      <ifc-input
        v-model="filters.search"
        :placeholder="`${$t('label.searchFor')} ${$t('label.id')} ${$t(
          'label.or'
        )} ${$t('label.name')}`"
        prependIcon="search"
        @keyup="waitTyping()"
      />
    </v-col>

    <v-col cols="12" sm="12" md="2" lg="2" class="ifc-px-4 ifc-pb-none">
      <div class="ifc-min-select">
        <ifc-select
          appendIcon="expand_more"
          v-model="filters.active"
          :options="statusList"
          :label="`${$t('label.filterFor')} ${$t('label.status')}`"
          :placeholder="$t('label.select')"
          clearable
        />
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="4"
      lg="4"
      class="ifc-pt-32 ifc-px-4 ifc-pb-none col-sm-12"
    >
      <!-- Removed by BackEnd -->
      <ifc-button
        v-if="1 === 2"
        class="ifc-mb-6 ifc-mr-4"
        appendIcon="ph-download"
        :label="`${$t('button.downloadList')}`"
        outlined
        color="information-200"
        @click="getDownload()"
      />
      <ifc-button
        class="ifc-mb-6 ifc-ml-4"
        appendIcon="add"
        :label="$t('button.newBrands')"
        :to="`/brands/new?channelId=${filters.channelId}`"
        color="information-200"
      />
    </v-col>
  </v-row>
</template>

<script>
import AutoCompleteListChannels from '@/components/Catalog/Autocomplete/AutoCompleteListChannels.vue'
import { Debounce } from '@/utils/Debounce'
import getStoragedChannel from '@/utils/get-storaged-channel'
export default {
  name: 'FilterBrandsList',
  components: {
    'acl-chan': AutoCompleteListChannels
  },
  data() {
    return {
      panel: [0],
      status: [],
      filters: {
        channelId: '',
        search: '',
        active: ''
      },
      filtersChanged: false, // Adi
      debounce: null,
      statusList: [
        {
          label: this.$t('status.active.short'),
          value: 'true'
        },
        {
          label: this.$t('status.inactive.short'),
          value: 'false'
        }
      ]
    }
  },
  watch: {
    filters: {
      handler() {
        this.waitTyping()
        if (this.filtersChanged) {
          this.$emit('filters', this.filters) // Emitir evento somente se os filtros foram alterados
          this.filtersChanged = false // Reiniciar a flag
        }
      },
      deep: true
    }
  },
  mounted() {
    this.filters.channelId = getStoragedChannel()
    this.filtersChanged = true // Indicar que os filtros foram alterados ao serem inicializados
  },
  methods: {
    waitTyping() {
      this.debounce = Debounce.execute(
        this.debounce,
        () => {
          this.filtersChanged = true
        },
        500
      )
    },
    getDownload() {
      this.$emit('download')
    }
  }
}
</script>

<style scoped>
.v-text-field__details {
  display: none !important;
}
.ifc-btn-right {
  text-align: right;
  white-space: nowrap;
}
.ifc-nx-4 {
  margin-left: -4px;
  margin-right: -4px;
}
.ifc-min-select {
  min-width: 155px;
}
@media (max-width: 960px) {
  .ifc-padd-bottom {
    position: relative;
    padding-bottom: 20px !important;
  }
  .ifc-btn-right {
    white-space: normal;
  }
}
</style>
