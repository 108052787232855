<template>
  <ifc-container
    class="categories-registration"
    :breadcrumb="breadcrumbs"
    :title="
      this.id === 'new' ? $t('titles.categoryNew') : $t('titles.categoryEdit')
    "
    returnButton
    :returnLabel="$t('button.back')"
    @onGoBack="getBack"
  >
    <v-row>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-card color="white" class="ifc-mb-24">
          <v-row>
            <v-col cols="12" sm="8">
              <ifc-typography
                fatherCategory
                :text="$t('titles.generalData')"
                typography-style="h4"
                text-weight="700"
              />
            </v-col>
            <v-col cols="12" sm="4" class="ifc-align-right">
              <ifc-switch
                class="w-105 ifc-mt-none"
                v-model="content.active"
                :label="`${$t('status.active.short')}?`"
                inset
                color="success"
              />
            </v-col>
          </v-row>
          <v-row v-if="!content.categoryId">
            <v-col cols="12" sm="12" md="4">
              <auto-complete-list-channels
                v-model="content.channelId"
                :label="$t('label.channel')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-if="id !== 'new'">
              <ifc-input
                :label="$t('label.id')"
                v-model="content.categoryId"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="id !== 'new'">
              <ifc-input
                :label="$t('label.createdAt')"
                v-model="content.createdAtView"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="id !== 'new'">
              <ifc-input
                :label="$t('label.updatedAt')"
                v-model="content.updatedAtView"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <ifc-input
                v-model="content.name"
                :label="`${$t('label.name')}*`"
                :rules="showFieldError ? rules.required : []"
                :hint="equalCategoryNameMessage"
                @blur="chkSameName()"
              />
              <v-checkbox
                :label="$t('label.equalNameTitle')"
                v-model="equalName"
                class="ifc-mt-none"
                color="information-200"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <ifc-input
                :label="`${$t('label.externalIdOf')} ${$t('label.category')}`"
                v-model="content.externalCode"
              />
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <ifc-input
                :label="$t('label.title')"
                v-model="content.title"
                :disabled="equalName"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <category-list
                :label="`${$t('label.categoryFather')}*`"
                v-model="content.fatherCategoryId"
                :channelId="content.channelId"
              />
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <ifc-textarea
                class="ifc-textarea-height"
                :label="`${$t('label.description')}*`"
                v-model="content.description"
                :rules="showFieldError ? rules.required : []"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <keywords
                :label="`${$t('label.keywords')}*`"
                v-model="content.keywords"
              />
            </v-col>
          </v-row>
        </ifc-card>
        <ifc-card color="white">
          <v-row>
            <v-col cols="12">
              <ifc-typography
                :text="$t('titles.seo')"
                typography-style="h4"
                text-weight="700"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="8">
              <v-row>
                <v-col cols="12" md="6">
                  <ifc-input
                    :label="`${$t('label.canonical')}`"
                    v-model="content.seo.canonical"
                    :id="content.categoryId"
                    :context="'categories'"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <keywords
                    :label="`${$t('label.keywords')}*`"
                    v-model="content.seo.metaKeywords"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <ifc-textarea
                    class="ifc-textarea-height"
                    :label="`${$t('label.metaDescription')}*`"
                    v-model="content.seo.metaDescription"
                    :rules="showFieldError ? rules.required : []"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <ifc-seo
                :title="content.name"
                :canonical="content.seo.canonical"
                :description="content.seo.metaDescription"
              />
            </v-col>
          </v-row>
        </ifc-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <small>{{ `(*) ${$t('message.inputRequired')}` }}</small>
      </v-col>
    </v-row>
    <v-row class="ifc-mt-32">
      <v-col cols="12">
        <div class="ifc-max-217">
          <ifc-button
            block
            icon=""
            :label="this.id === 'new' ? $t('button.save') : $t('button.update')"
            color="information-200"
            size="small"
            @click="setCategoryContent()"
          />
        </div>
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import {
  getCategoryData,
  setCreateCategory,
  setUpdateCategory,
  getCategoriesListTree
} from '@/services/categories'
import { showNotifyInfo, showNotifySuccess } from '@/plugins/notify'
import CategoryList from '@/components/Catalog/Autocomplete/AutoCompleteListCategories.vue'
import KeyWords from '@/components/Catalog/Autocomplete/AutoCompleteKeyWords.vue'
import Seo from '@/components/Catalog/Seo'
import AutoCompleteListChannels from '@/components/Catalog/Autocomplete/AutoCompleteListChannels.vue'
import getStoragedChannel from '@/utils/get-storaged-channel'

export default {
  name: 'CategoryRegistration',
  components: {
    AutoCompleteListChannels,
    'category-list': CategoryList,
    keywords: KeyWords,
    'ifc-seo': Seo
  },
  props: ['id'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('titles.homePage'),
          href: '/'
        },
        {
          text: this.$t('titles.catalog'),
          to: `/`,
          disabled: true
        },
        {
          text: this.$t('titles.categoriesList'),
          to: '/categories',
          exact: true
        },
        {
          text:
            this.id === 'new'
              ? this.$t('titles.categoryNew')
              : this.$t('titles.categoryEdit'),
          to: `/categories/${this.id}`,
          exact: true
        }
      ],
      rules: {
        required: [(v) => !!v || this.$t('message.required')]
      },
      loading: false,
      fatherId: 0,
      content: {
        categoryId: null,
        channelId: null,
        externalCode: null,
        name: '',
        keywords: '',
        title: null,
        description: null,
        fatherCategoryId: 0,
        active: true,
        seo: {
          canonical: null,
          metaKeywords: '',
          metaDescription: null
        },
        createdAt: null,
        updatedAt: null
      },
      equalName: false,
      equalCategoryName: false,
      addKeyWords: '',
      addMetaKeyWords: '',
      buttonValidate: true,
      options: {
        itemsPerPage: 10,
        page: 1
      },
      filters: {},
      categoriesList: [],
      showFieldError: false
    }
  },
  computed: {
    equalCategoryNameMessage() {
      return this.equalCategoryName
        ? `Essa categoria ${this.$t('label.exists')}`
        : ''
    },
    isInvalidForm() {
      const { name, seo, fatherId } = this.content || {}

      return (
        fatherId === null ||
        !name?.trim() ||
        !seo?.metaDescription?.trim() ||
        !seo?.metaKeywords
      )
    }
  },
  watch: {
    equalName: {
      handler(e) {
        if (e) {
          this.content.title = this.content.name
        }
      },
      deep: true
    },
    categoriesList: {
      handler(e) {
        if (e) {
          this.equalCategoryName = this.categoriesList.some((cat) => {
            return cat.name === this.content.name
          })
        }
      },
      deep: true
    },
    addKeyWords: {
      handler(k) {
        if (k.indexOf(',') > -1 || k.indexOf(';') > -1) {
          this.addKeyWords =
            k.indexOf(',') > -1 ? k.replaceAll(',', '') : k.replaceAll(';', '')
          this.setKeyWords()
        }
      },
      deep: true
    },
    addMetaKeyWords: {
      handler(k) {
        if (k.indexOf(',') > -1 || k.indexOf(';') > -1) {
          this.addMetaKeyWords =
            k.indexOf(',') > -1 ? k.replaceAll(',', '') : k.replaceAll(';', '')
          this.setKeyWords()
        }
      },
      deep: true
    },
    fatherId: {
      handler(f) {
        if (parseInt(f) < 0) {
          this.fatherId = 0
        }
      },
      deep: true
    },
    content: {
      handler(obj) {
        this.chkChannelId()
        this.content.fatherCategoryId =
          obj.categoryId === obj.fatherCategoryId || !obj.fatherCategoryId
            ? 0
            : obj.fatherCategoryId
        if (this.equalName) {
          this.content.title = this.content.name
        }
      },
      deep: true
    }
  },
  methods: {
    chkChannelId() {
      const getChannelId = () =>
        this.$route.query?.channelId || getStoragedChannel()

      if (!this.content?.channelId) {
        this.content.channelId = getChannelId()
      }
    },
    async getCategoryContent() {
      this.loading = true
      try {
        this.content = await getCategoryData(this.id, this.content.channelId)
        this.content.categoryId = this.content.categoryId.toString()
        this.content.seo = !this.content.seo
          ? {
              canonical: '',
              metaKeywords: '',
              metaDescription: ''
            }
          : this.content.seo
        this.content.createdAtView = this.chkDate(this.content.createdAt)
        this.content.updatedAtView = this.chkDate(this.content.updatedAt)
        this.equalName = this.content.name === this.content.title
        this.content.categId = this.content.categoryId?.toString()
        this.fatherId = this.content.fatherCategoryId?.toString()
      } finally {
        this.loading = false
      }
    },
    async setCategoryContent() {
      if (this.isInvalidForm) {
        this.showFieldError = true
        return
      }

      const params = {
        channelId: this.content.channelId,
        name: this.content.name,
        keywords: this.content.keywords,
        title: this.content.title || this.content.name,
        description: this.content.description,
        fatherCategoryId: parseInt(this.content.fatherCategoryId),
        isActive: this.content.active,
        externalCode: this.content.externalCode,
        seoMetaKeywords: this.content.seo.metaKeywords,
        seoMetaDescription: this.content.seo.metaDescription,
        seoCanonical: this.content.seo.canonical
      }
      try {
        let success

        if (this.id === 'new') {
          success = {
            msg: `${this.$t('message.category')} ${this.content.name} ${this.$t(
              'message.create'
            )}`,
            ok: this.$t('button.ok')
          }

          await setCreateCategory(params)
        } else {
          success = {
            msg: `${this.$t('message.category')} ${this.content.name} ${this.$t(
              'message.update'
            )}`,
            ok: this.$t('button.ok')
          }
          await setUpdateCategory(this.id, params)
        }
        const notify = showNotifySuccess(success)
        this.showNotify(notify)
      } catch (error) {
        const notify = showNotifyInfo({
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        })
        this.showNotify(notify)
      }
    },
    async getListCategories() {
      this.chkChannelId()
      this.loading = true
      try {
        const params = Object.assign(
          {
            filters: this.filters
          },
          this.options
        )
        const resp = await getCategoriesListTree(params)
        if (resp) {
          this.categoriesList = resp
        }
      } finally {
        this.loading = false
      }
    },
    chkDate(date) {
      if (!date) return date
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    chkSameName(name) {
      this.getListCategories()
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    getBack() {
      this.$router.push('/categories?channelId=' + this.content.channelId)
    },

    showNotify(options, info) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'success') {
            this.getBack()
          }
        })
        .catch(() => {})
    }
  },
  mounted() {
    this.chkChannelId()
    if (this.id !== 'new') {
      this.getCategoryContent()
    }
  }
}
</script>
<style scoped>
.ifc-align-right {
  text-align: right;
}
.w-105 {
  width: 105px;
  display: inline-block;
}
.ifc-textarea-height {
  height: 109px;
}
.col-relative {
  position: relative;
}
.ifc-btn-plus {
  position: absolute;
  top: 20px;
  right: 0px;
}
.area-chips {
  position: relative;
  margin-top: -25px;
}
.area-chips .theme--light.v-icon {
  color: rgba(255, 255, 255, 0.54) !important;
}
.ifc-max-217 {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 217px;
}
</style>
