<template>
  <ifc-container
    class="brands-registration"
    :breadcrumb="breadcrumbs"
    :title="
      this.id === 'new' ? $t('titles.brandsNew') : $t('titles.brandsEdit')
    "
    returnButton
    :returnLabel="$t('button.back')"
    @onGoBack="getBack"
  >
    <v-row>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-card color="white" class="ifc-mb-24">
          <v-row>
            <v-col cols="12" sm="8" class="ifc-px-8 ifc-py-none">
              <ifc-typography
                :text="$t('titles.generalData')"
                typography-style="h4"
                text-weight="700"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-row class="switchs-group ifc-pl-8">
                <ifc-tooltip v-bind="$props" :direction="'top'" max-width="280">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <ifc-switch
                        class="ifc-mt-none"
                        v-model="content.isHighlight"
                        :label="`${$t('label.isHighlight')}?`"
                        inset
                        color="success"
                      />
                    </div>
                  </template>
                  <span
                    >Alguns componentes como o carrossel de marcas usam essa
                    flag para selecionar quais itens mostrar</span
                  >
                </ifc-tooltip>
                <ifc-switch
                  class="ifc-mt-none"
                  v-model="content.active"
                  :label="`${$t('status.active.short')}?`"
                  inset
                  color="success"
                />
              </v-row>
            </v-col>

            <v-col cols="12" v-if="!content.brandId">
              <auto-complete-list-channels
                v-model="content.channelId"
                v-if="id === 'new'"
                :label="$t('label.channel')"
              />
            </v-col>
            <v-col
              class="ifc-px-8 ifc-py-none"
              cols="12"
              sm="4"
              v-if="id !== 'new'"
            >
              <ifc-input
                :label="$t('label.id')"
                v-model="content.brandId"
                disabled
              />
            </v-col>

            <v-col
              class="ifc-px-8 ifc-py-none"
              cols="12"
              sm="4"
              v-if="id !== 'new'"
            >
              <ifc-input
                :label="$t('label.createdAt')"
                v-model="content.createdAtView"
                disabled
              />
            </v-col>
            <v-col
              class="ifc-px-8 ifc-py-none"
              cols="12"
              sm="4"
              v-if="id !== 'new'"
            >
              <ifc-input
                :label="$t('label.updatedAt')"
                v-model="content.updatedAtView"
                disabled
              />
            </v-col>
            <v-col class="ifc-px-8 ifc-py-none" cols="12" sm="4">
              <ifc-input
                :label="`${$t('label.externalIdOf')} ${$t('label.brand')}`"
                v-model="content.externalCode"
              />
            </v-col>
            <v-col class="ifc-px-8 ifc-py-none" cols="12" sm="8">
              <ifc-input
                :label="`${$t('label.name')}*`"
                v-model="content.name"
                :rules="rules.required"
              />
            </v-col>
          </v-row>
        </ifc-card>

        <ifc-card color="white" class="ifc-mb-24">
          <v-row>
            <v-col cols="12">
              <ifc-typography
                :text="$t('titles.pageContent')"
                typography-style="h4"
                text-weight="700"
              />
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <v-row>
                <v-col class="ifc-px-8 ifc-py-none" cols="12" md="12">
                  <ifc-input
                    :label="$t('label.title')"
                    v-model="content.metaTitle"
                  />
                </v-col>
                <v-col class="ifc-px-8 ifc-pt-none ifc-pb-24" cols="12" md="12">
                  <rich-text-editor
                    :label="$t('label.description')"
                    v-model="content.description"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col col="12" sm="6" md="4">
              <v-row>
                <v-col class="ifc-px-8 ifc-py-none" cols="12" md="12">
                  <ifc-label>{{ $t('label.brandLogo') }}</ifc-label>
                  <upload-media
                    v-model="content.image"
                    :previewUrl="content.logo"
                    :showRemoveButton="false"
                    :type="'image'"
                    @onUpload="onUploadLogo"
                    :uploadMime="'image/*'"
                    :label="$t('label.image')"
                  />
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col col="12" sm="6" md="4" class="-ifc-mt-8">
           
            </v-col> -->
          </v-row>
        </ifc-card>

        <ifc-card color="white">
          <v-row>
            <v-col cols="12">
              <ifc-typography
                :text="$t('titles.seo')"
                typography-style="h4"
                text-weight="700"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="8">
              <v-row>
                <v-col class="ifc-px-8 ifc-py-none" cols="12" md="6">
                  <ifc-input
                    :label="`${$t('label.canonical')}`"
                    v-model="content.seo.canonical"
                  />
                </v-col>
                <v-col class="ifc-px-8 ifc-py-none" cols="12" md="6">
                  <keywords
                    :label="`${$t('label.keywords')}*`"
                    v-model="content.seo.metaKeywords"
                  />
                </v-col>
                <v-col class="ifc-px-8 ifc-py-none" cols="12" md="12">
                  <ifc-textarea
                    class="ifc-textarea-height"
                    :label="`${$t('label.metaDescription')}*`"
                    v-model="content.seo.metaDescription"
                    :rules="rules.required"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col col="12" sm="6" md="4">
              <ifc-seo
                :title="content.name"
                :canonical="content.seo.canonical"
                :description="content.seo.metaDescription"
              />
            </v-col>
          </v-row>
        </ifc-card>
      </v-col>
    </v-row>
    <v-row class="ifc-mt-32">
      <v-col cols="12">
        <div class="ifc-max-217">
          <ifc-button
            block
            icon=""
            :label="this.id === 'new' ? $t('button.save') : $t('button.update')"
            color="information-200"
            size="small"
            @click="setBrandsContent()"
            :disabled="isInvalidForm"
          />
        </div>
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import {
  getBrandsData,
  setCreateBrands,
  setUpdateBrands
} from '@/services/brands'
import { showNotifyInfo, showNotifySuccess } from '@/plugins/notify'
import KeyWords from '@/components/Catalog/Autocomplete/AutoCompleteKeyWords.vue'
import Seo from '@/components/Catalog/Seo'
import AutoCompleteListChannels from '@/components/Catalog/Autocomplete/AutoCompleteListChannels.vue'
import UploadMedia from '@/components/Catalog/Uploads/UploadMedia.vue'
import RichTextEditor from '@/components/Catalog/Editor/RichTextEditor.vue'

export default {
  name: 'BrandsRegistration',
  components: {
    AutoCompleteListChannels,
    RichTextEditor,
    keywords: KeyWords,
    'upload-media': UploadMedia,
    'ifc-seo': Seo
  },
  props: ['id'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('titles.homePage'),
          href: '/'
        },
        {
          text: this.$t('titles.catalog'),
          to: `/`,
          disabled: true
        },
        {
          text: this.$t('titles.brandsList'),
          to: '/brands',
          exact: true
        },
        {
          text:
            this.id === 'new'
              ? this.$t('titles.brandsNew')
              : this.$t('titles.brandsEdit'),
          href: `/brands/${this.id}`,
          exact: true
        }
      ],
      loading: false,
      content: {
        brandId: null,
        channelId: null,
        catalogSalesDefaultId: null,
        active: true,
        name: '',
        logo: '',
        metaTitle: '',
        description: '',
        isHighlight: false,
        customerServiceNotes: null,
        seo: {
          canonical: null,
          metaKeywords: '',
          metaDescription: null
        },
        createdAt: null,
        updatedAt: null
      },
      addMetaKeyWords: '',
      rules: {
        required: [(v) => !!v || this.$t('message.required')],
        phone: [
          (v) => {
            const val = v ? v.length > 17 : true
            return val || this.$t('validation.phone')
          },
          (v) => !!v || this.$t('message.required')
        ],
        email: [
          (v) => {
            const val = v ? /\S+@\S+\.\S+/.test(v) : true
            return val || this.$t('validation.email')
          },
          (v) => !!v || this.$t('message.required')
        ]
      }
    }
  },
  computed: {
    isInvalidForm() {
      const { name, seo } = this.content || {}

      return (
        !name?.trim() || !seo?.metaDescription?.trim() || !seo?.metaKeywords
      )
    }
  },
  watch: {
    addMetaKeyWords: {
      handler(k) {
        if (k.indexOf(',') > -1 || k.indexOf(';') > -1) {
          this.addMetaKeyWords =
            k.indexOf(',') > -1 ? k.replaceAll(',', '') : k.replaceAll(';', '')
          this.setMetaKeyWords()
        }
      },
      deep: true
    }
  },
  methods: {
    onUploadLogo({ url }) {
      this.content.logo = url
    },
    async getBrandsContent() {
      this.loading = true
      try {
        const content = await getBrandsData(this.id, this.content.channelId)
        this.content = content
        this.content.seo = !this.content.seo
          ? {
              canonical: '',
              metaKeywords: '',
              metaDescription: ''
            }
          : this.content.seo
        this.content.createdAtView = this.chkDate(this.content.createdAt)
        this.content.updatedAtView = this.chkDate(this.content.updatedAt)
      } finally {
        this.loading = false
      }
    },
    async setBrandsContent() {
      let notify = null

      try {
        if (!this.content.channelId && this.$route.query?.channelId) {
          this.content.channelId = this.$route.query?.channelId
        }

        const params = {
          channelId: this.content.channelId,
          isActive: this.content.active,
          name: this.content.name,
          logo: this.content.logo || '',
          externalCode: this.content.externalCode,
          description: this.content.description,
          metaTitle: this.content.metaTitle,
          isHighlight: this.content.isHighlight,
          customerServiceNotes: this.content.customerServiceNotes,
          seoCanonical: this.content.seo.canonical,
          seoMetaKeywords: this.content.seo.metaKeywords,
          seoMetaDescription: this.content.seo.metaDescription
        }

        const successMessage = {
          msg: `${this.$t('message.brands')} ${this.content.name} ${this.$t(
            this.id === 'new' ? 'message.create' : 'message.update'
          )}`,
          ok: this.$t('button.ok')
        }

        if (this.id === 'new') {
          await setCreateBrands(params)
        } else {
          await setUpdateBrands(this.id, params)
        }
        notify = showNotifySuccess(successMessage)
      } catch (error) {
        notify = showNotifyInfo({
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        })
      } finally {
        this.showNotify(notify)
      }
    },
    chkDate(date) {
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    getBack() {
      this.$router.push('/brands?channelId=' + this.content.channelId)
    },

    showNotify(options, info) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'success') {
            this.getBack()
          }
        })
        .catch(() => {})
    }
  },
  mounted() {
    if (this.$route.query?.channelId) {
      this.content.channelId = this.$route.query?.channelId
    }

    if (this.id !== 'new') {
      this.getBrandsContent()
    }
  }
}
</script>
<style scoped>
.ifc-align-right {
  text-align: right;
}
.switchs-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 614px) {
    justify-content: flex-start;
    margin-top: 8px;
  }
}
.col-relative {
  position: relative;
}
.ifc-btn-plus {
  position: absolute;
  top: 20px;
  right: 0px;
}
.area-chips {
  position: relative;
  margin-top: -25px;
}
.area-chips .theme--light.v-icon {
  color: rgba(255, 255, 255, 0.54) !important;
}
.ifc-max-217 {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 217px;
}
.upload-media-col {
  @media (max-width: 614px) {
    margin-top: 20px;
  }
}
</style>
