<template>
  <div class="ifc-browser">
    <div class="ifc-browser-bar">
      <div class="ball red"></div>
      <div class="ball orange"></div>
      <div class="ball green"></div>
    </div>
    <div class="ifc-browser-content">
      <a>
        <span>
          {{ $t('label.yourWebSite')
          }}{{ insertArrow(canonical || '/canonical') }}
        </span>
        <h3>{{ title || $t('label.title') }}</h3>
      </a>
      <p>{{ description || $t('label.description') }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'seo',
  props: {
    title: {
      type: String,
      require: false
    },
    canonical: {
      type: String,
      require: false
    },
    description: {
      type: String,
      require: false
    }
  },
  methods: {
    insertArrow(data) {
      const url = data.indexOf('/') > -1 ? data.replaceAll('/', ' › ') : data
      return url
    }
  }
}
</script>
<style lang="scss" scoped>
.ifc-browser {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 8px;
  border-radius: 3px;
  border: thin solid rgba(0, 0, 0, 0.12);

  .ifc-browser-bar {
    position: relative;
    width: 100%;
    padding: 10px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);

    .ball {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0px 5px;
      border-radius: 50%;

      &.red {
        background-color: red;
      }
      &.orange {
        background-color: orange;
      }
      &.green {
        background-color: green;
      }
    }
  }
  .ifc-browser-content {
    font-family: Arial, Helvetica, sans-serif !important;
    padding: 15px;
    & > a {
      display: block;
      color: #202124 !important;
      font-size: 14px;
      cursor: pointer;

      span {
        display: table;
        line-height: 18.2px;
      }

      h3 {
        display: block;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        margin: 7px 0 3px;
        color: #1a0dab;
      }

      &:hover {
        h3 {
          text-decoration: underline;
        }
      }
    }
    & > p {
      display: block;
      color: #4d5156;
      font-size: 14px;
    }
  }
}
</style>
