<template>
  <ifc-container
    class="product-list"
    :breadcrumb="breadcrumbs"
    :title="$t('titles.attributes')"
    returnButton
    :returnLabel="$t('button.back')"
    @onGoBack="getBack"
  >
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="ifc-pt-4 ifc-px-4 ifc-pb-none"
      >
        <auto-complete-list-channels
          v-model="filters.channelId"
          label="Canal"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="ifc-pt-32 ifc-px-4 ifc-pb-none col-sm-12"
      >
        <ifc-button
          class="ifc-mb-6"
          appendIcon="add"
          :label="$t('button.attributeRegister')"
          :to="`/attributes/new${
            filters.channelId ? `?channelId=${filters.channelId}` : ''
          }`"
          color="information-200"
        />
      </v-col>
    </v-row>
    <v-row class="ifc-mt-none">
      <v-col cols="12" class="ifc-pa-none">
        <ifc-table
          :headers="headers"
          :items="content.data"
          :options.sync="options"
          :loading="loading"
        >
          <tbody v-if="content.data.length > 0">
            <tr v-for="(item, i) in content.data" :key="i">
              <td>{{ item.attributeId }}</td>
              <td>
                <ifc-tooltip
                  v-bind="$props"
                  bottom
                  max-width="600"
                  background-color="information-50"
                  text-color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                      {{ item.name }}
                    </div>
                  </template>
                  <span> {{ item.name }} </span>
                </ifc-tooltip>
              </td>
              <td>
                <ifc-chip
                  :label="$t(`status.active.short`)"
                  background-color="success-50"
                  text-color="success-200"
                  v-if="item.buybox"
                />
                <ifc-chip
                  :label="$t(`status.inactive.short`)"
                  background-color="neutral-300"
                  text-color="neutral-400"
                  v-else
                />
              </td>
              <td>
                <ifc-chip
                  :label="$t(`status.active.short`)"
                  background-color="success-50"
                  text-color="success-200"
                  v-if="item.active"
                />
                <ifc-chip
                  :label="$t(`status.inactive.short`)"
                  background-color="neutral-300"
                  text-color="neutral-400"
                  v-else
                />
              </td>
              <td class="ifc-col-center">
                <ifc-button
                  text
                  :to="`/attributes/${item.attributeId}?channelId=${filters.channelId}`"
                  :label="$t('button.edition')"
                  color="information-200"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <span>
                  {{ $t('table.noDataText') }}
                </span>
              </td>
            </tr>
          </tbody>
        </ifc-table>

        <ifc-pagination
          class="ifc-mt-28"
          :totalPages="paginations.pages"
          :perPageOptions="listPerPage"
          :perPageDefault="paginations.perPage"
          @onChangePage="setOnChangePage"
          @onChangePerPage="setOnChangePerPage"
        />
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import AutoCompleteListChannels from '@/components/Catalog/Autocomplete/AutoCompleteListChannels.vue'
import { getAttributesList } from '@/services/attributes'
import getStoragedChannel from '@/utils/get-storaged-channel'

export default {
  name: 'Attributes',
  components: {
    AutoCompleteListChannels
  },
  props: ['chkStore'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('titles.homePage'),
          href: '/'
        },
        {
          text: this.$t('titles.catalog'),
          to: `/`,
          disabled: true
        },
        {
          text: this.$t('titles.attributesList'),
          href: `/attributes`,
          exact: true
        }
      ],
      headers: [
        {
          text: this.$t('label.id'),
          align: 'start',
          sortable: false,
          value: 'id'
        },
        {
          text: this.$t('label.name'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: this.$t('label.buybox'),
          align: 'start',
          sortable: false
        },
        {
          text: this.$t('label.status'),
          align: 'start',
          sortable: false
        },
        {
          text: '',
          sortable: false,
          value: 'edit'
        }
      ],
      modalUpload: false,
      options: {},
      listPerPage: [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 }
      ],
      content: {
        data: [],
        page: 1
      },
      config: [],
      paginations: [],
      filters: {},
      loading: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getListAttributes()
      },
      deep: true
    },
    filters: {
      handler() {
        this.getListAttributes()
      },
      deep: true
    },
    chkStore(store) {
      if (store) {
        this.getListAttributes()
      }
    }
  },
  methods: {
    chkChannelId() {
      const getChannelId = () =>
        this.$route.query?.channelId || getStoragedChannel()

      if (!this.filters?.channelId) {
        this.filters.channelId = getChannelId()
      }
    },
    async getListAttributes() {
      if (this.loading) return

      this.chkChannelId()

      this.loading = true
      this.content.data = []
      this.content.page = 1

      try {
        const params = Object.assign(
          {
            filters: this.filters
          },
          this.options
        )
        const { data, ...paginations } = await getAttributesList(params)
        this.paginations = paginations
        this.content.data = data
      } finally {
        this.loading = false
      }
    },
    chkDate(date) {
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    setOnChangePage(val) {
      this.options.page = val
    },
    setOnChangePerPage(val) {
      this.options.itemsPerPage = val
    },
    filterTable(filter) {
      this.paginations.page = 1
      this.filters = filter
      this.chkChannelId()
      this.getListAttributes()
    },
    getBack() {
      this.$router.push('/')
    },
    showNotify(options) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'question') {
            this.delCategoryContent(options.id, options.name)
          }
        })
        .catch(() => {})
    }
  },
  mounted() {
    this.getListAttributes()
  }
}
</script>

<style scoped>
.ifc-btn-right {
  text-align: right;
}
.ifc-col-center {
  text-align: center;
}
.cell-ellipsis {
  width: 215px !important;
}
</style>
