<template>
  <ifc-combobox
    hideMenu
    appendIcon="expand_more"
    clearable
    v-model="selected"
    :options="selected"
    multiple
    :label="label"
    deletableChips
    chipTextColor="shade-0"
    chipBackgroundColor="information-200"
    :rules="$validator().hasLength().rules()"
  />
</template>
<script>
export default {
  name: 'AutoCompleteKeyWords',
  props: {
    value: {
      type: String,
      require: false
    },
    label: {
      default: '',
      type: String,
      require: false
    }
  },
  data() {
    return {
      rules: {
        required: (v) => !!v || this.$t('message.keywordsRequired')
      }
    }
  },
  computed: {
    selected: {
      get() {
        return this.getKeyWords(this.value)
      },
      set(s) {
        this.$emit('input', this.setKeyWords(s))
      }
    }
  },
  methods: {
    getKeyWords(d) {
      let data = d
      if (data) {
        data = data.indexOf(', ') > -1 ? data.replaceAll(', ', ',') : data
        data = data.indexOf(',') > -1 ? data.split(',') : data
        data = typeof data === 'string' ? JSON.parse(`["${data}"]`) : data
        data = typeof data === 'object' ? [...new Set(data)] : data
      } else {
        data = []
      }
      return data
    },
    setKeyWords(d) {
      const data = d.join()
      return data
    }
  }
}
</script>
