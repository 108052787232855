import { ApiCtg } from '@/plugins/axios/axios'

const URL = '/categories'

const getControlPagination = (params) => {
  let orderDirection = null
  const { filters, ...restPag } = params
  if (typeof restPag?.sortDesc?.[0] === 'boolean') {
    orderDirection = restPag?.sortDesc?.[0] ? 'desc' : 'asc'
  }
  const formattedPagination = {
    perPage: restPag?.itemsPerPage || null,
    page: restPag?.page || null,
    orderBy: restPag?.sortBy?.[0] || null,
    orderDirection: orderDirection
  }
  for (const filterKey in filters) {
    if (filters[filterKey]) formattedPagination[filterKey] = filters[filterKey]
  }
  return formattedPagination
}

export const getCategoriesList = async (params) => {
  const { data } = await ApiCtg.get(`${URL}`, {
    params: getControlPagination(params)
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const getCategoriesListTree = async (params) => {
  const { data } = await ApiCtg.get(`${URL}/tree`, {
    params: getControlPagination(params)
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const getCategoriesTreeView = async (channelId) => {
  const { data } = await ApiCtg.get(`${URL}/tree`, {
    params: {
      channelId: channelId
    }
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const getCategoriesSimple = async () => {
  const { data } = await ApiCtg.get(`${URL}/getSimpleList`)
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const getCategoryData = async (id, channelId) => {
  const { data } = await ApiCtg.get(`${URL}/${id}`, {
    params: {
      channelId: channelId
    }
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const setDeleteCategory = async (id) => {
  const { data } = await ApiCtg.delete(`${URL}/${id}`)
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const setCreateCategory = async (params) => {
  return await ApiCtg.post(`${URL}`, params)
}

export const setUpdateCategory = async (id, params) => {
  return await ApiCtg.put(`${URL}/${id}`, params)
}
