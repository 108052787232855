<template>
  <ifc-container
    class="attributes-registration"
    :breadcrumb="breadcrumbs"
    :title="
      this.page === 'new'
        ? this.$t('titles.attributesNew')
        : this.$t('titles.attributesEdit')
    "
    returnButton
    :returnLabel="$t('button.back')"
    @onGoBack="getBack"
  >
    <v-row class="ifc-pa-none">
      <v-col cols="12" class="ifc-pa-none">
        <ifc-card color="white" class="ifc-mb-24">
          <v-row>
            <v-col cols="12" class="ifc-pb-none ifc-px-40 ifc-btn-center cla">
              <ifc-tabs
                class="ifc-px-none ifc-pb-none ifc-desktop"
                colorName="information-200"
                v-model="tab"
                :items="tabs"
              />
            </v-col>
            <v-col cols="12" class="ifc-px-40">
              <tab-properties
                :selected="content"
                :page="isNewAttr() ? 'new' : 'edit'"
                v-if="tab === 0"
                @updateProperties="updateProperties"
              />
              <tab-categories-form
                :attrId="id"
                :channelId="content.channelId"
                :selected="content"
                @updateCategories="updateCategories"
                v-if="tab === 1 && !isNewAttr()"
              />
            </v-col>
          </v-row>
        </ifc-card>
        <ifc-card v-if="tab === 0 && !isNewAttr()">
          <tab-values
            :selected="content"
            :page="isNewAttr() ? 'new' : 'edit'"
            @updateValues="updateValues"
          />
        </ifc-card>
        <div v-if="tab === 1 && !isNewAttr()">
          <tab-categories
            :attrId="id"
            :selected="content"
            @updateCategories="updateCategories"
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="ifc-mt-32">
      <v-col cols="12">
        <ifc-typography
          :text="`(*) ${$t('message.inputsRequired')}`"
          typography-style="body-small"
          text-weight="400"
        />
      </v-col>
    </v-row>
    <v-row class="ifc-mt-32">
      <v-col cols="12">
        <div class="ifc-max-217">
          <ifc-button
            v-if="tab === 0"
            block
            icon=""
            :label="isNewAttr() ? $t('button.save') : $t('button.update')"
            color="information-200"
            size="small"
            @click="setAttributesContent()"
            :disabled="!buttonValidate"
          />
        </div>
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import { showNotifyInfo, showNotifySuccess } from '@/plugins/notify'
import getStoragedChannel from '@/utils/get-storaged-channel'
import {
  getAttributesData,
  setCreateAttributes,
  setUpdateAttributes
} from '@/services/attributes'
import tabProperties from '../../components/Catalog/Tabs/Attributes/tabProperties.vue'
import tabValues from '../../components/Catalog/Tabs/Attributes/tabValues.vue'
import tabCategories from '../../components/Catalog/Tabs/Attributes/tabCategories.vue'
import tabCategoriesForm from '../../components/Catalog/Tabs/Attributes/tabCategoriesForm.vue'

export default {
  name: 'AtrributesRegistration',
  components: {
    tabProperties,
    tabValues,
    tabCategories,
    tabCategoriesForm
  },
  props: ['id', 'page'],
  watch: {
    content: {
      handler(c) {
        this.chkCategoryContent()
      },
      deep: true
    }
  },
  data() {
    return {
      tab: 0,
      buttonValidate: false,
      categoriesUpdated: false,
      breadcrumbs: [
        {
          text: this.$t('titles.homePage'),
          href: '/'
        },
        {
          text: this.$t('titles.catalog'),
          to: `/`,
          disabled: true
        },
        {
          text: this.$t('titles.attributesList'),
          to: `/attributes`,
          exact: true
        },
        {
          text:
            this.page === 'new'
              ? this.$t('titles.attributesNew')
              : this.$t('titles.attributesEdit'),
          href:
            this.page === 'new'
              ? `/attributes/new`
              : `/attributes/details/${this.attrId}`,
          exact: true
        }
      ],
      loading: false,
      attrId: null,

      content: {
        channelId: null,
        attributeId: null,
        name: null,
        required: false,
        active: true,
        buybox: false,
        synonyms: [],
        type: null,
        attributeValues: [],
        attributeCategories: [],
        defaultOrder: null
      }
    }
  },
  computed: {
    tabs() {
      const tabs = [
        {
          icon: 'ph-squares-four',
          label: 'Propriedades e Valores'
        },
        {
          icon: 'ph-list',
          label: 'Categorias Vinculadas',
          disabled: this.isNewAttr()
        }
      ]

      return tabs
    }
  },
  mounted() {
    this.chkChannelId()

    if (!this.isNewAttr()) {
      this.attrId = this.id
      this.getAttributesContent()
    }
  },
  methods: {
    chkChannelId() {
      const getChannelId = () =>
        this.$route.query?.channelId || getStoragedChannel()

      if (!this.content?.channelId) {
        this.content.channelId = getChannelId()
      }
    },
    isNewAttr() {
      return this.id === 'new'
    },
    chkCategoryContent() {
      this.chkChannelId()
      if (this.tab === 0) {
        this.buttonValidate = !!this.content?.name && !!this.content?.type
      } else {
        this.buttonValidate = this.categoriesUpdated
      }
    },
    async getAttributesContent() {
      this.loading = true
      try {
        const attrData = await this.getAttributesData()
        this.content = {
          defaultOrder: 'ALPHANUMERIC-DESCENDING',
          ...attrData
        }
      } finally {
        this.loading = false
      }
      this.selected = []
    },
    async updateValues(values) {
      if (typeof values !== 'boolean') {
        this.content.attributeValues = values
      } else {
        this.loading = true
        try {
          const attrData = await this.getAttributesData()
          this.content.attributeValues = attrData.attributeValues
        } finally {
          this.loading = false
        }
      }
    },
    async updateCategories() {
      this.loading = true
      try {
        const attrData = await this.getAttributesData()
        this.content.attributeCategories = attrData.attributeCategories
      } finally {
        this.loading = false
      }
    },
    async getAttributesData() {
      return await getAttributesData(this.attrId, this.content.channelId)
    },
    updateProperties(properties) {
      this.content.channelId = properties.channelId
      this.content.name = properties.name
      this.content.required = properties.required
      this.content.active = properties.active
      this.content.buybox = properties.buybox
      this.content.synonyms = properties.synonyms
      this.content.type = properties.type
      this.content.defaultOrder = properties.defaultOrder
    },
    async setAttributesContent() {
      this.chkChannelId()

      this.enumerateAttributeValues()
      const attr = this.content

      const params = {
        channelId: attr.channelId,
        attribute: {
          channelId: attr.channelId,
          name: attr.name,
          buybox: attr.buybox,
          isFilterable: true,
          isActive: attr.active,
          type: attr.type,
          sortOrderType: attr.defaultOrder
        },
        synonyms: attr.synonyms.map((syn) => {
          return { synonym: syn }
        })
      }

      if (this.isNewAttr()) {
        const success = {
          msg: `${this.$t('message.attribute')} ${attr.name} ${this.$t(
            'message.create'
          )}`,
          ok: this.$t('button.ok')
        }
        const error = {
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        }

        const resp = await setCreateAttributes(params)

        const notify = resp ? showNotifySuccess(success) : showNotifyInfo(error)

        this.showNotify(notify, () => {
          if (resp) {
            this.$router.push(
              `/attributes/${resp.data.attributeId}?channelId=${attr.channelId}`
            )
          }
        })
      } else {
        const success = {
          msg: `${this.$t('message.attribute')} ${attr.name} ${this.$t(
            'message.update'
          )}`,
          ok: this.$t('button.ok')
        }
        const error = {
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        }
        const resp = await setUpdateAttributes(this.attrId, params)
        const notify = resp ? showNotifySuccess(success) : showNotifyInfo(error)
        this.showNotify(notify)
      }
    },
    enumerateAttributeValues() {
      const { attributeValues } = this.content
      const list = [...attributeValues]

      this.content.attributeValues = list.map((item, index) => {
        return {
          ...item,
          sortOrder: index
        }
      })
    },
    getBack() {
      this.$router.push('/attributes?channelId=' + this.content.channelId)
    },
    showNotify(options, cb) {
      this.$notify(options)
        .then(cb)
        .catch(() => {})
    }
  }
}
</script>
<style scoped>
.ifc-max-217 {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 217px;
}

.ifc-btn-center {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
}

.ifc-mobile {
  display: none;
}
.ifc-desktop {
  display: initial;
}
@media screen and (max-width: 960px) {
  .ifc-mobile {
    display: initial;
  }
  .ifc-desktop {
    display: none;
  }
}
</style>
