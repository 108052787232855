import { ApiCtg } from '@/plugins/axios/axios'

const URL = '/skus'
const getControlPagination = (params) => {
  let orderDirection = null
  const { filters = {}, ...restPag } = params
  if (typeof restPag?.sortDesc?.[0] === 'boolean') {
    orderDirection = restPag?.sortDesc?.[0] ? 'desc' : 'asc'
  }
  const formattedPagination = {
    perPage: restPag?.itemsPerPage || null,
    page: restPag?.page || null,
    orderBy: restPag?.sortBy?.[0] || null,
    orderDirection: orderDirection
  }
  for (const filterKey in filters) {
    if (filters[filterKey]) formattedPagination[filterKey] = filters[filterKey]
  }
  return formattedPagination
}

export const getSkutList = async (params) => {
  const paginationParams = getControlPagination(params)
  paginationParams.channelId = params.channelId
  const { data } = await ApiCtg.get(`/managementProducts/getProductsFilters`, {
    params: paginationParams
  })
  return data
}

export const getProductSkuList = async (id, params) => {
  const paginationParams = getControlPagination(params)
  paginationParams.channelId = params.channelId
  const { data } = await ApiCtg.get(`${URL}?productId=${id}`, {
    params: paginationParams
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}
export const getSkuItem = async (id, channelId) => {
  const { data } = await ApiCtg.get(`${URL}/${id}`, {
    params: {
      channelId: channelId
    }
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return {}
    })
  return data
}

export const setCreateSku = async (params) => {
  return await ApiCtg.post(`${URL}`, params)
}

export const setUpdateSku = async (id, params) => {
  return await ApiCtg.put(`${URL}/${id}`, params)
}

export const setDeleteSku = async (id) => {
  const { data } = await ApiCtg.delete(`${URL}/${id}`)
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const uploadMediaSku = async (
  file,
  data,
  channelId,
  progressCallback
) => {
  if (!file) {
    if (progressCallback) {
      progressCallback(0, 'Undefined', 'error-50')
    }
    return
  }

  const fd = new FormData()
  fd.append('sku', data.sku)
  fd.append('label', data.label)
  fd.append('file', file)

  try {
    await ApiCtg.post(`${URL}/image`, fd, {
      onUploadProgress: (uploadEvent) => {
        const count = Math.round((uploadEvent.loaded / uploadEvent.total) * 100)
        if (progressCallback) {
          progressCallback(count, 'created', 'success')
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        channelId: channelId
      }
    })
  } catch (error) {
    if (progressCallback) {
      progressCallback(0, 'error', 'error-200')
    }
    console.error('Erro no upload:', error)
    throw error
  }
}

export const deleteMediaSku = async (arr) => {
  if (arr.data.length > 1) {
    return arr.data.map(
      async (item) =>
        await ApiCtg.delete(`${URL}/medias`, {
          params: {
            skuId: arr.skuId,
            fileId: item,
            channelId: arr.channelId
          }
        })
    )
  }
  return await ApiCtg.delete(`${URL}/medias`, {
    params: {
      skuId: arr.skuId,
      fileId: arr.data[0],
      channelId: arr.channelId
    }
  })
}
