<template>
  <div class="ifc-tab-content">
    <v-row class="d-flex flex-row-reverse">
      <ifc-switch
        class="ifc-switch-inline ifc-mt-none"
        v-model="content.active"
        :label="`${$t('status.active.short')}?`"
        inset
        color="success"
      />
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <category-list
          :label="`${$t('label.category')}*`"
          v-model="category"
          :channelId="channelId"
          @department="getDepartment"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <ifc-input
          :label="`${$t('label.department')}`"
          :value="departmentName"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-pb-none">
        <secondary-category-list
          v-model="content.secondaryCategories"
          :secondary-categories.sync="secondaryCategories"
          :label="`Categoria secundária`"
          :channelId="channelId"
          @input="getSecondaryCategories"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.name')}`"
          v-model="content.name"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.title')"
          v-model="content.title"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.categoryFatherId')}`"
          v-model="content.fatherCategoryId"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.id')"
          v-model="content.categoryId"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.createdAt')"
          v-model="content.createdAtView"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.updatedAt')"
          v-model="content.updatedAtView"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="8" class="ifc-py-none">
        <ifc-textarea
          class="ifc-textarea-height"
          :label="`${$t('label.description')}`"
          v-model="content.description"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-textarea
          class="ifc-textarea-height"
          :label="`${$t('label.keywords')}`"
          v-model="content.keywords"
          disabled
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ifc-typography
          :text="$t('titles.seo')"
          typography-style="h4"
          text-weight="700"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.canonical')"
          v-model="content.seo.canonical"
          disabled
        />
      </v-col>
      <v-col cols="12" md="4" class="ifc-py-none">
        <ifc-textarea
          class="ifc-textarea-height"
          :label="$t('label.metaKeywords')"
          v-model="content.seo.metaKeywords"
          disabled
        />
      </v-col>
      <v-col cols="12" md="4" class="ifc-py-none">
        <ifc-textarea
          class="ifc-textarea-height"
          :label="$t('label.metaDescription')"
          v-model="content.seo.metaDescription"
          disabled
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getCategoryData } from '@/services/categories'
import CategoryList from '@/components/Catalog/Autocomplete/AutoCompleteListCategories.vue'
import SecondaryCategoryList from '@/components/Catalog/Autocomplete/AutoCompleteListSecondaryCategories.vue'
export default {
  name: 'tabCategory',
  components: {
    'category-list': CategoryList,
    'secondary-category-list': SecondaryCategoryList
  },
  props: ['id', 'value', 'secondaryCategories', 'channelId'],
  data() {
    return {
      content: {
        categoryId: 0,
        secondaryCategories: [],
        name: null,
        keywords: '',
        title: null,
        description: null,
        fatherCategoryId: 0,
        active: true,
        seo: {
          canonical: null,
          metaKeywords: '',
          metaDescription: null
        },
        createdAt: null,
        updatedAt: null
      },
      departmentName: null
    }
  },
  watch: {
    category(c) {
      this.getCategoryContent()
      if (!c) this.clearForms()
    }
  },
  computed: {
    category: {
      get() {
        return this.value || 0
      },
      set(s) {
        this.$emit('input', s)
      }
    }
  },
  methods: {
    async getCategoryContent() {
      if (this.category) {
        this.loading = true
        try {
          this.content = await getCategoryData(this.category, this.channelId)
          this.content.createdAtView = this.chkDate(this.content.createdAt)
          this.content.updatedAtView = this.chkDate(this.content.updatedAt)
        } finally {
          this.loading = false
        }
      }
    },
    getDepartment(data) {
      this.departmentName = data.name
      this.$emit('department', data.id)
    },
    getSecondaryCategories(categories) {
      this.$emit('secondaryCategoriesList', [...categories])
    },
    chkDate(date) {
      if (!date) return date
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    clearForms() {
      this.content = {
        categoryId: 0,
        name: null,
        keywords: '',
        title: null,
        description: null,
        fatherCategoryId: 0,
        active: true,
        seo: {
          canonical: null,
          metaKeywords: '',
          metaDescription: null
        },
        createdAt: null,
        updatedAt: null
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.getCategoryContent()
    }, 100)
  }
}
</script>
<style scoped>
.ifc-tab-content {
  position: relative;
  display: block;
  width: 100%;
}
.ifc-switch-inline {
  display: inline-block;
}
.ifc-align-right {
  text-align: right;
}
.ifc-switch-inline {
  display: inline-block;
}
.ifc-separetor {
  border-bottom: 1px solid #c6d3de;
}

::v-deep
  .ifc-select-treeview__container
  .v-input__prepend-inner
  > .text-caption {
  width: 84px !important;
}
</style>
