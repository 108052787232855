<template>
  <div class="ifc-treeview-area">
    <ifc-select-treeview
      v-if="listCategories.length > 0"
      :multiple="true"
      v-model="selected"
      :options="listCategories"
      :label="label"
      :keyLabel="forId ? 'categoryId' : 'name'"
      keyValue="categoryId"
      keyChildren="children"
      selectionType="independent"
      clearable
      open-all
      :disabled="!!disabled"
    />
    <ifc-loader
      v-else
      class="ifc-loader-size"
      indeterminate
      color="information-400"
    />
    <small class="ifc-search-for ifc-color--information-200">
      <span v-if="forId" @click="forId = false">
        {{ `${$t('label.searchFor')} ${$t('label.name')}` }}
      </span>
      <span v-else @click="forId = true">
        {{ `${$t('label.searchFor')} ${$t('label.id')}` }}
      </span>
    </small>
  </div>
</template>
<script>
import { getCategoriesTreeView } from '@/services/categories'

export default {
  name: 'AutoCompleteListSecondaryCategories',
  props: {
    value: {
      require: false
    },
    secondaryCategories: {
      require: false
    },
    label: {
      require: false
    },
    disabled: {
      require: false
    },
    channelId: {
      require: false
    }
  },
  data() {
    return {
      listCategories: [],
      forId: false
    }
  },
  computed: {
    selected: {
      get() {
        return this.secondaryCategories || []
      },
      set(s) {
        this.$emit('input', s?.categoryId || s)
      }
    }
  },
  methods: {
    async getListCategories() {
      try {
        const data = await getCategoriesTreeView(this.channelId)
        const list = {
          categoryId: 0,
          name: this.$t('label.categoryNo')
        }
        data.unshift(list)
        this.listCategories = data
      } catch {
        this.listCategories = []
      }
    }
  },
  mounted() {
    this.getListCategories()
  }
}
</script>
<style scoped>
.ifc-treeview-area {
  position: relative;
  top: -5px;
}
small.ifc-search-for {
  position: absolute;
  display: block;
  bottom: 5px;
  cursor: pointer;
}
.ifc-loader-size {
  width: 45px !important;
  height: 45px !important;
  margin-bottom: 30px;
}
</style>
