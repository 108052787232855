import { ApiMedia } from '@/plugins/axios/axios'

const URL = '/files'

export const saveMedia = async (media) => {
  if (!media) {
    return
  }

  const formData = new FormData()

  formData.append('file', media.file)
  formData.append('canonical', media.canonical)
  formData.append('context', 'content')
  formData.append('contentLanguage', 'pt-br')
  formData.append('cacheControl', 'no-cache')
  formData.append('contentType', media.contentType)

  const { data } = await ApiMedia.post(URL, formData)

  return data?.data || []
}

export const deleteMedia = async (mediaId) => {
  if (!mediaId) {
    return
  }
  await ApiMedia.delete(`${URL}/${mediaId}`)
}
