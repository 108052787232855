import { Bus } from '@/main'
import {
  ApiCtg,
  ApiCtgDown,
  ApiCtgImp,
  ApiFile,
  axios
} from '@/plugins/axios/axios'

const URL = '/imports'

const CancelToken = axios.CancelToken
let cancel

const getControlPagination = (params) => {
  if (!params) return
  let orderDirection = null
  const { filters, ...restPag } = params
  if (typeof restPag?.sortDesc?.[0] === 'boolean') {
    orderDirection = restPag?.sortDesc?.[0] ? 'desc' : 'asc'
  }
  const formattedPagination = {
    perPage: restPag?.itemsPerPage || null,
    page: restPag?.page || null,
    orderBy: restPag?.sortBy?.[0] || null,
    orderDirection: orderDirection,
    model: getLocal(restPag?.originImport) || null
  }
  for (const filterKey in filters) {
    if (filters[filterKey]) formattedPagination[filterKey] = filters[filterKey]
  }
  return formattedPagination
}

const getControlDefault = (params) => {
  if (!params) return
  const { filters } = params
  const formatted = {
    model: 'PRODUCTS-SKUS',
    filter: {}
  }
  for (const filterKey in filters) {
    if (filters[filterKey]) formatted.filter[filterKey] = filters[filterKey]
  }
  return formatted
}

const getAction = (action) => {
  if (action === 2) {
    return 'UPDATE'
  }
  return 'CREATE'
}

const getLocal = (local) => {
  switch (local) {
    case 'brands':
      return 'BRANDS'
    case 'categories':
      return 'CATEGORIES'
    case 'fiscal':
      return 'PRODUCTS-FISCAL'
    case 'products-full':
      return 'PRODUCTS-SKUS-UNIFIED'
    case 'products':
      return 'PRODUCTS'
    case 'images':
      return 'MEDIAS-SHEET'
    case 'medias-zip':
      return 'MEDIAS-ZIP'
    // case 'images':
    //   return 'IMAGES'
    // case 'attributes':
    //   return 'ATTRIBUTES'
    // case 'products':
    //   return 'PRODUCTS'
    // case 'product':
    //   return 'PRODUCTS'
    // case 'sku':
    //   return 'SKUS'
    // case 'wms':
    //   return 'WMS'

    default:
      return ''
  }
}

export const getFilesList = async (value, model) => {
  const params = getControlPagination(value)
  params.channelId = value.channelId
  if (model) {
    params.model = model
  }
  const { data } = await ApiCtgImp.get(`${URL}`, {
    params: params
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const getDetailsList = async (id, params) => {
  const filteredParams = getControlPagination(params)
  filteredParams.channelId = params.channelId
  const { data } = await ApiCtgImp.get(`${URL}/${id}`, {
    params: filteredParams
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const postFileUpload = async (
  file,
  local,
  action,
  channelId,
  propagate
) => {
  const location = getLocal(local)

  const URL = `/imports`

  if (file) {
    const msg = {
      label: '',
      color: '',
      process: 0
    }

    const propagation = propagate

    const fd = new FormData()
    fd.append('file', file)
    fd.append('model', location)
    fd.append('propagation', propagation)
    fd.append('action', getAction(action))

    await ApiCtgImp.post(URL, fd, {
      onUploadProgress: (uploadEvent) => {
        const count = `${Math.round(
          (uploadEvent.loaded / uploadEvent.total) * 100
        )}`
        msg.process = count
        Bus.uploadCounterEmitter(count)
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        channelId: channelId
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c
      })
    })
      .then((resp) => {
        msg.label = resp.data.OK
      })
      .catch((err) => {
        msg.error = err
      })
    return msg
  }
}
export const postFileDownload = async (id, status) => {
  const URL = status ? `/download/${id}?status=${status}` : `/download/${id}`
  const resp = await ApiCtgDown.get(URL)
  return resp
}

export const postListDownload = async (local, params, id) => {
  const location = getLocal(local)
  let getId = ''
  if (local === 'sku') getId = id ? `/?productId=${id}` : ''
  const URL = `/${location}/export${getId}`
  const formattedParams = getControlPagination(params)
  formattedParams.channelId = params?.filters?.channelId || null
  const resp = await ApiCtg.get(URL, {
    params: formattedParams
  })
  return resp
}

export const postListExport = async (params) => {
  const URL = `reports`
  const paramsFormatted = getControlDefault(params)
  const response = await ApiFile.post(URL, paramsFormatted)
  return response
}

export const getSpreadSheetTemplate = async (model) => {
  if (!model) return

  const allowedModels = [
    'BRANDS',
    'CATEGORIES',
    'PRODUCTS-SKUS-UNIFIED',
    'MEDIAS-SHEET',
    'MEDIAS-ZIP',
    'PRODUCTS-FISCAL'
  ]

  if (!allowedModels.includes(model)) {
    throw new Error(
      'Model not allowed, accepted models: ' + allowedModels.join(', ')
    )
  }

  const URL = `/templates/${model}`
  const resp = await ApiCtgDown.get(URL)
  return resp
}

export const cancelUploadRequest = () => {
  cancel?.()
}
