import { ApiCtg } from '@/plugins/axios/axios'

const URL = '/canonical'

export const getValidCanonical = async (params) => {
  const { data } = await ApiCtg.get(`${URL}`, {
    params
  })
  return data
}
