<template>
  <div class="upload-send">
    <div class="loader-wrapper" v-if="loading">
      <div class="loader-content">
        <ifc-loader
          class="ifc-loader-size"
          indeterminate
          color="information-200"
        />
      </div>
    </div>
    <ifc-upload
      title=" "
      :accept="uploadMime || defaultMime"
      :showTitle="true"
      :rulesLabel="`${$t('label.medias')} `"
      @onError="errorHasEmitted"
      @onUpload="getListUpload"
    >
      <template slot="drag">
        <div class="drag-msg">
          <p class="ifc-pa-4">
            <ifc-upload-icon icon="ph-upload" color="information-100" />
          </p>
          <p class="ifc-pa-4">
            <ifc-typography
              :text="$t('message.uploadMedias')"
              typography-style="caption-small"
              text-weight="400"
              style="text-align: center"
            />
          </p>
        </div>
      </template>
    </ifc-upload>
    <ifc-typography
      typography-style="caption-small"
      text-weight="400"
      style="text-align: left"
    >
      <span class="ifc-color--neutral-400">{{ typeLabel }}</span>
    </ifc-typography>

    <div
      class="ifc-list-file ifc-py-12"
      v-if="(fileUp.status && fileUp.size <= maxFileSize) || currentPreview"
    >
      <div class="catalog-spradsheets-upload-v2">
        <div class="catalog-spradsheets-upload-v2-content">
          <template v-if="currentPreview">
            <template v-if="isImage(fileUp.ext) || type === 'image'">
              <img :src="currentPreview" alt="Preview" class="image-preview" />
            </template>
            <template v-else-if="isVideo(fileUp.ext) || type === 'video'">
              <video
                :src="currentPreview"
                controls
                class="video-preview"
              ></video>
            </template>
          </template>
          <template v-else>
            <template v-if="isFile(fileUp.ext)">
              <ifc-upload-icon
                size="md"
                :extension="fileUp.ext"
                :color="fileUp.color"
              />
              <ifc-typography
                :text="fileUp.label"
                typography-style="caption-small"
                text-weight="400"
                style="text-align: left"
              />
            </template>
          </template>
        </div>
        <button v-if="showRemoveButton" type="button" @click="removeItem">
          <ifc-icon
            name="ph-trash"
            type="filled"
            class="ifc-pl-4"
            iconColor="error-400"
            iconSize="24"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { saveMedia } from '@/services/media'
import { showNotifyInfo } from '@/plugins/notify'

export default {
  name: 'UploadMedia',
  props: {
    type: {
      type: String,
      required: true
    },
    uploadMime: {
      type: String,
      default: 'image/*'
    },
    showRemoveButton: {
      type: Boolean,
      default: true
    },
    previewUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentPreview: '',
      loading: false,
      fileUp: {
        status: false,
        size: 0,
        label: '',
        ext: '',
        color: '',
        item: null
      },
      defaultMime: 'image/*',
      maxFileSize: 10485760
    }
  },
  watch: {
    previewUrl: {
      immediate: true,
      handler(newVal) {
        this.currentPreview = newVal
      }
    }
  },
  computed: {
    typeLabel() {
      const typeMapping = {
        video: 'Vídeo',
        image: 'Imagem',
        file: 'Arquivo'
      }
      return `${this.$t('label.uploadMedias')} ${
        typeMapping[this.type || 'file']
      }.`
    }
  },
  methods: {
    showNotify(options, info) {
      this.$notify(options)
        .then(() => {})
        .catch(() => {})
    },
    errorHasEmitted(error) {
      console.error(error)
    },
    async getListUpload(files) {
      const file = files[0]
      const ext = file.name.split('.').pop().toLowerCase()

      this.fileUp = {
        status: true,
        size: file.size,
        label: file.name,
        ext,
        color: 'information-100',
        item: file
      }

      if (this.isImage(ext) || this.isVideo(ext)) {
        try {
          this.loading = true
          const { uri } = await saveMedia({
            file: file,
            label: file.name,
            contentType: file.type
          })
          this.currentPreview = uri || ''
          this.$emit('onUpload', {
            url: this.currentPreview,
            file: this.fileUp.item
          })
        } catch (error) {
          const notify = showNotifyInfo({
            msg: `${this.$t('message.errorRequest')}`,
            ok: this.$t('button.ok')
          })
          this.showNotify(notify)
        } finally {
          this.loading = false
        }
      } else {
        this.currentPreview = ''
        this.$emit('onUpload', {
          url: '',
          file: this.fileUp.item
        })
      }
    },
    removeItem() {
      this.fileUp = {
        status: false,
        size: 0,
        label: '',
        ext: '',
        color: '',
        item: null
      }
      this.currentPreview = ''
    },
    isImage(ext) {
      return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(ext)
    },
    isVideo(ext) {
      return ['mp4', 'webm', 'ogg'].includes(ext)
    },
    isFile(ext) {
      return !this.isImage(ext) && !this.isVideo(ext)
    }
  }
}
</script>

<style scoped>
.upload-send {
  position: relative;
}
.loader-wrapper {
  position: absolute;
  background-color: #fff;
  opacity: 0.75;
  z-index: 10;
  margin: 36px 0 8px;
  inset: 0;
}
.loader-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.ifc-loader-size {
  width: 45px !important;
  height: 45px !important;
}
.drag-msg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ifc-list-file {
  position: relative;
}
.ifc-icon-position {
  position: absolute;
  display: block;
  top: 10px;
  right: 5px;
  cursor: pointer;
}
.video-preview {
  width: auto;
  height: 100%;
}
.image-preview {
  width: 100%;
  height: 100%;
  max-width: 96px;
  aspect-ratio: 1/1;
  border-radius: 4px;
  object-fit: contain;
}
</style>

<style>
div[primary-title].v-card__title {
  display: none !important;
}
.v-card__actions {
  padding: 0 !important;
}
.catalog-spradsheets-upload-v2 {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.catalog-spradsheets-upload-v2-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.catalog-spradsheets-upload .ifc-upload-progress__title {
  margin-bottom: 6px;
  width: 200px !important;
  word-break: break-all !important;
}

.catalog-spradsheets-upload .ifc-icon-position {
  position: absolute;
  display: block;
  top: 15px;
  right: 5px;
  cursor: pointer;
}

.catalog-spradsheets-upload .text-black > .ifc-upload-icon {
  color: #fff !important;
}
</style>
