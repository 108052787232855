<template>
  <div>
    <v-row>
      <v-col cols="12" class="align-self-center ifc-pb-none">
        <ifc-typography
          class="ifc-title-inline"
          typography-style="h4"
          text-weight="700"
        >
          <div>{{ $t('titles.attributesValue') }}*</div>
          <div>
            <ifc-button
              v-if="page !== 'new'"
              icon="ph-trash"
              :label="$t('button.attributeRegister')"
              color="error-200"
              @click="deleteAttributeValues()"
              :disabled="!selectedToDel?.length"
            />
          </div>
        </ifc-typography>
      </v-col>
      <v-col>
        <ifc-table class="ifc-table-insert" :loading="loading">
          <template v-slot:default>
            <tbody>
              <tr>
                <td vertical-align="top">
                  <ifc-input
                    :placeholder="`${$t('label.value')}`"
                    v-model="newVal.attributeValue"
                    :error-messages="createMessageError"
                    hide-details
                  />
                </td>
                <td vertical-align="top">
                  <ifc-input
                    :placeholder="`${$t('label.content')}`"
                    v-model="newVal.attributeContent"
                    hide-details
                  />
                </td>
                <td vertical-align="top" class="ifc-table-button">
                  <ifc-button
                    icon="ph-plus"
                    size="extra-small"
                    @click="addValue()"
                  />
                </td>
                <td vertical-align="top" class="ifc-table-button">
                  <ifc-button
                    icon="ph-x"
                    size="extra-small"
                    @click="cancel('new')"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </ifc-table>
        <ifc-table
          :headers="headers"
          :class="`ifc-table-values ${
            page === 'new' ? 'ifc-header-remove-one' : ''
          }`"
          :loading="loading"
        >
          <template v-slot:default>
            <draggable
              tag="tbody"
              :list="listValues"
              :disabled="tmpEditValue.attributeContent !== null"
              @end="draggrableEnd"
            >
              <tr v-for="(item, i) in listValues" :key="i">
                <td class="text-center">
                  <v-checkbox
                    v-if="page !== 'new'"
                    size="small"
                    v-model="selectedToDel"
                    :value="item.attributeValueId || i"
                    :disabled="editValueI === i"
                    hide-details
                  />
                  <span v-else>{{ i + 1 }}</span>
                </td>
                <td class="text-center">
                  <ifc-icon
                    name="ph-dots-six-vertical"
                    class="ifc-mouse-move ifc-mt-8"
                  />
                </td>

                <td class="text-center">
                  {{ item.sortOrder }}
                </td>

                <td v-if="editValueI === i">
                  <div class="ifc-mt-20">
                    <ifc-input
                      :label="`${$t('label.value')}`"
                      v-model="tmpEditValue.attributeValue"
                      :error-messages="editMessageError"
                    />
                  </div>
                </td>
                <td v-else>
                  {{ item.attributeValue }}
                </td>

                <td v-if="editValueI === i">
                  <div class="ifc-mt-20">
                    <ifc-input
                      :label="`${$t('label.content')}`"
                      v-model="tmpEditValue.attributeContent"
                    />
                  </div>
                </td>
                <td v-else>
                  {{ item.attributeContent }}
                </td>

                <td class="ifc-table-button">
                  <ifc-button
                    icon="ph-check"
                    @click="saveValue(i)"
                    size="extra-small"
                    v-if="editValueI === i"
                  />
                  <ifc-button
                    icon="ph-pencil-simple"
                    @click="editValue(i, item)"
                    size="extra-small"
                    v-else
                  />
                </td>
                <td class="ifc-table-button">
                  <ifc-button
                    icon="ph-x"
                    size="extra-small"
                    v-if="editValueI === i"
                    @click="cancel('edit')"
                  />
                  <ifc-button
                    icon="ph-trash-simple"
                    @click="deleteAttributeValue(i)"
                    size="extra-small"
                    v-else
                  />
                </td>
              </tr>
            </draggable>
          </template>
        </ifc-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { showNotifyInfo, showNotifySuccess } from '@/plugins/notify'
import draggable from 'vuedraggable'
import {
  setCreateAttributeValue,
  setUpdateAttributeValue,
  deleteAttributeValue
} from '@/services/attributes'
import { validateErrorResponse } from '@/utils/validate-error-response'

/* components */

export default {
  components: {
    draggable
  },
  name: 'tabValues',
  props: ['selected', 'page'],
  data() {
    return {
      attrId: null,
      channelId: null,
      values: [],

      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: '',
          width: '50px'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: '',
          width: '50px'
        },
        {
          text: this.$t('label.ordenation'),
          align: 'start',
          sortable: false,
          value: '',
          width: '50px'
        },
        {
          text: this.$t('label.value'),
          align: 'start',
          sortable: false,
          value: 'attributeValue'
        },
        {
          text: this.$t('label.content'),
          align: 'start',
          sortable: false,
          value: 'attributeContent'
        },
        {
          text: '',
          align: 'start',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          sortable: false
        }
      ],
      rules: {
        required: [(v) => !!v || this.$t('message.required')]
      },

      editValueI: null,
      editMessageError: null,
      tmpEditValue: {
        attributeContent: null,
        attributeValue: null,
        attributeValueId: null
      },

      createMessageError: null,
      buttonValidate: false,

      newVal: {
        attributeContent: null,
        attributeValue: null,
        attributeValueId: null
      },

      loading: false,
      selectedToDel: []
    }
  },
  computed: {
    listValues() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.values.sort((a, b) => a.sortOrder - b.sortOrder)
    }
  },
  watch: {
    selectedToDel: {
      handler(s) {
        if (s === null) this.selectedToDel = []
      }
    },
    selected: {
      handler(s) {
        if (typeof s !== 'object' || !s) {
          const error = {
            msg: `${this.$t('message.errorRequest')}`,
            ok: this.$t('button.ok')
          }
          const notify = showNotifyInfo(error)
          this.showNotify(notify)
          this.values = []
        } else if (this.page !== 'new') {
          this.attrId = this.selected.attributeId
          this.values = this.selected.attributeValues
        }
      },
      deep: true
    }
  },
  mounted() {
    this.channelId = this.selected.channelId
    if (this.page !== 'new') {
      this.attrId = this.selected.attributeId
      this.values = this.selected.attributeValues
    }
  },
  methods: {
    draggrableEnd(action) {
      this.values.map((val, i) => {
        this.saveValue(val, false, i + 1)
      })
    },
    async saveValue(i, edit = true, order = null) {
      let newVal

      if (typeof i === 'object') {
        newVal = i
        i = order
      } else {
        newVal = edit ? this.tmpEditValue : this.values[i]
      }

      if (!this.validateValue(newVal)) {
        return
      }

      if (this.page === 'new') {
        this.values[i] = newVal
        this.$emit('updateValues', this.values)
        this.cancel('edit')
        return
      }

      const saveValue = await setUpdateAttributeValue(newVal.attributeValueId, {
        channelId: this.channelId,
        attributeValue: {
          value: newVal.attributeValue,
          content: newVal.attributeContent,
          sortOrder: i
        },
        synonyms: []
      })

      let notify = null
      if (saveValue) {
        const success = {
          msg: `${this.$t('message.attributeValue')} ${
            newVal.attributeValue
          } ${this.$t('message.update')}`,
          ok: this.$t('button.ok')
        }
        notify = showNotifySuccess(success)
      } else {
        const error = {
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        }
        notify = showNotifyInfo(error)
      }

      this.showNotify(notify, () => {
        this.values[i] = newVal
        this.$emit('updateValues', true)
        this.cancel('edit')
      })
    },
    validateValue(newVal) {
      const chkVal =
        this.values.length > 0 &&
        this.values.find((val) => {
          return (
            val.attributeValue.toLowerCase() ===
              newVal.attributeValue.trim().toLowerCase() &&
            (!newVal.attributeValueId ||
              val.attributeValueId !== newVal.attributeValueId)
          )
        })

      if (chkVal) {
        const notify = showNotifyInfo({
          msg: `${this.$t('message.attributeValue')} ${this.$t(
            'message.exists'
          )}`,
          ok: this.$t('button.ok')
        })
        this.showNotify(notify, () =>
          this.cancel(newVal.attributeId ? 'new' : 'edit')
        )
        return false
      }

      return true
    },
    editValue(i, value) {
      this.editValueI = i
      this.tmpEditValue = value
    },
    async addValue() {
      const newVal = this.newVal
      if (!this.validateValue(newVal)) {
        return
      }

      if (this.page === 'new') {
        this.values.push(newVal)
        this.$emit('updateValues', this.values)
        this.cancel('new')
      } else {
        const params = {
          channelId: this.channelId,
          attributeId: this.attrId,
          attributeValue: {
            value: newVal.attributeValue,
            content: newVal.attributeContent,
            sortOrder: this.values.length + 1
          },
          synonyms: []
        }

        let notify = null

        try {
          const res = await setCreateAttributeValue(params)
          notify = showNotifySuccess({
            msg: `${this.$t('message.attributeValue')} ${
              newVal.attributeValue
            } ${this.$t('message.create')}`,
            ok: this.$t('button.ok')
          })

          if (res?.data) {
            const { attributeValueId } = res.data
            const existsThisVal = this.selectedToDel?.findIndex(
              (val) => val === attributeValueId
            )
            if (existsThisVal === -1) {
              this.selectedToDel.push(attributeValueId)
            }
          }
        } catch {
          notify = showNotifyInfo({
            msg: `${this.$t('message.errorRequest')}`,
            ok: this.$t('button.ok')
          })
        }

        this.showNotify(notify, () => {
          this.values.push(newVal)
          this.$emit('updateValues', true)
          this.cancel('new')
        })
      }
    },
    async deleteAttributeValues() {
      if (!this.selectedToDel || !this.selectedToDel.length) {
        return
      }

      this.selectedToDel.map(async (value) => {
        await this.deleteAttributeValue(value, false)
      })

      this.selectedToDel = []
    },
    async deleteAttributeValue(i, isIndex = true) {
      this.loading = true
      let selectedValue = null

      if (!isIndex) {
        selectedValue = this.listValues.find(
          (val) => val.attributeValueId === i
        )
      } else {
        selectedValue = this.listValues[i]
      }

      try {
        // Faz a requisição para deletar o valor do atributo
        await deleteAttributeValue(
          selectedValue.attributeValueId,
          this.channelId
        )

        // Notifica o sucesso
        const notify = showNotifySuccess({
          msg: `${this.$t('message.attributeValue')} ${
            selectedValue.attributeValue
          } ${this.$t('message.delete')}`,
          ok: this.$t('button.ok')
        })

        // Atualiza a lista e emite o evento após a notificação de sucesso
        this.showNotify(notify, () => {
          delete this.listValues[i]
          this.$emit('updateValues', true)
        })
      } catch (error) {
        let message = `${this.$t('message.errorRequest')}`

        const status = error?.response?.status

        // Verifica se o erro é de conflito
        if (status === 409) {
          const errorMessage = validateErrorResponse(error)

          if (typeof errorMessage === 'string') {
            message = errorMessage
          } else {
            const { context } = errorMessage || {}

            if (context) {
              message = `Este atributo está em uso pelos seguintes ${context} portanto não é permitido deletar`
            }
          }
        }

        // Notifica em caso de erro
        const notify = showNotifyInfo({
          msg: message,
          ok: this.$t('button.ok')
        })

        this.showNotify(notify)
      } finally {
        // Sempre define loading como false, independentemente do sucesso ou erro
        this.loading = false
      }
    },
    cancel(type) {
      const cleanObj = {
        attributeContent: null,
        attributeValue: null,
        attributeValueId: null
      }

      if (type === 'new') {
        this.newVal = cleanObj
      } else {
        this.editValueI = null
        this.tmpEditValue = cleanObj
      }
    },
    showNotify(options, cb) {
      this.$notify(options)
        .then(cb())
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.ifc-title-inline > div {
  position: relative;
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
}
.ifc-title-inline > div:last-child {
  text-align: right;
}
.ifc-mouse-move {
  cursor: move;
}
.ifc-mouse-move:active {
  cursor: grabbing;
}
.ifc-table-button {
  width: 60px;
  text-align: center;
}
.ifc-table-insert .v-input .v-input__control > .v-messages.theme--light,
.ifc-table-insert .v-input .v-input__control > .v-text-field__details {
  display: none !important;
}
.ifc-table-insert .ifc-input-nomessage {
  position: relative;
  overflow-y: hidden;
  width: 100%;
  display: block;
  height: 60px;
}
.ifc-table-values table {
  table-layout: fixed;
}
.ifc-table-values td {
  white-space: normal;
  padding: 10px !important;
}
.ifc-table-values .v-input--selection-controls.v-input {
  margin: 0 !important;
}
.v-btn--disabled {
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}
.v-btn--disabled .ifc-icon i {
  color: #999 !important;
}
</style>
