<template>
  <div class="ifc-tab-content">
    <v-row>
      <v-col cols="12" class="ifc-py-none ifc-align-right">
        <ifc-button
          class="ifc-mb-6"
          prependIcon="add"
          :label="$t('button.newAttributeValue')"
          @click="modalOpen()"
          color="information-200"
        />
      </v-col>
      <v-col cols="12" class="ifc-py-8">
        <ifc-table :headers="headers" :items="attributesList">
          <tbody default="{ items }" v-if="attributesList.length > 0">
            <tr v-for="(item, i) in attributesList" :key="i">
              <td width="50%" v-if="editAttrValues === i">
                <ifc-combobox
                  class="no-message"
                  appendIcon="expand_more"
                  keyValue="attributeId"
                  keyLabel="name"
                  v-model="nameSelect"
                  :options="attributeOptionsName"
                  size="small"
                  color="primary"
                  :hideMoreOptions="false"
                  :deletableChips="false"
                  :multiple="false"
                  :placeholder="$t('label.name')"
                  returnObject
                  :clearable="false"
                />
              </td>
              <td width="50%" v-else>{{ item.name }}</td>
              <td width="50%" v-if="editAttrValues === i">
                <ifc-combobox
                  class="no-message"
                  appendIcon="expand_more"
                  keyValue="attributeValue"
                  keyLabel="attributeValue"
                  v-model="valueSelect"
                  :options="attributeOptionsValue"
                  size="small"
                  color="primary"
                  :hideMoreOptions="false"
                  :deletableChips="false"
                  :multiple="false"
                  :placeholder="$t('label.value')"
                  returnObject
                  :clearable="false"
                  ref="attributeValueComboBox"
                  @click="getScrollPosition"
                  @input.native="debouncedSetFilterString"
                />
              </td>
              <td width="50%" v-else>
                {{ item.attributeValue ? item.attributeValue : '-' }}
              </td>
              <td
                width="60"
                class="ifc-align-center"
                v-if="editAttrValues === i"
              >
                <ifc-button
                  icon="ph-check"
                  size="extra-small"
                  @click="setEditAttrValue(i, item)"
                />
              </td>
              <td class="ifc-align-center" width="60" v-else>
                <ifc-button
                  icon="ph-pencil-simple"
                  @click="editAttrValuesOpen(i, item)"
                  size="extra-small"
                />
              </td>
              <td
                width="60"
                class="ifc-align-center"
                v-if="editAttrValues === i"
              >
                <ifc-button
                  icon="ph-x"
                  @click="editAttrValuesClose()"
                  size="extra-small"
                />
              </td>
              <td class="ifc-align-center" width="60" v-else>
                <ifc-button
                  icon="ph-trash"
                  @click="delAttribute(item)"
                  size="extra-small"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">
                <span>
                  {{ $t('table.noDataText') }}
                </span>
              </td>
            </tr>
          </tbody>
        </ifc-table>
        <add-attr-values
          :options="attributesOptions"
          :status="attrValueStatus"
          :attributes-selected="attributesList"
          local="product"
          @save="setAttributeValue"
          :channelId="channelId"
        />
      </v-col>
      <v-col cols="12" class="ifc-py-none">
        <ifc-typography typography-style="body-small" text-weight="500">{{
          listAttrRequired.length > 0
            ? `(*) ${$t('message.attributeRequired')} : ${listAttrRequired}`
            : $t('message.attributeNotRequired')
        }}</ifc-typography>
      </v-col>
    </v-row>
    <create-attr-value
      persistent
      v-model="modalAttributes"
      :title="$t('titles.attributeValueNew')"
      :options="attributesOptions"
      :data="attributesSelected"
      :channelId="channelId"
      local="product"
      @blur="modalClose"
      @onClose="modalClose"
      @save="saveAttrValue"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import CreateAttributes from '@/components/Catalog/Tabs/Products/Sku/CreateAttrValue.vue'
import AddAttrValues from '@/components/Catalog/Tabs/Products/Sku/AddAttributesValue.vue'
import { showNotifyInfo, showNotifyDelete } from '@/plugins/notify'
import { getAttributeValue, getAttributeValues } from '@/services/attributes'
import Vue from 'vue'
export default {
  name: 'tabAttributes',
  components: {
    'create-attr-value': CreateAttributes,
    'add-attr-values': AddAttrValues
  },
  props: ['value', 'options', 'channelId'],
  data() {
    return {
      debouncedSetFilterString: _.debounce(this.setFilterString, 1500),
      headers: [
        {
          text: this.$t('label.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('label.value'),
          align: 'start',
          sortable: true,
          value: 'attributeValue'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'edit'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'delete'
        }
      ],
      // attributesOptions: [],
      attributesOptionsValue: [],
      attributesSelected: [],
      attributeSelection: {},
      attrValueStatus: null,
      editAttrValues: null,
      nameSelect: {},
      valueSelect: {},
      modalAttributes: false,
      getValidation: false,
      listAttrRequired: [],
      loading: false
    }
  },
  watch: {
    // options: {
    //   handler(opt) {
    //     this.attributesOptions = opt
    //   },
    //   deep: true
    // },
    getValidation: {
      handler(v) {
        this.$emit('valAttr', v)
      },
      deep: true
    },
    attributesOptions: {
      handler() {
        this.listAttrRequired = this.getAttributesRequeired()
        this.chkAttrContent()
      },
      deep: true
    },
    attributeSelection: {
      handler(s) {
        this.getOptionsValue(s)
      },
      deep: true
    },
    nameSelect: {
      handler(n) {
        this.errCreateMsg = null
        this.attributeSelection = {
          attributeId: n.attributeId,
          name: n.name
        }
      },
      deep: true
    },
    valueSelect: {
      handler(v) {
        this.errCreateMsg = null
        this.attributeSelection.attributeValueId = v.attributeValueId
        this.attributeSelection.attributeValue = v.attributeValue
      },
      deep: true
    }
  },
  computed: {
    attributesList: {
      get() {
        return this.value
      },
      set(s) {
        this.chkAttrContent()
        this.$emit('input', s)
      }
    },
    attributesOptions: {
      get() {
        return this.options
      }
    },
    attributeOptionsName() {
      const opt = []
      this.attributesOptions.forEach((obj) => {
        const arr = {
          name: obj.attribute.name,
          attributeId: obj.attribute.attributeId
        }
        opt.push(arr)
      })
      return opt
    }
  },
  methods: {
    setFilterString(e) {
      const { value } = e.target
      this.getValue(value)
    },
    getScrollPosition() {
      const dropdown =
        this.$refs.attributeValueComboBox[0]?.$refs['v-combobox'].$refs.menu
          ?.$children[0]?.$el
      if (dropdown) {
        dropdown.addEventListener('scroll', this.handleScroll)
      }
    },
    handleScroll(event) {
      const scrollHeight = event.target.scrollHeight - event.target.clientHeight
      const scrollPosition = event.target.scrollTop

      if (scrollPosition === scrollHeight) {
        this.getValues()
      }
    },
    merge(arr1, arr2) {
      const newArr = arr1.concat(arr2)
      return newArr.filter((item, index) => newArr.indexOf(item) === index)
    },
    async getValue(value) {
      const elementPos = this.options
        .map((item) => item.attribute.attributeId)
        .indexOf(this.attributeSelection.attributeId)
      const res = await getAttributeValue(
        this.attributeSelection.attributeId,
        this.channelId,
        value
      )
      if (res.data.length > 0) {
        const newData = this.merge(
          res.data,
          this.options[elementPos].attribute.attributeValues.data
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'data',
          newData
        )
        this.getOptionsValue({
          attributeId: this.options[elementPos].attribute.attributeId,
          attributeValue:
            this.options[elementPos].attribute.attributeValues.value,
          attributeValueId:
            this.options[elementPos].attribute.attributeValues.attributeValueId,
          name: this.options[elementPos].attribute.name
        })
        this.$forceUpdate()
      }
    },
    async getValues() {
      const elementPos = this.options
        .map((item) => item.attribute.attributeId)
        .indexOf(this.attributeSelection.attributeId)
      const currentPage =
        this.options[elementPos].attribute.attributeValues.currentPage
      const pages = this.options[elementPos].attribute.attributeValues.pages
      if (currentPage < pages) {
        const res = await getAttributeValues(
          this.attributeSelection.attributeId,
          this.channelId,
          this.options[elementPos].attribute.attributeValues.nextPage
        )
        const newData = this.merge(
          this.options[elementPos].attribute.attributeValues.data,
          res.data
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'currentPage',
          res.currentPage
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'nextPage',
          res.nextPage
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'prevPage',
          res.prevPage
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'data',
          newData
        )
        this.getOptionsValue({
          attributeId: this.options[elementPos].attribute.attributeId,
          attributeValue:
            this.options[elementPos].attribute.attributeValues.value,
          attributeValueId:
            this.options[elementPos].attribute.attributeValues.attributeValueId,
          name: this.options[elementPos].attribute.name
        })

        this.$forceUpdate()
      }
    },
    setAttributeValue(val) {
      const item = val
      const data = this.attributesList
      if (item._id) {
        const idx = item._id - 1
        data[idx] = item
      } else {
        const cont = this.attributesList.length + 1
        item._id = cont
        data.push(item)
      }
      this.chkAttrContent()
      this.$emit('input', data)
    },
    setEditAttrValue(i, val) {
      const ckhAttrValue = this.attributesList.find((obj) => {
        return (
          obj.attributeValue.toLowerCase() ===
            this.valueSelect.attributeValue.toLowerCase() &&
          obj.name.toLowerCase() === this.nameSelect.name.toLowerCase()
        )
      })
      if (ckhAttrValue) {
        this.editAttrValues = i
        const error = {
          msg: `${this.$t('message.attributeValue')} ${this.$t(
            'message.exists'
          )}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyInfo(error)
        this.showNotify(notify)
      } else {
        const item = val

        this.attrValueStatus = null

        this.attributesList[i] = {
          _id: item._id,
          attributeId: this.nameSelect.attributeId,
          name: this.nameSelect.name,
          attributeValueId: this.valueSelect.attributeValueId,
          attributeValue: this.valueSelect.attributeValue
        }

        this.chkAttrContent()

        this.editAttrValues = null
      }
    },
    editAttrValuesOpen(i, val) {
      const item = val
      this.editAttrValues = i
      this.nameSelect = {
        attributeId: item.attributeId,
        name: item.name
      }
      this.valueSelect = {
        attributeValue: item.attributeValue,
        attributeValueId: item.attributeValueId
      }
    },
    editAttrValuesClose() {
      this.editAttrValues = null
      this.nameSelect = {}
      this.valueSelect = {}
    },
    getOptionsValue(s) {
      this.attributeOptionsValue = []
      let list = []
      if (this.options.length > 0) {
        list = this.options.filter((obj) => {
          return obj.attribute.attributeId === s.attributeId
        })
      }
      if (list.length) {
        list[0].attribute.attributeValues.data.forEach((obj) => {
          const arr = {
            attributeValue: obj.value,
            attributeValueId: obj.attributeValueId
          }
          this.attributeOptionsValue.push(arr)
        })
      }
    },
    delAttribute(item) {
      const remove = {
        msg: `${this.$t('message.removeAttributeValue')} ${item.name}`,
        ok: this.$t('button.ok'),
        cancel: this.$t('button.cancel'),
        item: item
      }
      const notify = showNotifyDelete(remove)
      this.showNotify(notify)
    },
    setDelAttribute(item) {
      this.attributesList = this.attributesList.filter((obj) => {
        return obj._id !== item._id
      })
      this.chkAttrContent()
    },
    chkAttrContent() {
      let getRequired = false
      const isRequired = this.listAttrRequired.length > 0
      const isData = this.attributesList.length > 0
      if (isRequired) {
        if (isData) {
          let chkList = []
          this.listAttrRequired.forEach((req) => {
            let count = 0
            this.attributesList.forEach((obj) => {
              if (req === obj.attribute.name) {
                count++
              }
            })
            chkList.push(count)
          })
          getRequired = !(chkList.indexOf(0) > -1)
          chkList = []
        } else {
          getRequired = false
        }
      } else {
        getRequired = true
      }
      this.getValidation = getRequired
    },
    getAttributesRequeired() {
      const list = this.attributesOptions.filter((obj) => {
        return obj.required === true
      })
      const attrs = []
      list.forEach((obj) => {
        attrs.push(obj.attribute.name)
      })
      return attrs
    },
    saveAttrValue() {
      this.editAttrValues = null
      this.$emit('refresh')
    },
    modalOpen(item) {
      const newAttr = {
        _id: null,
        name: null,
        attributeId: null,
        attributeValue: null,
        attributeValueId: null
      }
      this.attributesSelected = item || newAttr
      this.modalAttributes = true
    },
    modalClose() {
      this.attributesSelected = []
      this.modalAttributes = false
    },
    showNotify(options, info) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'success') {
            this.goBack()
          }
          if (options.type === 'question') {
            this.setDelAttribute(options.item)
          }
        })
        .catch(() => {})
    }
  },
  beforeDestroy() {
    const dropdown =
      this.$refs.attributeValueComboBox[0].$refs[
        'v-combobox'
      ].$refs.menu.$children[0].$el?.querySelector('.v-select-list')

    if (dropdown) {
      dropdown.removeEventListener('scroll', this.handleScroll)
    }
  },
  mounted() {
    this.$emit('valAttr', this.listAttrRequired.length > 0)
  }
}
</script>
<style scoped>
.ifc-tab-content {
  position: relative;
  display: block;
  width: 100%;
}
.ifc-align-right {
  text-align: right;
}
.ifc-align-center {
  text-align: center;
}
.no-message {
  position: relative;
  display: block;
  margin-bottom: -35px;
}
</style>
