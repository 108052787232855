<template>
  <div>
    <ifc-combobox
      class="ifc-filter-brand"
      :placeholder="$t('label.select')"
      v-model="selected"
      :options="listBrands"
      keyLabel="name"
      keyValue="brandId"
      :label="label"
      :loading="loading"
      @input.native="debouncedSetFilterString"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import { getBrandsSimple } from '@/services/brands'

export default {
  name: 'AutoCompleteListBrands',
  props: {
    value: {
      default: 0
    },
    label: {
      require: false
    },
    channelId: {
      require: false
    }
  },
  data() {
    return {
      debouncedSetFilterString: _.debounce(this.setFilterString, 1500),
      loading: false,
      listBrands: []
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(s) {
        this.$emit('input', s?.brandId || s)
      }
    }
  },
  watch: {
    channelId: {
      handler() {
        this.getBrands()
      },
      immediate: true
    }
  },
  methods: {
    setFilterString(event) {
      const { value } = event.target
      this.getBrands(value)
    },
    async getBrands(value) {
      if (!this.channelId) return

      this.loading = true
      this.listBrands = []
      try {
        const data = await getBrandsSimple(this.channelId, value)

        const list = {
          brandId: 0,
          name: this.$t('label.brandNo')
        }

        if (!data) {
          this.listBrands = []
          this.listBrands.push(list)
        } else {
          data.data.unshift(list)
          this.listBrands = data.data
        }

        if (this.value > 0) {
          this.selected = this.value
        } else {
          this.selected = null
        }
      } catch {
        this.listBrands = []
      } finally {
        this.loading = false
      }
    }
  },
  beforeDestroy() {
    const dropdown =
      this.$refs.attributeValueComboBox &&
      this.$refs.attributeValueComboBox[0].$refs[
        'v-combobox'
      ].$refs.menu.$children[0].$el?.querySelector('.v-select-list')

    if (dropdown) {
      dropdown.removeEventListener('scroll', this.handleScroll)
    }
  }
}
</script>
<style lang="scss">
.ifc-filter-brand > label.ifc-label {
  margin-bottom: 3px !important;
}
</style>
