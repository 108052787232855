<template>
  <div>
    <div class="ifc-tab-content">
      <v-row v-if="page === 'new'">
        <v-col cols="12" sm="12" md="4">
          <auto-complete-list-channels
            v-model="content.channelId"
            :label="$t('label.channel')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <ifc-typography
            :text="$t('titles.attributesProperties')"
            typography-style="h4"
            text-weight="700"
          />
        </v-col>
        <v-col cols="12" sm="12" md="8" class="ifc-align-right ifc-py-none">
          <ifc-switch
            class="ifc-switch-inline ifc-mt-none ifc-mx-10"
            v-model="content.active"
            :label="`${$t('status.active.short')}?`"
            inset
            color="success"
          />
          <ifc-switch
            class="ifc-switch-inline ifc-mt-none ifc-mx-10"
            v-model="content.isFilterable"
            :label="`${$t('label.filterable')}?`"
            inset
            color="success"
          />
          <ifc-switch
            class="ifc-switch-inline ifc-mt-none ifc-mx-10"
            v-model="content.buybox"
            :label="`${$t('label.buybox')}?`"
            inset
            color="success"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" v-if="page !== 'new'">
          <ifc-input
            :label="$t('label.id')"
            v-model="content.attributeId"
            disabled
          />
        </v-col>
        <v-col cols="12" sm="6" :md="page === 'new' ? '8' : '4'">
          <ifc-input
            :label="`${$t('label.name')}*`"
            v-model="content.name"
            :rules="rules.required"
          />
        </v-col>
        <v-col cols="12" sm="6" :md="page === 'new' ? '4' : '4'">
          <ifc-select
            appendIcon="expand_more"
            v-model="content.type"
            :options="listEnum"
            :label="`${$t('label.type')}*`"
            :placeholder="$t('label.select')"
            :rules="rules.required"
          />
        </v-col>
        <v-col cols="12" sm="6" :md="page === 'new' ? '8' : '4'">
          <ifc-combobox
            hideMenu
            appendIcon="expand_more"
            clearable
            v-model="content.synonyms"
            :options="content.synonyms"
            multiple
            :label="`${$t('label.synonyms')}`"
            deletableChips
            chipTextColor="shade-0"
            chipBackgroundColor="information-200"
          />
        </v-col>
        <v-col cols="12" sm="6" :md="page === 'new' ? '4' : '4'">
          <ifc-select
            appendIcon="expand_more"
            v-model="content.defaultOrder"
            :options="orderEnum"
            :label="`${$t('label.defaultOrder')}*`"
            :placeholder="$t('label.select')"
            :rules="rules.required"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { showNotifyInfo } from '@/plugins/notify'
import AutoCompleteListChannels from '../../Autocomplete/AutoCompleteListChannels.vue'

/* components */

export default {
  components: { AutoCompleteListChannels },
  name: 'tabProperties',
  props: ['selected', 'page'],
  data() {
    return {
      loaded: false,
      channelId: null,
      content: {
        attributeId: null,
        channelId: null,
        name: null,
        isFilterable: false,
        active: true,
        buybox: false,
        synonyms: [],
        type: null,
        defaultOrder: null
      },
      listEnum: [
        { label: this.$t('type.color'), value: 'color' },
        { label: this.$t('type.image'), value: 'image' },
        { label: this.$t('type.selection'), value: 'select' },
        { label: this.$t('type.text'), value: 'text' },
        { label: this.$t('type.filter'), value: 'filter' }
      ],
      orderEnum: [
        // { label: this.$t('order.custom'), value: 'CUSTOM' },
        // { label: this.$t('order.asc'), value: 'ALPHANUMERIC-ASCENDING' },
        { label: this.$t('order.desc'), value: 'ALPHANUMERIC-DESCENDING' }
      ],
      rules: {
        required: [(v) => !!v || this.$t('message.required')],
        type: [
          (v) =>
            (!!v && this.listEnum.find((list) => list.label === v)) ||
            this.$t('message.type')
        ]
      },
      loading: false
    }
  },
  watch: {
    selected: {
      handler(s) {
        if (typeof s !== 'object' || !s) {
          const error = {
            msg: `${this.$t('message.errorRequest')}`,
            ok: this.$t('button.ok')
          }
          const notify = showNotifyInfo(error)
          this.showNotify(notify)
          this.content = []
        } else if (this.page !== 'new') {
          this.content = { ...s }
          if (!this.loaded) {
            this.loaded = true
          }
        }
      },
      deep: true
    },
    content: {
      handler(newContent) {
        if (this.loaded || this.page === 'new') {
          this.$emit('updateProperties', newContent)
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.page !== 'new') {
      this.content = this.selected
      this.content.defaultOrder =
        this.selected?.attributeCategories[0]?.sortOrderType || null
    }

    this.channelId = this.content.channelId = this.$route.query?.channelId
  },
  methods: {}
}
</script>

<style scoped>
.ifc-tab-content {
  position: relative;
  display: block;
  width: 100%;
}
.ifc-switch-inline {
  display: inline-block;
}
.ifc-align-right {
  text-align: right;
}
</style>
