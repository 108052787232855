export const showNotifySuccess = (params) => {
  const options = {
    iconColor: 'success-100',
    icon: 'check_circle',
    confirmButtonColor: 'success-100',
    confirmLabel: params.ok,
    type: 'success',
    text: params.msg
  }
  return options
}

export const showNotifyDelete = (params) => {
  const options = {
    iconColor: 'error-100',
    icon: 'ph-trash-simple',
    declineButtonColor: 'error-100',
    confirmButtonColor: 'error-100',
    declineLabel: params.cancel,
    confirmLabel: params.ok,
    declineOutlined: true,
    type: 'question',
    text: params.msg,
    id: params.id,
    name: params.name,
    item: params.item
  }
  return options
}

export const showNotifyInfoQuestion = (params) => {
  const options = {
    iconColor: 'information-200',
    icon: 'ph-info',
    declineButtonColor: 'information-200',
    confirmButtonColor: 'information-200',
    declineLabel: params.cancel,
    confirmLabel: params.ok,
    declineOutlined: true,
    type: 'question',
    text: params.msg,
    id: params.id,
    name: params.name,
    item: params.item
  }
  return options
}

export const showNotifyWarning = (params) => {
  const options = {
    iconColor: 'warning-400',
    icon: 'ph-warning',
    confirmButtonColor: 'warning-400',
    confirmLabel: params.ok,
    type: 'warning',
    text: params.msg
  }
  return options
}

export const showNotifyInfo = (params) => {
  const options = {
    iconColor: 'information-200',
    icon: 'ph-info',
    confirmButtonColor: 'information-200',
    confirmLabel: params.ok,
    type: 'info',
    text: params.msg
  }
  return options
}
