import { ApiAcc } from '@/plugins/axios/axios'

export const getChannelsList = async () => {
  try {
    const { data: responseData } = await ApiAcc.get(
      `/admin/accounts/${window.parent.IFC_VARIABLES.headers['x-tenant-id']}/saleschannels`,
      {
        params: {
          perPage: 100
        }
      }
    )

    return responseData?.data?.saleschannels || []
  } catch (error) {
    console.error(error)
    return []
  }
}
