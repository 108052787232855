<template>
  <div class="ifc-tab-content">
    <div>
      <v-row v-for="(item, i) in categories" :key="i">
        <v-col>
          <ifc-card color="white" class="ifc-mb-24">
            <div class="ifc-card-header">
              <ifc-typography
                :text="item.name"
                typography-style="h4"
                text-weight="700"
              />
            </div>
            <v-row>
              <v-col cols="4">
                <ifc-select
                  appendIcon="expand_more"
                  v-model="item.level"
                  :options="listLevels"
                  :label="`${$t('label.level')}*`"
                  :placeholder="$t('label.select')"
                  :rules="rules.required"
                  @change="updateCategory(item)"
                />
              </v-col>
              <v-col>
                <ifc-switch
                  class="ifc-switch-inline ifc-mt-25 ifc-mx-10"
                  v-model="item.required"
                  :label="`${$t('label.requiredAttribute')}?`"
                  inset
                  color="success"
                  @input="updateCategory(item)"
                />
              </v-col>
              <!--
              <v-col class="text-right">
                <ifc-button
                  v-if="showDeleteButton"
                  icon="ph-trash"
                  outlined
                  color="error-200"
                  size="small"
                  @click="deleteCategory(item.categoryId, true)"
                />
              </v-col>
              -->
            </v-row>
          </ifc-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { showNotifyInfo, showNotifySuccess } from '@/plugins/notify'
import {
  getAttributesData,
  setAttributeAssociateCategory,
  deleteAttributeAssociateCategory
} from '@/services/attributes'

/* components */

export default {
  name: 'tabCategories',
  props: ['attrId', 'selected'],
  data() {
    return {
      channelId: null,
      showDeleteButton: true,
      categories: [],
      rules: {
        required: [(v) => !!v || this.$t('message.required')]
      },
      loading: false,
      listLevels: [
        { label: this.$t('level.product'), value: 'PRODUCT' },
        { label: this.$t('level.sku'), value: 'SKU' }
      ]
    }
  },
  mounted() {
    this.channelId = this.selected.channelId

    this.getAttributes()
  },
  watch: {
    selected: {
      handler() {
        this.getAttributes()
      },
      deep: true
    }
  },
  methods: {
    async getAttributes() {
      if (!this.channelId) {
        return
      }

      try {
        const attrData = await getAttributesData(this.attrId, this.channelId)

        if (!attrData?.attributeCategories?.length) {
          return
        }

        this.categories = attrData.attributeCategories.map((cat) => {
          return {
            categoryId: cat.categoryId,
            required: cat.required,
            name: cat.categoryName,
            level: cat.level,
            sortOrderType: cat.sortOrderType
          }
        })
      } catch (error) {
        console.error('🚀 ~ getAttributesContent ~ error:', error)
      } finally {
        this.loading = false
      }
    },
    async deleteCategory(catId, catLevel, update = false) {
      await deleteAttributeAssociateCategory({
        attributeId: parseInt(this.attrId),
        categoryId: parseInt(catId),
        level: catLevel
      })

      if (update) {
        this.$emit('updateCategories', true)
      }
    },
    async updateCategory(cat) {
      const level = cat.level || 'PRODUCT'
      const originalRequired = cat.required

      try {
        if (cat.categoryId) {
          await this.deleteCategory(cat.categoryId, level)
        }

        const params = {
          channelId: this.channelId,
          attributeId: parseInt(this.attrId),
          level,
          isRequired: cat.required,
          categoryIds: [cat.categoryId],
          sortOrderType: this.selected.defaultOrder || 'ALPHANUMERIC-DESCENDING'
        }

        await setAttributeAssociateCategory(params)

        this.showNotify(
          showNotifySuccess({
            msg: `${this.$t('message.categoriesUpdated')}`,
            ok: this.$t('button.ok')
          })
        )

        this.$emit('updateCategories', true)
      } catch (error) {
        console.error('🚀 ~ updateCategory ~ error:', error)

        cat.required = !originalRequired

        this.showNotify(
          showNotifyInfo({
            msg: `${this.$t('message.errorRequest')}`,
            ok: this.$t('button.ok')
          })
        )
      } finally {
        this.categoriesUpdated = false
      }
    },
    showNotify(options, cb) {
      this.$notify(options)
        .then(cb)
        .catch(() => {})
    }
  }
}
</script>
<style scoped>
.ifc-card-header {
  display: block;
  background-color: #e7ecf2;
  padding: 20px;
  margin: -20px -20px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ifc-mt-25 {
  margin-top: 25px;
}
</style>
