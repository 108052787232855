<template>
  <div>
    <ifc-input
      :label="this.label"
      v-model="dateSelect"
      :placeholder="this.label"
      appendIcon="calendar_today"
      @click="showModal()"
      @keydown="handleActionModal"
      mask="##/##/####"
    />
    <ifc-dialog v-model="modal" @onClose="closeModal()" maxWidth="450px">
      <ifc-date-flat-picker
        theme="theme-light-info"
        v-model="dateCalend"
        :dark="false"
        language="PT"
        mode="single"
        fixed
        :enableTime="false"
        removeBorder
        :customConfig="configDate"
      />
      <ifc-button
        prependIcon="close"
        class="ifc-btn-center"
        color="information-100"
        outlined
        :label="$t('button.clear')"
        @click="dateCalend = undefined"
      />
    </ifc-dialog>
  </div>
</template>
<script>
export default {
  name: 'ModalDate',
  props: {
    selected: {
      type: String,
      required: false
    },
    value: {
      type: String,
      require: false
    },
    dateStart: {
      type: String,
      require: false
    },
    dateEnd: {
      type: String,
      require: false
    },
    label: {
      type: String,
      require: false
    }
  },
  data() {
    return {
      modal: false,
      dateSelect: ''
    }
  },
  computed: {
    dateCalend: {
      get() {
        let date = this.value

        if (date) {
          const parsedDate = this.$moment.utc(this.getDate(this.value))

          if (parsedDate.isValid()) {
            parsedDate.set({ hour: 3, minute: 0, second: 0 })
            date = parsedDate.toISOString()
          }
        }

        return date
      },
      set(s) {
        this.modal = false
        this.dateSelect = this.setDate(s)

        this.$emit('input', this.setDate(s))
      }
    },
    configDate() {
      const endDate = this.dateEnd ? this.getDate(this.dateEnd) : undefined
      const obj = {
        maxDate: endDate
      }
      return obj
    }
  },
  watch: {
    value() {
      this.dateSelect = this.value
    }
  },
  methods: {
    showModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
    handleActionModal(event) {
      const ESCKeyCode = 27

      if (event.keyCode === ESCKeyCode && this.modal) {
        this.closeModal()
      } else {
        this.showModal()
      }
    },
    getDate(d) {
      const date = d
      if (date.indexOf('/') > -1) {
        return date.split('/').reverse().join('-')
      }
      return undefined
    },
    setDate(d) {
      let date = d ? d.join() : ''
      if (date) {
        date = new Date(date)
        let day = date.getDate()
        day = day < 10 ? '0' + day : day
        let month = date.getMonth() + 1
        month = month < 10 ? '0' + month : month
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      } else {
        return ''
      }
    }
  },
  mounted() {
    if (this.selected) {
      this.dateSelect = this.selected
    } else {
      this.dateSelect = this.value
    }
  }
}
</script>

<style scoped>
.ifc-btn-center {
  position: relative;
  display: block;
  margin: 15px auto;
}
</style>
