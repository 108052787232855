<template>
  <ifc-dialog
    v-bind="attributes"
    v-on="listeners"
    maxWidth="350"
    @onClose="handleCloseModal()"
  >
    <v-row>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-combobox
          :label="$t('label.attribute')"
          appendIcon="expand_more"
          keyValue="attributeId"
          keyLabel="name"
          v-model="attrSelect"
          :options="attributeOptionsName"
          size="small"
          color="primary"
          :hideMoreOptions="false"
          :deletableChips="false"
          :multiple="false"
          :placeholder="$t('label.name')"
          returnObject
          :clearable="true"
        />
        <!-- :change="getAttrList()" -->
      </v-col>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-input
          :label="$t('label.value')"
          v-model="attrValues.attributeValue"
        />
      </v-col>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-input
          :label="$t('label.content')"
          v-model="attrValues.attributeContent"
        />
      </v-col>
      <v-col cols="12" class="ifc-py-10 ifc-px-none" v-if="message.status">
        <ifc-typography typography-style="body-small" text-weight="500">
          <span
            :class="
              message.status === 'success'
                ? 'ifc-color--success-200'
                : 'ifc-color--error-200'
            "
          >
            <ifc-icon
              :name="
                message.status === 'success' ? 'ph-check-circle' : 'ph-warning'
              "
              type="filled"
              iconClass="color-black"
              :iconColor="
                message.status === 'success' ? 'success-200' : 'error-200'
              "
              iconSize="24"
            />
            {{ message.msg }}
          </span>
        </ifc-typography>
      </v-col>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-button
          class="col-12 mt-6"
          size="large"
          :label="$t('button.add')"
          color="information-200"
          @click="setAttributesValue()"
        />
      </v-col>
    </v-row>
  </ifc-dialog>
</template>

<script>
import {
  getAttributesData,
  setCreateAttributeValue
} from '@/services/attributes'
export default {
  name: 'CreateAttributesValues',
  components: {},
  props: ['options', 'channelId'],
  data() {
    return {
      content: [],
      attrValues: {
        attributeValue: null,
        attributeContent: null
      },
      attrSelect: {},
      attrValueList: [],
      message: {}
    }
  },
  watch: {
    attrSelect: {
      handler(attr) {
        this.getAttrList(attr)
      },
      deep: true
    }
  },
  computed: {
    attributes() {
      const attributes = {
        ...this.$props,
        ...this.$attrs
      }

      return attributes
    },
    listeners() {
      return this.$listeners
    },
    attributeOptionsName() {
      const opt = []
      this.options.forEach((obj) => {
        const arr = {
          name: obj?.attribute.name || 'Atributo ' + obj.attributeId,
          attributeId: obj.attributeId
        }
        opt.push(arr)
      })
      return opt
    }
  },
  methods: {
    async getAttrList(attr) {
      try {
        if (attr.attributeId) {
          const id = attr.attributeId
          this.content = await getAttributesData(id, this.channelId)
        }
      } catch (error) {
        console.error('🚀 ~ getAttributesContent ~ error:', error)
      }
    },
    async setAttributesValue() {
      if (!this.content.attributeValues) {
        this.message = {
          msg: `${this.$t('message.attributeValue')}`,
          status: 'error'
        }
        return
      }

      const ckhAttr = this.content.attributeValues.find((obj) => {
        return (
          obj.attributeValue.toLowerCase() ===
          this.attrValues.attributeValue.toLowerCase()
        )
      })
      if (ckhAttr) {
        this.message = {
          msg: `${this.$t('message.attributeValue')} ${
            this.attrValues.attributeValue
          } ${this.$t('message.exists')}`,
          status: 'error'
        }
      } else {
        const success = {
          msg: `${this.$t('message.attributeValue')} ${
            this.attrValues.attributeValue
          } ${this.$t('message.create')}`,
          status: 'success'
        }
        const error = {
          msg: `${this.$t('message.errorRequest')}`,
          status: 'error'
        }

        const params = {
          channelId: this.channelId,
          attributeId: this.content.attributeId,
          attributeValue: {
            value: this.attrValues.attributeValue,
            content: this.attrValues.attributeContent,
            sortOrder: this.content.attributeValues.length + 1
          },
          synonyms: []
        }

        try {
          await setCreateAttributeValue(params)
          this.message = success
          this.$emit('save')
          setTimeout(() => {
            this.attrSelect = {}
            this.closeModal()
          }, 3000)
        } catch {
          this.message = error
        }
      }
    },
    addAttrValue() {
      this.$emit('attrValues', this.attrValues)
      this.clearModal()
    },
    clearModal() {
      this.attrValues = {
        attributeValue: null,
        attributeContent: null
      }
    },
    closeModal() {
      this.clearModal()
      this.$emit('onClose')
      this.message = {}
    },
    handleCloseModal() {
      this.clearModal()
      this.message = {}
    }
  }
}
</script>

<style scoped>
.drag-msg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ifc-top-negative {
  margin: -50px -8px 0;
}
.ifc-upload.ifc-top-negative .v-card__actions {
  padding: 0 !important;
}
.ifc-combobox__container .ifc-label {
  margin-bottom: 5px !important;
}
</style>
