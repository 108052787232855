import { ApiCtg } from '@/plugins/axios/axios'

const URL = '/products'

const getControlPagination = (params) => {
  let orderDirection = null
  const { filters = {}, ...restPag } = params
  if (typeof restPag?.sortDesc?.[0] === 'boolean') {
    orderDirection = restPag?.sortDesc?.[0] ? 'desc' : 'asc'
  }
  const formattedPagination = {
    perPage: restPag?.itemsPerPage || null,
    page: restPag?.page || null,
    orderBy: restPag?.sortBy?.[0] || null,
    orderDirection: orderDirection
  }
  for (const filterKey in filters) {
    if (filters[filterKey]) formattedPagination[filterKey] = filters[filterKey]
  }
  return formattedPagination
}

export const getProductList = async (params) => {
  const { data } = await ApiCtg.get(URL, {
    // const { data } = await ApiCtg.get(`/managementProducts/getProductsFilters`, {
    params: getControlPagination(params)
  })
  return data
}

export const getProductData = async (id, channelId) => {
  const { data } = await ApiCtg.get(`${URL}/${id}`, {
    params: {
      channelId: channelId
    }
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const getProductListNew = async (id, params) => {
  const { data } = await ApiCtg.get(`${URL}/file/${id}`, {
    params: getControlPagination(params)
  })
  return data
}
export const setCreateProduct = async (params) => {
  let data = {
    status: false,
    data: {}
  }
  await ApiCtg.post(`${URL}`, params)
    .then((resp) => {
      data = {
        status: true,
        data: resp
      }
    })
    .catch((err) => {
      data = {
        status: false,
        data: err
      }
    })
  return data
}

export const setUpdateProduct = async (id, params) => {
  let data = ''
  await ApiCtg.put(`${URL}/${id}`, params)
    .then(() => {
      data = {
        status: true
      }
    })
    .catch(() => {
      data = {
        stauts: false
      }
    })
  return data
}

export const setDeleteProduct = async (id) => {
  const { data } = await ApiCtg.delete(`${URL}/${id}`)
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}
