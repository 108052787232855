import { ApiCtg } from '@/plugins/axios/axios'

const URL = '/attributes'
const URL_VALUES = '/attributes-values'

const getControlPagination = (params) => {
  let orderDirection = null
  const { filters = {}, ...restPag } = params
  if (typeof restPag?.sortDesc?.[0] === 'boolean') {
    orderDirection = restPag?.sortDesc?.[0] ? 'desc' : 'asc'
  }
  const formattedPagination = {
    perPage: restPag?.itemsPerPage || null,
    page: restPag?.page || null,
    orderBy: restPag?.sortBy?.[0] || null,
    orderDirection: orderDirection
  }
  for (const filterKey in filters) {
    if (filters[filterKey]) formattedPagination[filterKey] = filters[filterKey]
  }
  return formattedPagination
}

export const getAttributesList = async (params) => {
  const { data } = await ApiCtg.get(URL, {
    params: getControlPagination(params)
  })
  return data
}

export const getAttributesData = async (id, channelId) => {
  const { data } = await ApiCtg.get(`${URL}/${id}`, {
    params: {
      channelId: channelId
    }
  })

  return data
}

export const getAllByCategory = async (id, params, channelId) => {
  const { data } = await ApiCtg.get(`${URL}/category/${id}`, {
    params: {
      ...params,
      channelId: channelId
    }
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return ['error']
    })
  return data
}

export const getAllByLevel = async (level, channelId) => {
  const { data } = await ApiCtg.get(`${URL}/level/${level}`, {
    params: {
      channelId: channelId
    }
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return ['error']
    })
  return data
}

export const setCreateAttributes = async (params) => {
  let data = ''
  await ApiCtg.post(`${URL}`, params)
    .then((attribute) => {
      data = attribute
    })
    .catch(() => {
      data = false
    })
  return data
}

export const setAttributeAssociateCategory = async (params) => {
  return await ApiCtg.post(`${URL}/associate`, params)
}

export const deleteAttributeAssociateCategory = async (params) => {
  return await ApiCtg.put(`${URL}/unassociate`, params)
}

export const setUpdateAttributes = async (id, params) => {
  let data = ''
  await ApiCtg.put(`${URL}/${id}`, params)
    .then(() => {
      data = true
    })
    .catch(() => {
      data = false
    })
  return data
}

export const setCreateAttributeValue = async (params) => {
  return await ApiCtg.post(`${URL_VALUES}`, params)
}

export const setUpdateAttributeValue = async (id, params) => {
  let data = null
  await ApiCtg.put(`${URL_VALUES}/${id}`, params)
    .then(() => {
      data = true
    })
    .catch(() => {
      data = false
    })
  return data
}

export const getAttributeValues = async (id, channelId, page) => {
  const params = {
    attributeId: id,
    channelId: channelId
  }
  const { data } = await ApiCtg.get(
    `${URL_VALUES}?pageSize=10&pageNumber=${page}`,
    {
      params
    }
  )
  return data
}

export const getAttributeValue = async (id, channelId, value) => {
  const params = {
    attributeId: id,
    channelId: channelId
  }
  const { data } = await ApiCtg.get(
    `${URL_VALUES}?pageSize=10&value=${value}&exactMatch=false`,
    {
      params
    }
  )
  return data
}

export const deleteAttributeValue = async (id, channelId) => {
  await ApiCtg.delete(`${URL_VALUES}/${id}`, {
    params: {
      channelId: channelId
    }
  })
}
