<template>
  <div class="ifc-tab-content">
    <v-row>
      <v-col cols="12" sm="8" md="6">
        <brands-list
          :label="`${$t('label.brands')}*`"
          v-model="selBrand"
          :channelId="channelId"
        />
      </v-col>
      <v-col
        cols="12"
        :sm="'4'"
        :md="'6'"
        class="ifc-align-right ifc-pb-none ifc-pt-36"
      >
        <ifc-switch
          class="ifc-switch-inline ifc-mt-none"
          v-model="content.active"
          :label="`${$t('status.active.short')}?`"
          inset
          color="success"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="8" class="ifc-py-none">
        <ifc-input :label="$t('label.name')" v-model="content.name" disabled />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input :label="$t('label.id')" v-model="content.brandId" disabled />
      </v-col>
      <v-col cols="12" sm="6" class="ifc-py-none">
        <ifc-input
          :label="$t('label.email')"
          v-model="content.email"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" class="ifc-py-none">
        <ifc-input
          :label="$t('label.phone')"
          v-model="content.phoneNumber"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" class="ifc-py-none">
        <ifc-input :label="$t('label.site')" v-model="content.site" disabled />
      </v-col>
      <v-col cols="12" sm="6" class="ifc-py-none">
        <ifc-input
          :label="$t('label.customerServiceNotes')"
          v-model="content.customerServiceNotes"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" class="ifc-py-none">
        <ifc-input
          :label="$t('label.createdAt')"
          v-model="content.createdAtView"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" class="ifc-py-none">
        <ifc-input
          :label="$t('label.updatedAt')"
          v-model="content.updatedAtView"
          disabled
        />
      </v-col>
    </v-row>
    <v-row v-if="selBrand">
      <v-col cols="12">
        <ifc-typography
          :text="$t('titles.seo')"
          typography-style="h4"
          text-weight="700"
        />
      </v-col>
    </v-row>
    <v-row v-if="selBrand">
      <v-col cols="12" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.canonical')"
          v-model="content.canonical"
          disabled
        />
      </v-col>
      <v-col cols="12" md="4" class="ifc-py-none">
        <ifc-textarea
          class="ifc-textarea-height"
          :label="$t('label.metaKeywords')"
          v-model="content.keywords"
          disabled
        />
      </v-col>
      <v-col cols="12" md="4" class="ifc-py-none">
        <ifc-textarea
          class="ifc-textarea-height"
          :label="$t('label.metaDescription')"
          v-model="content.description"
          disabled
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getBrandsData } from '@/services/brands'
import BrandsList from '@/components/Catalog/Autocomplete/AutoCompleteListBrands.vue'
export default {
  name: 'tabBrands',
  components: {
    'brands-list': BrandsList
  },
  props: ['id', 'value', 'channelId'],
  data() {
    return {
      content: {
        brandId: null,
        active: true,
        name: null,
        description: null,
        site: null,
        email: '',
        phoneNumber: '',
        customerServiceNotes: null,
        seo: {
          canonical: null,
          metaKeywords: '',
          metaDescription: null
        },
        createdAt: null,
        updatedAt: null
      }
    }
  },
  watch: {
    selBrand: {
      handler() {
        setTimeout(() => {
          this.getBrandsContent()
        }, 10)
      },
      deep: true
    }
  },
  computed: {
    selBrand: {
      get() {
        return this.value
      },
      set(s) {
        this.$emit('input', s)
      }
    }
  },
  methods: {
    async getBrandsContent() {
      if (this.selBrand) {
        this.loading = true
        try {
          this.content = await getBrandsData(this.selBrand, this.channelId)
          if (this.content) {
            this.content.createdAtView = this.chkDate(this.content?.createdAt)
            this.content.updatedAtView = this.chkDate(this.content?.updatedAt)
          }
        } finally {
          this.loading = false
        }
      }
    },
    chkDate(date) {
      if (!date) return date
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    setCanonical(t) {
      const pText = t
      let rText
      if (pText) {
        rText = pText.toLowerCase().replace(/ /g, '-')
        rText = rText.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        rText = rText.replace(/[^a-zA-Z0-9.-]/g, '')
      } else {
        rText = ''
      }
      return rText
    }
  }
}
</script>
<style scoped>
.ifc-tab-content {
  position: relative;
  display: block;
  width: 100%;
}
.ifc-switch-inline {
  display: inline-block;
}
.ifc-align-right {
  text-align: right;
}
.ifc-switch-inline {
  display: inline-block;
}
.ifc-separetor {
  border-bottom: 1px solid #c6d3de;
}
</style>
