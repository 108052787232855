<template>
  <div class="ifc-tab-content">
    <v-row>
      <v-col cols="12" class="ifc-align-right ifc-py-none">
        <ifc-switch
          class="ifc-switch-inline ifc-mt-none ifc-mx-10"
          v-model="content.isVisible"
          :label="`${$t('label.isVisibleSite')}?`"
          inset
          color="success"
          :colorThumb="content.isVisible ? 'success-200' : 'neutral-400'"
        />
        <ifc-switch
          class="ifc-switch-inline ifc-mt-none ifc-mx-10"
          v-model="content.isProduced"
          :label="`${$t('label.isProduction')}?`"
          inset
          color="success"
          :colorThumb="content.isProduced ? 'success-200' : 'neutral-400'"
        />
        <ifc-switch
          class="ifc-switch-inline ifc-mt-none ifc-mx-10"
          v-model="content.showWithOutStock"
          :label="`${$t('label.isVisibleNotStock')}?`"
          inset
          color="success"
          :colorThumb="content.showWithOutStock ? 'success-200' : 'neutral-400'"
        />
      </v-col>
      <v-col cols="12">
        <auto-complete-list-channels
          v-model="content.channelId"
          :label="$t('label.channel')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="id !== 'new'" class="ifc-py-none">
        <ifc-input
          :label="$t('label.id')"
          v-model="content.productId"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="id !== 'new'" class="ifc-py-none">
        <ifc-input
          :label="$t('label.createdAt')"
          v-model="content.createdAtView"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="id !== 'new'" class="ifc-py-none">
        <ifc-input
          :label="$t('label.updatedAt')"
          v-model="content.updatedAtView"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.referenceCode')}*`"
          v-model="content.externalProductId"
          :rules="rules.required"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('titles.conversionFactor')}`"
          v-model="content.conversionFactor"
          :rules="rules.optionalNumber"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <date-modal
          :label="$t('label.releaseDate')"
          v-model="content.releaseDate"
        />
      </v-col>
      <v-col cols="12" sm="12" md="8" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.title')}*`"
          v-model="content.title"
          :rules="rules.required"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.name')}*`"
          v-model="content.name"
          :rules="rules.required"
          :error="null"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.invoiceName')}*`"
          v-model="content.invoiceName"
          :rules="rules.required"
        />
      </v-col>

      <v-col cols="12" sm="12" md="4" class="ifc-py-none">
        <ifc-textarea
          :label="`${$t('label.resume')}*`"
          v-model="content.descriptionShort"
          :rules="rules.required"
        />
      </v-col>
      <v-col cols="12" sm="12" md="8" class="ifc-py-none">
        <ifc-textarea
          :label="`${$t('label.description')}*`"
          v-model="content.description"
          :rules="rules.required"
        />
      </v-col>
      <v-col cols="12" sm="4" md="4" class="ifc-py-none">
        <ifc-select
          appendIcon="expand_more"
          keyLabel="label"
          keyValue="value"
          v-model="giftPackaging"
          :options="optionsGiftPackaging"
          :label="`${$t('label.giftPackaging')}?`"
          placeholder="Selecione"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ifc-separetor">
        <ifc-typography
          :text="$t('titles.seo')"
          typography-style="h4"
          text-weight="700"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="8" class="ifc-py-none">
        <v-row>
          <v-col cols="12" sm="6" md="6" class="ifc-py-none">
            <input-canonical
              :label="$t('label.canonical')"
              v-model="content.canonical"
              :id="content.productId"
              :context="`products`"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" class="ifc-py-none">
            <keywords
              :label="`${$t('label.keywords')}*`"
              v-model="content.keywords"
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="ifc-py-none">
            <ifc-textarea
              :label="`${$t('label.metaDescription')}*`"
              v-model="content.metaTagDescription"
              :rules="rules.required"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-seo
          :title="content.name"
          :canonical="content.canonical"
          :description="content.metaTagDescription"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import KeyWords from '@/components/Catalog/Autocomplete/AutoCompleteKeyWords.vue'
import AutoCompleteListChannels from '@/components/Catalog/Autocomplete/AutoCompleteListChannels.vue'
import DateModal from '@/components/Catalog/Modals/DateModal.vue'
import Seo from '@/components/Catalog/Seo'
import InputCanonical from '@/components/Catalog/Validations/InputCanonical.vue'

export default {
  name: 'tabGeneralData',
  components: {
    AutoCompleteListChannels,
    keywords: KeyWords,
    'date-modal': DateModal,
    'input-canonical': InputCanonical,
    'ifc-seo': Seo
  },
  props: ['id', 'value'],
  data() {
    return {
      optionsGiftPackaging: [
        { label: this.$t('status.null.short'), value: null },
        { label: this.$t('status.yes.short'), value: true },
        { label: this.$t('status.no.short'), value: false }
      ],
      listDepartment: [],
      rules: {
        optionalNumber: [
          (v) => {
            if (v === null || v === '') {
              return true
            }
            return v > 0 || this.$t('validation.number')
          }
        ],
        required: [(v) => !!v || this.$t('message.required')]
      }
    }
  },
  computed: {
    content: {
      get() {
        return this.value
      },
      set(s) {
        this.$emit('input', s)
      }
    },
    giftPackaging: {
      get() {
        return this.value.giftPackaging
      },
      set(val) {
        this.content.giftPackaging = val
      }
    }
  },
  watch: {
    content: {
      handler(c) {
        this.$emit('input', c)
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.ifc-tab-content {
  position: relative;
  display: block;
  width: 100%;
}
.ifc-switch-inline {
  display: inline-block;
}
.ifc-align-right {
  text-align: right;
}
</style>
