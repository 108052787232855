<template>
  <div class="ifc-tab-content">
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <ifc-typography
          :text="$t('titles.categories')"
          typography-style="h4"
          text-weight="700"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="align-self-center ifc-pb-none">
        <auto-complete-list-categories-vue
          v-model="categoryId"
          :channelId="channelId"
        />
      </v-col>
      <v-col>
        <ifc-button
          size="small"
          @click="addCategory()"
          class="btn-adicionar"
          appendIcon="add"
          color="information-200"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { setAttributeAssociateCategory } from '@/services/attributes'
import { getCategoryData } from '@/services/categories'
import AutoCompleteListCategoriesVue from '../../Autocomplete/AutoCompleteListCategories.vue'

import { showNotifyInfo, showNotifySuccess } from '@/plugins/notify'
/* components */

export default {
  name: 'tabCategoriesForm',
  props: ['channelId', 'selected'],
  components: {
    AutoCompleteListCategoriesVue
  },
  data() {
    return {
      isMounted: false,
      categories: [],
      categoryId: null,
      rules: {
        required: [(v) => !!v || this.$t('message.required')]
      },
      loading: false,
      listLevels: [
        { label: this.$t('level.product'), value: 'PRODUCT' },
        { label: this.$t('level.sku'), value: 'SKU' }
      ]
    }
  },
  methods: {
    async addCategory() {
      if (!this.categoryId) {
        this.showNotify(
          showNotifyInfo({
            msg: `${this.$t('message.categoryRequired')}`,
            ok: this.$t('button.ok')
          })
        )
        return
      }
      const params = {
        channelId: this.channelId,
        attributeId: parseInt(this.selected.attributeId),
        level: 'PRODUCT',
        isRequired: false,
        categoryIds: [this.categoryId],
        sortOrderType: this.selected.defaultOrder || 'ALPHANUMERIC-DESCENDING'
      }
      try {
        await setAttributeAssociateCategory(params)

        this.showNotify(
          showNotifySuccess({
            msg: `${this.$t('message.categoriesUpdated')}`,
            ok: this.$t('button.ok')
          })
        )

        this.$emit('updateCategories', true)
      } catch (error) {
        this.showNotify(
          showNotifyInfo({
            msg: `${this.$t('message.errorRequest')}`,
            ok: this.$t('button.ok')
          })
        )
      }
    },
    async getCategoryName(idCateg) {
      const category = await getCategoryData(idCateg.toString(), this.channelId)
      return {
        id: category.categoryId,
        name: category.name
      }
    },
    showNotify(options, cb) {
      this.$notify(options)
        .then(cb)
        .catch(() => {})
    }
  }
}
</script>
<style scoped>
.ifc-card-header {
  display: block;
  background-color: #e7ecf2;
  padding: 20px;
  margin: -20px -20px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ifc-mt-25 {
  margin-top: 25px;
}
.btn-adicionar {
  margin-bottom: 28px;
  margin-left: 0;
}
</style>
