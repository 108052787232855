<template>
  <div class="tab-sku-list">
    <v-row class="ifc-mt-none">
      <v-col cols="12" md="4" class="ifc-pa-none ifc-align-right">
        <ifc-switch
          v-model="statusAll"
          :label="$t('label.changeStatusSkuAll')"
          inset
          color="success"
          :colorThumb="statusAll ? 'success-200' : 'neutral-400'"
          @input="setStatusSkuAll(statusAll)"
        />
      </v-col>
      <v-col cols="12" md="8" class="ifc-pa-none ifc-align-right">
        <!-- Removido temporiamente pois não temos um endpoint para fazer download da listagem de SKUs -->
        <!-- <ifc-button
          class="ifc-mb-6 ifc-mr-4"
          appendIcon="ph-download"
          :label="`${$t('button.downloadList')}`"
          outlined
          color="information-200"
          @click="downloadList()"
        /> -->
        <ifc-button
          class="ifc-mb-6 ifc-ml-4"
          appendIcon="ph-plus"
          :label="$t('button.newSku')"
          color="information-200"
          @click="getSkuDetails('new')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-table
          :headers="headers"
          :items="content.data"
          :options.sync="options"
          :loading="loading"
        >
          <tbody v-if="content.data.length > 0">
            <tr v-for="(item, i) in content.data" :key="i">
              <td>{{ item.skuId }}</td>
              <td>{{ item.externalCode }}</td>
              <td>
                <ifc-tooltip
                  v-bind="$props"
                  bottom
                  max-width="600"
                  background-color="information-50"
                  text-color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                      {{ item.name }}
                    </div>
                  </template>
                  <span> {{ item.name }} </span>
                </ifc-tooltip>
              </td>
              <td class="ifc-width-100">
                <ifc-switch
                  class="ifc-switch-adjust"
                  v-model="item.isActive"
                  inset
                  color="success"
                  :colorThumb="item.isActive ? 'success-200' : 'neutral-400'"
                  @input="setStatusSku(i)"
                />
              </td>
              <td class="ifc-align-center">
                <ifc-button
                  icon="ph-pencil-simple"
                  @click="getSkuDetails(item.skuId)"
                  size="extra-small"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <span>
                  {{ $t('table.noDataText') }}
                </span>
              </td>
            </tr>
          </tbody>
        </ifc-table>
        <ifc-pagination
          class="ifc-mt-28"
          :totalPages="paginations.pages"
          :perPageOptions="listPerPage"
          :perPageDefault="paginations.perPage"
          @onChangePage="setOnChangePage"
          @onChangePerPage="setOnChangePerPage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getProductSkuList, setUpdateSku, setDeleteSku } from '@/services/sku'
import { postListDownload } from '@/services/imports'
import { showNotifyInfo, showNotifySuccess } from '@/plugins/notify'
export default {
  name: 'Brands',
  props: ['id', 'channelId'],
  data() {
    return {
      headers: [
        {
          text: this.$t('label.id'),
          sortable: false,
          value: 'isActive'
        },
        {
          text: this.$t('label.sku'),
          align: 'start',
          sortable: true,
          value: 'sku'
        },
        {
          text: this.$t('label.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('label.status'),
          align: 'start',
          sortable: false
        },
        {
          text: '',
          sortable: false,
          value: 'edit'
        }
      ],
      statusAll: false,
      modalUpload: false,
      options: {},
      listPerPage: [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 }
      ],
      content: {
        data: [],
        page: 1
      },
      config: [],
      paginations: [],
      filters: {},
      loading: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getListSku()
      },
      deep: true
    },
    content: {
      handler(c) {
        let cont = 0
        c.data.forEach((obj) => {
          cont = obj.isActive ? cont + 1 : cont + 0
        })

        this.statusAll = c.data.length === cont
      },
      deep: true
    }
  },
  methods: {
    async setStatusSku(index) {
      const item = this.content.data[index]
      if (!item.medias.length) {
        this.$nextTick(() => {
          this.$set(this.content.data, index, {
            ...item,
            isActive: false
          })
        })
        return this.$notify({
          text: this.$t('message.activeSku')
        })
      }
      await setUpdateSku(item.skuId, item)
    },
    async setStatusSkuAll(stt) {
      const listContent = this.content.data
      const listSku = {}
      for (const key in listContent) {
        listSku[key] = listContent[key]
        if (listSku[key].isActive !== stt) {
          listSku[key].isActive = stt
          await setUpdateSku(listSku[key].skuId, listSku[key])
        }
      }
    },
    async getListSku() {
      this.loading = true
      try {
        const params = Object.assign(
          {
            filters: this.filters
          },
          this.options
        )
        params.channelId = this.channelId
        const resp = await getProductSkuList(this.id, params)
        if (resp) {
          const { data, ...paginations } = resp
          this.paginations = paginations
          this.content.data = data
          this.content.data.forEach((obj) => {
            obj.createdAtView = this.chkDate(obj.createdAt)
            obj.updatedAtView = this.chkDate(obj.updatedAt)
          })
          // if (this.statusAll) {
          //   this.content.data.forEach((obj) => {
          //     obj.isActive = true
          //   })
          // }
        } else {
          const error = {
            msg: `${this.$t('message.errorRequest')}`,
            ok: this.$t('button.ok')
          }
          const notify = showNotifyInfo(error)
          this.showNotify(notify)
        }
      } finally {
        this.loading = false
      }
    },
    async downloadList() {
      try {
        const params = Object.assign({ filters: this.filters }, this.options)
        const resp = await postListDownload('sku', params, this.id)
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${this.$t('titles.skuList')} - ${this.$t('titles.product')} ID ${
            this.id
          }.xlsx`
        )
        document.body.appendChild(link)
        link.click()
      } catch {
        const error = {
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyInfo(error)
        this.showNotify(notify)
      }
    },
    async delSkuContent(id, name) {
      const success = {
        msg: `${this.$t('message.sku')} ${name} ${this.$t('message.remove')}`,
        ok: this.$t('button.ok')
      }
      const error = {
        msg: `${this.$t('message.errorRequest')}`,
        ok: this.$t('button.ok')
      }
      const resp = await setDeleteSku(id)
      const notify = resp ? showNotifySuccess(success) : showNotifyInfo(error)
      this.showNotify(notify)
      this.getListSku()
    },
    getSkuDetails(id) {
      this.$emit('details', id)
    },
    chkDate(date) {
      if (!date) return date
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    setOnChangePage(val) {
      this.options.page = val
    },
    setOnChangePerPage(val) {
      this.options.itemsPerPage = val
    },
    filterTable(filter) {
      this.paginations.page = 1
      this.filters = filter
      this.getListSku()
    },
    modalOpen(dialogName) {
      this[dialogName] = true
    },
    modalClose(dialogName) {
      this[dialogName] = false
    },
    showNotify(options) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'question') {
            this.delSkuContent(options.id, options.name)
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.ifc-btn-right {
  float: right;
}
.ifc-align-center {
  text-align: center;
}
.cell-ellipsis {
  width: 215px !important;
}
.ifc-align-right {
  text-align: right;
}
.ifc-align-center {
  text-align: center;
}
.ifc-width-100 {
  width: 100px;
}
.ifc-switch-adjust {
  height: 30px !important;
  margin: 0 auto !important;
  width: 75px;
}
</style>
