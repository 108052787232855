<template>
  <div class="ifc-tab-content">
    <sku-list
      :id="id"
      @details="getSkuDetails"
      v-if="displayView === 'list'"
      :channelId="channelId"
    />
    <sku-details
      :id="id"
      :categoryId="categoryId"
      :data="skuData"
      :skuId="skuId || 'new'"
      :channelId="channelId"
      :options="attributeOptions"
      @list="getSkuList"
      v-if="displayView === 'details'"
      @goBack="displayView = 'list'"
      @refresh="attrRefresh"
    />
  </div>
</template>

<script>
// import KeyWords from '@/components/Catalog/Autocomplete/AutoCompleteKeyWords.vue'
import SkuList from './tabSkuList.vue'
import SkuDetails from './tabSkuDetails.vue'
export default {
  name: 'tabSku',
  components: {
    'sku-list': SkuList,
    'sku-details': SkuDetails
    // keywords: KeyWords
  },
  props: ['id', 'options', 'skuDetails', 'channelId', 'categoryId'],
  data() {
    return {
      displayView: 'list',
      skuData: {},
      skuId: null
    }
  },
  computed: {
    attributeOptions: {
      get() {
        return this.options
      }
    }
  },
  methods: {
    getSkuDetails(id) {
      this.displayView = 'details'
      // this.skuData = data
      this.skuId = id
    },
    getSkuList() {
      this.displayView = 'list'
      // this.skuData = {}
    },
    attrRefresh() {
      this.$emit('refresh')
    }
  },
  mounted() {
    if (this.skuDetails) {
      this.getSkuDetails(this.skuDetails)
    }
  }
}
</script>
<style scoped>
.ifc-tab-content {
  position: relative;
  display: block;
  width: 100%;
}
.ifc-switch-inline {
  display: inline-block;
}
.ifc-align-right {
  text-align: right;
}
.ifc-separetor {
  border-bottom: 1px solid #c6d3de;
}
</style>
