<template>
  <ifc-container
    class="product-list"
    :breadcrumb="breadcrumbs"
    :title="$t('titles.constuction')"
    returnButton
    :returnLabel="$t('button.back')"
    @onGoBack="getBack"
  >
    <v-row>
      <v-col cols="12">
        <ifc-card>
          {{ $t('message.constuction') }}
        </ifc-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ifc-col-center">
        <ifc-button
          icon=""
          prependIcon="ph-arrow-left"
          :label="$t('button.back')"
          color="primary"
          @click="getBack()"
        />
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
export default {
  name: 'Construction',
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('titles.catalog'),
          to: `/`,
          disabled: true
        },
        {
          text: this.$t('titles.constuction'),
          to: '/',
          exact: true
        }
      ]
    }
  },
  methods: {
    getBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.ifc-btn-right {
  text-align: right;
}
.ifc-col-center {
  text-align: center;
}
.cell-ellipsis {
  width: 215px !important;
}
</style>
