<template>
  <ifc-container
    class="import-list"
    :breadcrumb="config.title ? breadcrumbs : '...'"
    :title="config.title"
  >
    <v-row class="filter-row">
      <v-col cols="4">
        <auto-complete-list-channels
          v-model="channelId"
          :label="$t('label.channel')"
        />
      </v-col>
      <v-col cols="8" class="align-right">
        <ifc-button
          class="ifc-ml-10 ifc-mb-10"
          appendIcon="ph-download"
          :label="$t('button.spreadsheetPartner')"
          :loading="downloadingTemplate"
          @click="handleDownloadSpreadsheetTemplate(`${config.title}.xlsx`)"
          outlined
          color="information-200"
        />
        <ifc-button
          class="ifc-ml-10 ifc-mb-10"
          appendIcon="ph-upload"
          :label="$t('button.sendUploadXlsx')"
          @click="modalOpen('modalUpload'), (uploadType = 'xlsx')"
          color="information-200"
        />
        <ifc-button
          v-if="page === 'images'"
          class="ifc-ml-10 ifc-mb-10"
          appendIcon="ph-upload"
          :label="$t('button.sendUploadZip')"
          @click="modalOpen('modalUploadInfo'), (uploadType = 'zip')"
          color="information-200"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-table
          :headers="headers"
          :items="content.data"
          :options.sync="options"
          :loading="loading"
        >
          <tbody v-if="content.data.length > 0">
            <tr v-for="(item, i) in content.data" :key="i">
              <td>
                <ifc-tooltip
                  v-bind="$props"
                  bottom
                  max-width="600"
                  background-color="information-50"
                  text-color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                      {{ item.fileOriginalName }}
                    </div>
                  </template>
                  <span> {{ item.fileOriginalName }} </span>
                </ifc-tooltip>
              </td>
              <td>{{ item.action }}</td>
              <td>{{ item.model }}</td>
              <td>{{ item.lines.PROCESSING }}</td>
              <td>{{ item.lines.SUCCESS }}</td>
              <td>{{ item.lines.ERROR }}</td>
              <td>{{ chkDate(item.updatedAt) }}</td>
              <td>
                <ifc-chip
                  v-if="!setTootipView(item)"
                  :text-color="setTypeStatus(item.status).color"
                  :background-color="setTypeStatus(item.status).bg"
                  :label="setTypeStatus(item.status).label"
                >
                </ifc-chip>
                <ifc-tooltip
                  v-if="setTootipView(item)"
                  v-bind="$props"
                  bottom
                  max-width="600"
                  :text-color="setTypeStatus(item.status).color"
                  :background-color="setTypeStatus(item.status).bg"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ifc-chip
                      v-bind="attrs"
                      v-on="on"
                      :text-color="setTypeStatus(item.status).color"
                      :background-color="setTypeStatus(item.status).bg"
                      :label="setTypeStatus(item.status).label"
                    >
                    </ifc-chip>
                  </template>
                  <span> {{ setTootipView(item) }} </span>
                </ifc-tooltip>
              </td>
              <td>
                <ifc-button
                  v-if="item.status === 'FINISHED'"
                  icon="ph-download"
                  @click="
                    modalOpen('modalSheetExport'),
                      ((lineId = item._id), (name = item.fileOriginalName))
                  "
                  size="extra-small"
                />
              </td>
              <td>
                <ifc-button
                  icon="ph-info"
                  :to="`/import-details/${page}/${item._id}/${item.fileOriginalName}?channelId=${channelId}`"
                  size="extra-small"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <span v-if="loading">
                  {{ $t('message.loading') }}
                </span>
                <span v-else>
                  {{ $t('table.noDataText') }}
                </span>
              </td>
            </tr>
          </tbody>
        </ifc-table>
        <ifc-pagination
          class="ifc-mt-28"
          :totalPages="paginations.pages"
          :perPageOptions="listPerPage"
          :perPageDefault="paginations.perPage"
          @onChangePage="setOnChangePage"
          @onChangePerPage="setOnChangePerPage"
        />
      </v-col>
    </v-row>
    <upload
      persistent
      :value="modalUpload"
      :title="
        uploadType === 'zip'
          ? $t('titles.importZip')
          : $t('titles.importSpreadsheet')
      "
      :local="page"
      :type="uploadType"
      @blur="modalClose('modalUpload')"
      @onClose="modalClose('modalUpload', $event)"
      @error="uploadError()"
    />
    <upload-info
      persistent
      :value="modalUploadInfo"
      @blur="modalClose('modalUploadInfo')"
      @onClose="modalCloseInfo"
    />
    <export-sheet-modal
      persistent
      @blur="modalClose('modalSheetExport')"
      @onClose="modalClose('modalSheetExport')"
      @error="uploadError()"
      :id="lineId"
      :name="name"
      :value="modalSheetExport"
    />
  </ifc-container>
</template>

<script>
import AutoCompleteListChannels from '@/components/Catalog/Autocomplete/AutoCompleteListChannels.vue'
import ExportSheetModal from '@/components/Catalog/Modals/ExportSheetModal.vue'
import Upload from '@/components/Catalog/Uploads/UploadImportFiles.vue'
import UploadInfo from '@/components/Catalog/Uploads/UploadImportFilesInfo.vue'
import { showNotifyWarning } from '@/plugins/notify'
import { getFilesList, getSpreadSheetTemplate } from '@/services/imports'

export default {
  name: 'ViewImports',
  components: {
    upload: Upload,
    'upload-info': UploadInfo,
    'export-sheet-modal': ExportSheetModal,
    AutoCompleteListChannels
  },
  props: ['page', 'chkStore'],
  data() {
    return {
      breadcrumbs: [],
      headers: [
        {
          text: this.$t('label.name'),
          align: 'start',
          sortable: true,
          value: 'fileOriginalName'
        },
        {
          text: this.$t('label.action'),
          align: 'start',
          value: 'action',
          sortable: false
        },
        {
          text: this.$t('label.model'),
          align: 'start',
          value: 'model',
          sortable: false
        },
        {
          text: this.$t('label.processing'),
          align: 'start',
          sortable: false
        },
        {
          text: this.$t('label.success'),
          align: 'start',
          sortable: false
        },
        {
          text: this.$t('label.error'),
          align: 'start',
          sortable: false
        },
        {
          text: this.$t('label.updatedAt'),
          align: 'start',
          sortable: true,
          value: 'updatedAt'
        },
        {
          text: this.$t('label.status'),
          align: 'start',
          sortable: true
        },
        {
          text: '',
          sortable: false,
          value: 'download'
        },
        {
          text: '',
          sortable: false,
          value: 'delete'
        }
      ],
      downloadingTemplate: false,
      modalUpload: false,
      modalUploadInfo: false,
      modalSheetExport: false,
      lineId: null,
      name: null,
      uploadType: 'xlsx',
      options: {},
      listPerPage: [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 }
      ],
      content: {
        data: [],
        page: 1
      },
      config: {
        title: '...',
        params: null,
        modelFile: ''
      },
      paginations: [],
      channelId: null,
      filters: {},
      loading: false,
      filterImports: null,
      intervalToRefreshInSec: 10
    }
  },
  watch: {
    page: {
      handler() {
        this.config = this.getConfigPage()
        this.getListFilesImport()
        this.breadcrumbs = [
          {
            text: this.$t('titles.homePage'),
            href: '/'
          },
          {
            text: this.$t('titles.catalog'),
            href: `/${window.parent.IFC_VARIABLES.headers['x-tenant-id']}/catalog/products`
          },
          {
            text: this.config.title || '...',
            to: `/import/${this.page}'`,
            exact: true
          }
        ]
      },
      deep: true
    },
    options: {
      handler() {
        this.getListFilesImport()
      },
      deep: true
    },
    channelId: {
      handler() {
        this.getListFilesImport()
      },
      deep: true
    },
    chkStore(store) {
      if (store) {
        this.getListFilesImport()
      }
    }
  },
  methods: {
    setTootipView(item) {
      return item.error
    },
    async handleDownloadSpreadsheetTemplate(filename) {
      this.downloadingTemplate = true

      try {
        const { modelFile } = this.config || {}

        if (!modelFile) {
          throw new Error('Invalid modelFile')
        }

        const { data } = (await getSpreadSheetTemplate(modelFile)) || {}

        // Ensure data is not null or empty
        if (!data) {
          throw new Error(
            'Empty or null data returned from getSpreadSheetTemplate'
          )
        }

        // Check if the data is in the correct format (Blob or File object)
        if (!(data instanceof Blob || data instanceof File)) {
          throw new Error('Invalid data format. Expected Blob or File object.')
        }

        const url = window.URL.createObjectURL(data)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()

        window.URL.revokeObjectURL(url)
      } catch (e) {
        console.error('Error in handleDownloadSpreadsheetTemplate:', e)
        this.showNotify({
          type: 'error',
          title: this.$t('message.errorRequest'),
          text: this.$t('message.errorRequest')
        })
      } finally {
        this.downloadingTemplate = false
      }
    },
    // BRANDS, CATEGORIES, PRODUCTS-SKUS-UNIFIED, MEDIAS-SHEET, MEDIAS-ZIP, PRODUCTS-FISCAL

    getConfigPage() {
      switch (this.page) {
        case 'brands':
          this.filterImports = 'BRANDS'
          return {
            title: `${this.$t('titles.importOn')} ${this.$t('titles.brands')}`,
            params: 'brands',
            modelFile: `BRANDS`
          }
        case 'categories':
          this.filterImports = 'CATEGORIES'
          return {
            title: `${this.$t('titles.importOn')} ${this.$t(
              'titles.categories'
            )}`,
            params: 'categories',
            modelFile: `CATEGORIES`
          }
        case 'fiscal':
          this.filterImports = 'PRODUCTS-FISCAL'
          return {
            title: `${this.$t('titles.importOn')} ${this.$t('titles.fiscal')}`,
            params: 'fiscal',
            modelFile: `PRODUCTS-FISCAL`
          }
        case 'images':
          this.filterImports = 'MEDIAS'
          return {
            title: `${this.$t('titles.importOn')} ${this.$t('titles.medias')}`,
            params: 'medias',
            modelFile: `MEDIAS-SHEET`
          }
        case 'products-full':
          this.filterImports = 'PRODUCTS-SKUS-UNIFIED'
          return {
            title: `${this.$t('titles.importOn')} ${this.$t(
              'titles.products'
            )}`,
            params: 'products-full',
            modelFile: `PRODUCTS-SKUS-UNIFIED`
          }
        default:
          this.$router.push('/')
          return {
            title: `${this.$t('titles.import')}`,
            params: '',
            modelFile: null
          }
      }
    },
    async getListFilesImport() {
      this.loading = true
      try {
        const params = Object.assign(
          {
            filters: this.filters
          },
          this.options
        )
        params.channelId = this.channelId
        params.originImport = this.config.params
        const { data, ...paginations } = await getFilesList(
          params,
          this.filterImports
        )
        this.paginations = paginations || null
        this.content.data = data || []
      } finally {
        this.loading = false
      }
    },
    valDownload(f) {
      const file = f.split('.').reverse()
      return file[0] === 'xlsx'
    },
    chkDate(date) {
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    setOnChangePage(val) {
      this.options.page = val
    },
    statusLoadInterval(interval = this.intervalToRefreshInSec * 1000) {
      let initialInterval = null

      if (initialInterval) {
        clearInterval(initialInterval)
      }

      initialInterval = setInterval(() => {
        const processing = this.content.data.some(
          (el) =>
            el.lines?.perStatus?.Processing > 0 || el.status === 'PROCESSING'
        )
        if (processing) {
          this.getListFilesImport()
        } else {
          clearInterval(initialInterval)
        }
      }, interval)
    },
    setOnChangePerPage(val) {
      this.options.itemsPerPage = val
    },
    filterTable(filter) {
      this.paginations.page = 1
      this.filters = filter
      this.getListFilesImport()
    },
    setTypeStatus(status) {
      let statusChips = {}
      switch (status) {
        case 'ERROR':
          statusChips = {
            color: 'error-100',
            bg: 'error-50',
            label: this.$t('status.error.short')
          }
          break
        case 'PROCESSING':
          statusChips = {
            color: 'information-100',
            bg: 'information-50',
            label: this.$t('status.processing.short')
          }
          break
        case 'SUCCESS':
          statusChips = {
            color: 'success',
            bg: 'success-50',
            label: this.$t('status.success.short')
          }
          break
        case 'FINISHED':
          statusChips = {
            color: 'information',
            bg: 'information-50',
            label: this.$t('status.finished.short')
          }
          break
        case 'RECEIVED':
          statusChips = {
            label: this.$t('status.received.short')
          }
          break
      }

      return statusChips
    },
    uploadError() {
      const error = {
        msg: `${this.$t('message.errorRequest')}`,
        ok: this.$t('button.ok')
      }
      const notify = showNotifyWarning(error)
      this.showNotify(notify)
    },
    modalClose(dialogName, event) {
      this[dialogName] = false

      if (event?.refreshDataTable) {
        this.getListFilesImport()
        this.statusLoadInterval()
      }
    },
    modalCloseInfo(info) {
      if (info) {
        this.uploadType = 'zip'
        this.modalClose('modalUploadInfo')
        this.modalOpen('modalUpload')
      } else {
        this.modalClose('modalUploadInfo')
      }
    },
    modalOpen(dialogName) {
      this[dialogName] = true
    },
    showNotify(options) {
      this.$notify(options)
        .then(() => {})
        .catch(() => {})
    }
  },
  mounted() {
    this.statusLoadInterval()

    this.config = this.getConfigPage()
    this.breadcrumbs = [
      {
        text: this.$t('titles.homePage'),
        href: '/'
      },
      {
        text: this.$t('titles.catalog'),
        to: '/',
        exact: true
      },
      {
        text: this.config.title || '...',
        to: `catalog/import/${this.page}'`,
        exact: true
      }
    ]
  }
}
</script>

<style scoped lang="scss">
.filter-row {
  padding: 0;

  > div {
    padding: 0;

    &:last-child {
      padding-top: 25px;
    }
  }
}
.align-right {
  text-align: right;
  grid-gap: 10px;
}
.v-btn {
  ::v-deep &.v-btn--disabled.v-btn--icon {
    .ph-download {
      &::before {
        color: $color-neutral-200;
      }
    }
  }
}
</style>
