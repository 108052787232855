<template>
  <ifc-container
    class="product-list"
    :breadcrumb="breadcrumbs"
    :title="$t('titles.categories')"
  >
    <filter-table @filters="filterTable" @download="downloadList()" />
    <v-row class="ifc-mt-10">
      <v-col cols="12" class="ifc-pa-none">
        <div class="treeview">
          <div class="treeview--box">
            <div class="ifc-pa-12 treeview-header">
              <div class="row no-gutters ifc-mt-2">
                <div class="treeview-header--spacer"></div>
                <div class="col-1">
                  <b class="ifc-ml-6">{{ this.$t('label.id') }}</b>
                </div>
                <div class="col">
                  <b>{{ this.$t('label.name') }}</b>
                </div>
                <div class="treeview-header--actions ifc-pl-16">
                  <div class="row">
                    <div class="col text-center">
                      <b>{{ this.$t('label.status') }}</b>
                    </div>
                    <div class="col-3"></div>
                  </div>
                </div>
              </div>
            </div>
            <v-treeview
              class="ifc-list-treeview"
              v-if="!loading && content.data.length > 0"
              :items="content.data"
              v-model.lazy="selected"
              return-object
              selection-type="independent"
              item-key="categoryId"
              :open-all="openAll"
            >
              <template v-slot:label="{ item }">
                <div class="row">
                  <div class="col-1">
                    <span class="ifc-ml-6 mr-5">{{ item.categoryId }}</span>
                  </div>
                  <div class="col">
                    <ifc-tooltip
                      v-bind="$props"
                      bottom
                      max-width="600"
                      background-color="information-50"
                      text-color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                          {{ item.name }}
                        </div>
                      </template>
                      <span> {{ item.name }} </span>
                    </ifc-tooltip>
                  </div>
                  <div class="treeview--actions">
                    <div class="row">
                      <div class="col text-center">
                        <ifc-chip
                          :label="$t(`status.active.short`)"
                          background-color="success-50"
                          text-color="success-200"
                          v-if="item.isActive"
                        />
                        <ifc-chip
                          :label="$t(`status.inactive.short`)"
                          background-color="neutral-300"
                          text-color="neutral-400"
                          v-else
                        />
                      </div>
                      <div class="col-2">
                        <ifc-button
                          icon="ph-pencil-simple"
                          :to="`/categories/${item.categoryId}?channelId=${
                            filters.channelId !== 'undefined'
                              ? filters.channelId
                              : ''
                          }`"
                          size="extra-small"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </v-treeview>

            <div v-else-if="loading" class="text-center treeview-loading">
              <div class="pt-3">
                <ifc-loader
                  color="primary"
                  indeterminate
                  style="height: 30px; width: 30px"
                />
                {{ $t('message.loading') }}...
              </div>
            </div>

            <div v-else class="no-content">
              <p>
                {{ $t('table.noDataText') }}
              </p>
            </div>
          </div>
        </div>

        <ifc-pagination
          class="ifc-mt-28"
          :totalPages="paginations.pages"
          :perPageOptions="listPerPage"
          :perPageDefault="paginations.perPage"
          @onChangePage="setOnChangePage"
          @onChangePerPage="setOnChangePerPage"
        />
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import { getCategoriesListTree, setDeleteCategory } from '@/services/categories'
import FilterTable from '@/components/Catalog/Filters/FilterCategoriesList.vue'
import { postListDownload } from '@/services/imports'
import { showNotifyInfo, showNotifySuccess } from '@/plugins/notify'
export default {
  name: 'Categories',
  components: {
    'filter-table': FilterTable
  },
  props: ['chkStore'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('titles.homePage'),
          href: '/'
        },
        {
          text: this.$t('titles.catalog'),
          to: `/`,
          disabled: true
        },
        {
          text: this.$t('titles.categoriesList'),
          to: '/categories',
          exact: true
        }
      ],
      modalUpload: false,
      options: {
        itemsPerPage: 10,
        page: 1
      },
      listPerPage: [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 }
      ],
      content: {
        data: [],
        page: 1
      },
      config: [],
      paginations: [],
      filters: {},
      loading: false,
      select: null,
      selected: [],
      openAll: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getListCategories()
      },
      deep: true
    },
    chkStore(store) {
      if (store) {
        this.getListCategories()
      }
    },
    selected() {
      this.verifyTreeChildrens()
    }
  },
  mounted() {
    this.getListCategories()
  },
  methods: {
    async getListCategories() {
      this.loading = true
      this.content.data = []
      try {
        const params = Object.assign(
          {
            filters: this.filters
          },
          this.options
        )
        const resp = await getCategoriesListTree(params)

        if (resp) {
          this.content.data = resp
          // const { data, ...paginations } = resp
          // this.paginations = paginations
        } else {
          this.content.data = []
          // const error = {
          //   msg: `${this.$t('message.errorRequest')}`,
          //   ok: this.$t('button.ok')
          // }
          // const notify = showNotifyInfo(error)
          // this.showNotify(notify)
        }
      } finally {
        this.loading = false
      }
    },
    async downloadList() {
      try {
        const params = Object.assign({ filters: this.filters }, this.options)
        params.channelId = this.filters.channelId

        const resp = await postListDownload('categories', params)

        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${this.$t('titles.categoriesList')}.xlsx`
        )
        document.body.appendChild(link)
        link.click()
      } catch {
        const error = {
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyInfo(error)
        this.showNotify(notify)
      }
    },
    async delCategoryContent(id, name) {
      const success = {
        msg: `${this.$t('message.category')} ${name} ${this.$t(
          'message.remove'
        )}`,
        ok: this.$t('button.ok')
      }
      const error = {
        msg: `${this.$t('message.removeCategoryValidation')}`,
        ok: this.$t('button.ok')
      }
      const resp = await setDeleteCategory(id)
      const notify = resp ? showNotifySuccess(success) : showNotifyInfo(error)
      this.showNotify(notify)
      this.getListCategories()
    },
    chkDate(date) {
      return date.indexOf('T') > -1 || date.indexOf('Z') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    setOnChangePage(val) {
      this.options.page = val
    },
    setOnChangePerPage(val) {
      this.options.itemsPerPage = val
    },
    filterTable(filter) {
      this.openAll = true
      this.paginations.page = 1
      this.filters = filter
      this.getListCategories()
    },
    showNotify(options) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'question') {
            this.delCategoryContent(options.id, options.name)
          }
        })
        .catch(() => {})
    },
    // if categoryId selected has a children, the children will be selected too
    verifyTreeChildrens() {
      const newSelected = []

      if (this.selected.length > 0) {
        this.selected.forEach((category) => {
          const { children } = category

          if (!!children && children.length > 0) {
            const [firstChildren] = children
            const childrenSelected = this.selected.find((item) => {
              return item.categoryId === firstChildren.categoryId
            })

            if (!childrenSelected) {
              newSelected.push(...children)
            }
          }
        })
      }

      if (newSelected.length > 0) {
        this.selected.push(...newSelected)
      }
    }
  }
}
</script>
<style scoped>
.no-content {
  padding: 20px 20px 10px;
  text-align: center;
  background-color: white;
}
</style>
<style lang="scss">
.ifc-btn-right {
  float: right;
}
.ifc-col-center {
  text-align: center;
}
.cell-ellipsis {
  width: 215px !important;
}

.treeview-header {
  background: #e7ecf2;
  .treeview-header {
    &--actions {
      width: 400px;
    }
  }
  &--spacer {
    width: 54px;
  }
}

.ifc-list-treeview {
  &.v-treeview {
    border-radius: 0;
    border: 1px solid $color-neutral-200;
    border-bottom: none;
    background-color: white;
  }

  .v-treeview-node__root {
    border-bottom: 1px solid $color-neutral-200;
    height: 52px;
  }

  .v-treeview-node__level:nth-last-child(4) {
    height: 14px;
    width: 12px;
    margin-left: 10px;
    margin-top: -10px;
    border-bottom: 1px dashed black;
    border-left: 1px dashed black;
  }
  .treeview {
    background-color: #fff;
    box-shadow: 0px 0px 4px $color-neutral-100;

    &--box {
      min-width: 1010px;
    }

    &-header {
      border-bottom: none;
      background-color: $color-neutral-100;
      height: 52px;

      &--actions {
        width: 400px;
      }
    }

    &--actions {
      width: 400px;
      margin-top: 10px;
    }

    &-loading {
      height: 52px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .treeview {
    overflow: auto;
    &--box {
      width: 1100px;
    }
  }
}
</style>
