<template>
  <v-row id="filters-row">
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="2"
      class="align-self-start filter-channel"
    >
      <acl-chan :label="`${$t('label.channel')}`" v-model="filters.channelId" />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      class="align-self-start filter-search"
    >
      <ifc-input
        class="ifc-mt-16"
        v-model="filters.search"
        :placeholder="`${$t('label.searchBy')} ${$t('label.name')}, ${$t(
          'label.id'
        )}, ${$t('label.or')} ${$t('label.sku')}`"
        prependIcon="search"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="2"
      class="align-self-start filter-category"
    >
      <acl-categ
        :label="`${$t('label.category')}`"
        v-model="filters.categoryId"
        :channelId="filters.channelId"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="2"
      class="align-self-start filter-brand"
    >
      <acl-brand
        class="ifc-position-brand"
        :label="`${$t('label.brand')}`"
        v-model="filters.brandId"
        :channelId="filters.channelId"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      class="align-self-start ifc-align-right filter-buttons"
    >
      <!-- removed by Backend -->
      <ifc-button
        class="ifc-mb-6 ifc-mr-4"
        appendIcon="ph-download"
        :label="`${$t('button.export')}`"
        outlined
        color="information-200"
        @click="getDownload"
        :disabled="exportIsLoading"
      />
      <ifc-button
        class="ifc-mb-6 ifc-mt-2 ifc-ml-4"
        appendIcon="add"
        :label="$t('button.newProduct')"
        :to="`/products/new?channelId=${filters.channelId}`"
        color="information-200"
      />
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'
import AutoCompleteListBrands from '@/components/Catalog/Autocomplete/AutoCompleteListBrands.vue'
import AutoCompleteListCategories from '@/components/Catalog/Autocomplete/AutoCompleteListCategories.vue'
import AutoCompleteListChannels from '@/components/Catalog/Autocomplete/AutoCompleteListChannels.vue'
import getStoragedChannel from '@/utils/get-storaged-channel'
export default {
  name: 'FilterLocations',
  components: {
    'acl-brand': AutoCompleteListBrands,
    'acl-categ': AutoCompleteListCategories,
    'acl-chan': AutoCompleteListChannels
  },
  props: {
    exportIsLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      panel: [0],
      status: [],
      debounce: null,
      filters: {
        search: '',
        categoryId: '',
        brandId: '',
        channelId: ''
      },
      debouncedUpdateFilters: _.debounce(this.updateFilters, 500)
    }
  },
  watch: {
    filters: {
      handler() {
        this.debouncedUpdateFilters()
      },
      deep: true
    }
  },
  mounted() {
    this.filters.channelId = getStoragedChannel()
  },
  methods: {
    updateFilters() {
      this.$emit('filters', this.filters)
    },
    getDownload() {
      this.$emit('download')
    }
  }
}
</script>

<style lang="scss" scoped>
#filters-row {
  > div {
    padding: 0 5px;
  }

  .filter-search {
    padding-top: 11px;
  }

  .filter-category {
    padding-top: 11px;
  }

  .filter-brand {
    padding-top: 11px;
  }

  .filter-buttons {
    padding-top: 25px;
  }
}

.v-text-field__details {
  display: none !important;
}

.ifc-align-right {
  text-align: right;
}

.ifc-position-brand {
  position: relative;
  top: -5px;
}

@media (max-width: 960px) {
  .ifc-padd-bottom {
    position: relative;
    padding-bottom: 20px !important;
  }
}
</style>
