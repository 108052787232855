import Vue from 'vue';
<template>
  <ifc-table class="ifc-table-insert" v-if="showTable">
    <template v-slot:default>
      <tbody>
        <tr>
          <td width="50%" vertical-align="top">
            <ifc-combobox
              class="ifc-input-no-hint"
              appendIcon="expand_more"
              keyValue="attributeId"
              keyLabel="name"
              v-model="nameSelect"
              :options="attributeOptionsName"
              size="small"
              color="primary"
              :hideMoreOptions="false"
              :deletableChips="false"
              :multiple="false"
              :placeholder="$t('label.name')"
              returnObject
              :clearable="false"
              @change="resetAttributeValues"
            />
          </td>
          <td width="50%" vertical-align="top">
            <ifc-combobox
              class="ifc-input-no-hint"
              appendIcon="expand_more"
              keyValue="attributeValue"
              keyLabel="attributeValue"
              v-model="valueSelect"
              :options="attributeOptionsValue"
              size="small"
              color="primary"
              :hideMoreOptions="false"
              :deletableChips="false"
              :multiple="false"
              :placeholder="$t('label.value')"
              returnObject
              :error-messages="errCreateMsg"
              :clearable="false"
              ref="attributeValueComboBox"
              @click="getScrollPosition"
              @input.native="debouncedSetFilterString"
            />
          </td>
          <td
            width="60"
            vertical-align="top"
            align="center"
            class="ifc-table-button"
          >
            <ifc-button
              icon="ph-plus"
              size="extra-small"
              @click="addAttrValue()"
              :disabled="!valueSelect.attributeValue || !nameSelect.name"
            />
          </td>
          <td
            width="60"
            vertical-align="top"
            align="center"
            class="ifc-table-button"
          >
            <ifc-button
              icon="ph-x"
              size="extra-small"
              @click="cancelAttrValue()"
            />
          </td>
        </tr>
      </tbody>
    </template>
  </ifc-table>
</template>

<script>
import _ from 'lodash'
import { getAttributeValues, getAttributeValue } from '@/services/attributes'
import Vue from 'vue'

export default {
  name: 'ModalAttributesValuesSku',
  components: {},
  props: ['value', 'title', 'options', 'channelId', 'attributesSelected'],
  data() {
    return {
      debouncedSetFilterString: _.debounce(this.setFilterString, 1500),
      attributeSelection: {
        _id: null,
        name: this.$t('label.select'),
        attributeId: 0,
        attributeValue: this.$t('label.select'),
        attributeValueId: 0
      },
      dataAttr: {},
      attributeOptionsValue: [],
      toSelected: undefined,
      validButton: false,
      nameSelect: {},
      valueSelect: {},
      errCreateMsg: null
    }
  },
  watch: {
    options: {
      handler() {
        // this.nameSelect = {}
        this.valueSelect = {}
      },
      deep: true
    },
    data: {
      handler(d) {
        this.attributeSelection = {
          _id: d._id,
          name: d.name,
          attributeId: d.attributeId,
          attributeValue: d.attributeValue,
          attributeValueId: d.attributeValueId
        }
      },
      deep: true
    },
    attributeSelection: {
      handler(s) {
        this.getOptionsValue(s)
        this.chkValidButton()
      },
      deep: true
    },
    nameSelect: {
      handler(n) {
        this.errCreateMsg = null
        this.attributeSelection = {
          attributeId: n.attributeId,
          name: n.name
        }
      },
      deep: true
    },
    valueSelect: {
      handler(v) {
        this.errCreateMsg = null
        this.attributeSelection.attributeValueId = v.attributeValueId
        this.attributeSelection.attributeValue = v.attributeValue
      },
      deep: true
    }
  },
  computed: {
    showTable() {
      return this.attributeOptionsName?.length > 0
    },
    attributeOptionsName() {
      if (!this.options) return []

      const selectedAttributeIds = new Set(
        this.attributesSelected?.map((item) => item.attributeId) || []
      )

      return this.options
        .filter((option) => !selectedAttributeIds.has(option.attributeId))
        .map((option) => ({
          attributeId: option.attributeId,
          name: option.attribute?.name || `Atributo - ${option.attributeId}`,
          disabled: option.isActive === false
        }))
    }
  },
  methods: {
    setFilterString(e) {
      const { value } = e.target
      this.getValue(value)
    },
    resetAttributeValues() {
      this.valueSelect = {}
    },
    getScrollPosition() {
      const dropdown =
        this.$refs.attributeValueComboBox?.$refs['v-combobox'].$refs.menu
          ?.$children[0]?.$el
      if (dropdown) {
        dropdown.addEventListener('scroll', this.handleScroll)
      }
    },
    merge(arr1, arr2) {
      const newArr = arr1.concat(arr2)
      return newArr.filter((item, index) => newArr.indexOf(item) === index)
    },
    handleScroll(event) {
      const scrollHeight = event.target.scrollHeight - event.target.clientHeight
      const scrollPosition = event.target.scrollTop

      if (scrollPosition === scrollHeight) {
        this.getValues()
      }
    },
    async getValue(value) {
      const elementPos = this.options
        .map((item) => item.attribute.attributeId)
        .indexOf(this.attributeSelection.attributeId)
      const res = await getAttributeValue(
        this.attributeSelection.attributeId,
        this.channelId,
        value
      )
      if (res.data.length > 0) {
        const newData = this.merge(
          res.data,
          this.options[elementPos].attribute.attributeValues.data
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'data',
          newData
        )
        this.getOptionsValue({
          attributeId: this.options[elementPos].attribute.attributeId,
          attributeValue:
            this.options[elementPos].attribute.attributeValues.value,
          attributeValueId:
            this.options[elementPos].attribute.attributeValues.attributeValueId,
          name: this.options[elementPos].attribute.name
        })
        this.$forceUpdate()
      }
    },
    async getValues() {
      const elementPos = this.options
        .map((item) => item.attribute.attributeId)
        .indexOf(this.attributeSelection.attributeId)
      const currentPage =
        this.options[elementPos].attribute.attributeValues.currentPage
      const pages = this.options[elementPos].attribute.attributeValues.pages
      if (currentPage < pages) {
        const res = await getAttributeValues(
          this.attributeSelection.attributeId,
          this.channelId,
          this.options[elementPos].attribute.attributeValues.nextPage
        )
        const newData = this.merge(
          this.options[elementPos].attribute.attributeValues.data,
          res.data
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'currentPage',
          res.currentPage
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'nextPage',
          res.nextPage
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'prevPage',
          res.prevPage
        )
        Vue.set(
          this.options[elementPos].attribute.attributeValues,
          'data',
          newData
        )
        this.getOptionsValue({
          attributeId: this.options[elementPos].attribute.attributeId,
          attributeValue:
            this.options[elementPos].attribute.attributeValues.value,
          attributeValueId:
            this.options[elementPos].attribute.attributeValues.attributeValueId,
          name: this.options[elementPos].attribute.name
        })

        this.$forceUpdate()
      }
    },
    getOptionsValue(s) {
      this.attributeOptionsValue = []

      if (this.options.length > 0) {
        const list = this.options.find((obj) => {
          return obj.attributeId === s.attributeId
        })

        if (list) {
          list.attribute.attributeValues.data.forEach((obj) => {
            const hasAttributeSelected = this.attributesSelected?.find(
              (item) =>
                item.attributeId === obj.attributeId &&
                item.attributeValueId === obj.attributeValueId
            )
            if (!hasAttributeSelected) {
              const arr = {
                attributeValue: obj.value,
                attributeValueId: obj.attributeValueId
              }
              this.attributeOptionsValue.push(arr)
            }
          })
        }
      }
    },
    addAttrValue() {
      const data = {
        attributeId: this.attributeSelection.attributeId,
        name: this.attributeSelection.name,
        attributeValue: this.attributeSelection.attributeValue,
        attributeValueId: this.attributeSelection.attributeValueId
      }
      this.$emit('save', data)
      this.errCreateMsg = null
      this.nameSelect = {}
      this.valueSelect = {}
      // this.clearModal()
    },
    chkValidButton() {
      const item = this.attributeSelection
      this.validButton =
        item.attributeId &&
        item.name &&
        item.attributeValue &&
        item.attributeValueId
    },
    cancelAttrValue() {
      this.attributeSelection = {
        _id: null,
        name: null,
        attributeId: null,
        attributeValue: null,
        attributeValueId: null
      }
      this.dataAttr = {
        _id: null,
        name: null,
        attributeId: null,
        attributeValue: null,
        attributeValueId: null
      }
      this.valueSelect = {
        attributeValue: null,
        attributeValueId: null
      }
      this.nameSelect = {
        name: null,
        attributeId: null
      }
    }
  },
  beforeDestroy() {
    const dropdown =
      this.$refs.attributeValueComboBox.$refs[
        'v-combobox'
      ].$refs.menu.$children[0].$el?.querySelector('.v-select-list')

    if (dropdown) {
      dropdown.removeEventListener('scroll', this.handleScroll)
    }
  },
  mounted() {
    this.dataAttr = { ...this.data }
  }
}
</script>

<style scoped>
.ifc-input-no-hint {
  position: relative;
  display: block;
  margin-bottom: -35px;
}
</style>
