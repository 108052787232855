import AttributesDetails from '@/views/Attributes/Registration.vue'
import Attributes from '@/views/Attributes/index.vue'
import BrandsDetails from '@/views/Brands/Registration.vue'
import Brands from '@/views/Brands/index.vue'
import CategoriesDetails from '@/views/Categories/Registration.vue'
import Categories from '@/views/Categories/index.vue'
import Construct from '@/views/Construct/index.vue'
import ImportsDetails from '@/views/Imports/Details.vue'
import Imports from '@/views/Imports/index.vue'
import ProductsDetails from '@/views/Products/Registration.vue'
import Products from '@/views/Products/index.vue'

export default [
  {
    path: '/',
    component: Products,
    meta: { title: 'Product List' }
  },
  {
    path: '/products',
    component: Products,
    meta: { title: 'Product List' }
  },
  {
    path: '/products/:id',
    component: ProductsDetails,
    props: true,
    meta: { title: 'Product Details' }
  },
  {
    path: '/categories',
    component: Categories,
    meta: { title: 'Categories List' }
  },
  {
    path: '/categories/:id',
    component: CategoriesDetails,
    props: true,
    meta: { title: 'Categories Registration' }
  },
  {
    path: '/attributes',
    component: Attributes,
    props: true,
    meta: { title: 'Attributes List' }
  },
  {
    path: '/attributes/:id',
    component: AttributesDetails,
    props: true,
    meta: { title: 'Attributes Registration' }
  },
  {
    path: '/brands',
    component: Brands,
    meta: { title: 'Brands List' }
  },
  {
    path: '/brands/:id',
    component: BrandsDetails,
    props: true,
    meta: { title: 'Brands Registration' }
  },
  {
    path: '/import-details/:page/:id/:file',
    component: ImportsDetails,
    props: true,
    meta: { title: 'Import Details' }
  },
  {
    path: '/import/:page',
    component: Imports,
    props: true,
    meta: { title: 'Import List' }
  },
  {
    path: '/construct',
    component: Construct,
    meta: { title: 'Contruction Page' }
  }
]
