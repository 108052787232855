/**
 * Função que valida a resposta de erro da API
 * @param {Object} errorResponse - Resposta de erro da API
 * @returns {String|Object} - Mensagem de erro ou objeto com a chave e os argumentos
 */
export function validateErrorResponse(error) {
  // Verifica se o objeto de erro existe
  const errorResponse = error?.response?.data
  console.log('🚀 ~ validateErrorResponse ~ errorResponse:', errorResponse)

  // Verifica se o objeto tem as propriedades 'key' e 'args'
  if (!errorResponse || typeof errorResponse !== 'object') {
    return 'Algo deu errado, tente mais tarde ou contate o suporte'
  }

  const { key, args } = errorResponse

  // Verifica se a propriedade 'key' existe e é uma string
  if (typeof key !== 'string' || !key.trim()) {
    return 'Algo deu errado, tente mais tarde ou contate o suporte'
  }

  // Verifica se a propriedade 'args' existe e é um objeto com a propriedade 'context'
  if (!args || typeof args.context !== 'string' || !args.context.trim()) {
    return 'Algo deu errado, tente mais tarde ou contate o suporte'
  }

  // Retorna a chave e os argumentos
  return {
    key,
    args,
    context: args?.context
  }
}
