<template>
  <div class="ifc-tab-content">
    <v-row>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.fiscalNcm')"
          v-model="content.ncm"
          mask="####.##.##"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.fiscalCest')"
          v-model="content.cest"
          mask="##.###.##"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.originProduct')"
          v-model="content.origemProd"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.ipiFraming')"
          v-model="content.enquadramentoIpi"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.exceptionTipi')"
          v-model="content.excecaoTip"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="$t('label.descPerfilImposto')"
          v-model="content.descPerfilImposto"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-input
          :label="`${$t('label.fiscalTaxProfileCode')}`"
          :rules="rules.optionalMinLength(4)"
          minlength="4"
          maxlength="5"
          v-model="content.codPerfilImposto"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="ifc-py-none">
        <ifc-select
          appendIcon="expand_more"
          v-model="content.equiparadoIndustria"
          :options="listOptions"
          :label="`${$t('label.fiscalEquivalentIndustry')}?`"
          :placeholder="$t('label.select')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'tabFiscal',
  props: ['id', 'value'],
  data: function () {
    return {
      rules: {
        optionalMinLength: (min) => [
          (v) => {
            console.log(v)
            if (!v) return true
            return v.length >= min || this.$t('validation.minLength', { min })
          }
        ]
      },
      listOptions: [
        { label: this.$t('status.nullable.short'), value: null },
        { label: this.$t('status.yes.short'), value: true },
        { label: this.$t('status.no.short'), value: false }
      ]
    }
  },
  computed: {
    content: {
      get() {
        return this.value
      },
      set(s) {
        this.$emit('input', s)
      }
    }
  },
  watch: {
    content: {
      handler(c) {
        this.$emit('input', c)
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.ifc-tab-content {
  position: relative;
  display: block;
  width: 100%;
}
.ifc-switch-inline {
  display: inline-block;
}
.ifc-align-right {
  text-align: right;
}
</style>
