<template>
  <ifc-dialog v-bind="attributes" v-on="listeners" maxWidth="450px">
    <ifc-select
      appendIcon="expand_more"
      keyLabel="label"
      keyValue="value"
      v-model="status"
      :options="statusSelect"
      :label="`${$t('label.downloadStatus')}*`"
      :placeholder="$t('label.select')"
    />
    <ifc-button
      appendIcon="ph-download"
      class="ifc-btn-center"
      color="information-100"
      outlined
      :label="$t('button.download')"
      @click="handleDownload()"
      :disabled="status === null"
    />
  </ifc-dialog>
</template>
<script>
import { showNotifyInfo } from '@/plugins/notify'
import { postFileDownload } from '@/services/imports'

export default {
  name: 'ExportSheetModal',
  props: ['id', 'name'],
  data() {
    return {
      modal: false,
      status: null,
      statusSelect: [
        { label: this.$t('label.success'), value: 'SUCCESS' },
        { label: this.$t('label.error'), value: 'ERROR' },
        { label: this.$t('label.both'), value: '' }
      ]
    }
  },
  computed: {
    attributes() {
      const attributes = {
        ...this.$props,
        ...this.$attrs
      }

      return attributes
    },
    listeners() {
      return this.$listeners
    }
  },
  methods: {
    async handleDownload() {
      try {
        const resp = await postFileDownload(this.attributes.id, this.status)
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        const newName = this.attributes.name.includes('.zip')
          ? this.attributes.name.replace('zip', 'xlsx')
          : this.attributes.name
        link.setAttribute('download', `Download_${newName}`)
        document.body.appendChild(link)
        this.closeModal(true)
        return link.click()
      } catch {
        const error = {
          msg: `${this.$t('message.errorRequest')}`,
          ok: this.$t('button.ok')
        }
        const notify = showNotifyInfo(error)
        this.showNotify(notify)
      }
    },
    closeModal(info) {
      this.$emit('onClose', info)
    }
  },
  mounted() {}
}
</script>

<style scoped>
.ifc-btn-center {
  position: relative;
  display: block;
  margin: 15px auto;
}
</style>
