<template>
  <div>
    <ifc-input
      :loading="loading"
      :label="`${$t('label.canonical')}`"
      v-model="canonical"
      :error-messages="error"
    />
  </div>
</template>
<script>
import { getValidCanonical } from '@/services/canonical'

export default {
  name: 'InputCanonical',
  props: {
    value: {
      required: false
    },
    label: {
      required: false
    },
    id: {
      required: false
    },
    context: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      canonical: '',
      error: undefined,
      rules: {
        required: [(v) => !!v || this.$t('message.canonicalEmpty')]
      }
    }
  },
  watch: {
    async value(v) {
      this.canonical = v
      // const val =
      //   typeof v === 'string'
      //     ? v.indexOf('%') > -1
      //       ? v.replaceAll(/%/g, '')
      //       : v
      //     : ''
      // if (val) await this.getCanonical(val)
    }
  },
  methods: {
    async getCanonical(v) {
      const val = v
      if (val) {
        this.loading = true
        try {
          const params = {
            query: val,
            context: this.context,
            id: this.id || null
          }
          let data = await getValidCanonical(params)
          data = data === 'OK' ? '' : data
          this.canonical = data
          this.error = undefined
        } catch (e) {
          const err = JSON.stringify(e.message)
          this.error =
            err.indexOf('409') > -1
              ? this.$t('message.canonicalErrVal')
              : this.$t('message.canonicalErrApi')
          this.canonical = val
        } finally {
          this.loading = false
          this.$emit('input', this.canonical)
        }
      } else {
        this.$emit('input', '')
      }
    }
  }
}
</script>
<style scoped>
.ifc-treeview-area {
  position: relative;
  top: -5px;
}
</style>
