import { ApiCtg } from '@/plugins/axios/axios'

const URL = '/brands'

const getControlPagination = (params) => {
  let orderDirection = null
  const { filters, ...restPag } = params
  if (typeof restPag?.sortDesc?.[0] === 'boolean') {
    orderDirection = restPag?.sortDesc?.[0] ? '1' : '-1' || 1
  }
  const formattedPagination = {
    perPage: restPag?.itemsPerPage || null,
    page: restPag?.page || null,
    orderBy: restPag?.sortBy?.[0] || 'createdAt',
    orderDirection: orderDirection
  }
  for (const filterKey in filters) {
    if (filters[filterKey]) formattedPagination[filterKey] = filters[filterKey]
  }
  return formattedPagination
}

export const getBrandsList = async (params) => {
  const { data } = await ApiCtg.get(`${URL}`, {
    params: getControlPagination(params)
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}
export const getBrandsSimple = async (channelId, value) => {
  const params = {
    orderBy: 'createdAt',
    orderDirection: 1,
    channelId,
    search: value ?? '',
    perPage: 999
  }

  const { data } = await ApiCtg.get(`${URL}/getSimpleList`, {
    params
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const getBrandsData = async (id, channelId) => {
  const { data } = await ApiCtg.get(`${URL}/${id}`, {
    params: {
      channelId: channelId
    }
  })
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const setDeleteBrands = async (id) => {
  const { data } = await ApiCtg.delete(`${URL}/${id}`)
    .then((resp) => {
      return resp
    })
    .catch(() => {
      return []
    })
  return data
}

export const setCreateBrands = async (params) => {
  let data = ''
  await ApiCtg.post(`${URL}`, params)
    .then(() => {
      data = true
    })
    .catch(() => {
      data = false
    })
  return data
}

export const setUpdateBrands = async (id, params) => {
  await ApiCtg.put(`${URL}/${id}`, params, {
    params: {
      channelId: params?.channelId || null
    }
  })
}
